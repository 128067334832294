import { AssignableUnit, AssignableUnitSection, Mandatory } from '../models';
import { UserLearningStatus } from '../models/model-types';
import { UserAssignment } from '../models/user-assignment';
import { SessionInfo } from '../services/learning-record-store/learning-record-store.interfaces';

type Unit = AssignableUnit | AssignableUnitSection;

export interface UnitStatusWithScore {
    status: UserLearningStatus;
    score?: number;
}

/**
 * AssignmentStatusHelper takes over functionality which used to be provided by
 * @see {UserAssignmentStatus} models.
 *
 * Since the seperation of Sessions and Assignments (V5) the Player is no longer
 * receiving assignments with session data included. Instead we're fully relying
 * on local session data.
 */
export class AssignmentStatusHelper {

    /**
     * Filter out sessions not relevant to this assignment
     */
    readonly relevantSessions: SessionInfo[] = this.sessions.filter(s => s.assignmentId === this.assignment.assignment.identifier);

    /**
     * List of all activities, including the ones nested in courses.
     */
    readonly allActivities: Unit[] = this.assignment.assignment.units.reduce((acc, unit) => {
        if (unit.type === 'course') {
            return [...acc, ...unit.sections];
        }
        return [...acc, unit];
    }, []);

    constructor(
        readonly assignment: UserAssignment,
        readonly sessions: SessionInfo[],
    ) { }

    /**
     * Get assignment status
     */
    getStatus(): Exclude<UserLearningStatus, 'passed' | 'failed'> {
        let allCompleted = true;
        let someStarted = false;

        this.allActivities.forEach((activity) => {
            const hasCompleted = this.relevantSessions.some((s) => s.activityId === activity.identifier && s.completed);
            if (hasCompleted) {
                someStarted = true;
            } else {
                allCompleted = false;
            }
        });

        if (allCompleted) {
            return 'completed';
        }
        return someStarted ? 'in-progress' : 'not-started';
    }

    /**
     * Gets the overall assignment progress as a percentage (0-1)
     */
    getProgress(): number {
        // At least one completed session for all activities
        const completed = this.allActivities.reduce((count, activity) => {
            return this.relevantSessions.find(s => s.activityId === activity.identifier && s.completed) != null ? count + 1 : count;
        }, 0);
        return (completed / this.allActivities.length);
    }

    /**
     * Returns the best score for the given unit
     * @param id
     */
    getBestResultForUnit(id: string): Mandatory<SessionInfo, 'score', 'score'>['score'] | undefined {
        const unit = this.getUnit(id);
        if (!unit) { return; }
        const best = this.relevantSessions
            .filter((s: SessionInfo): s is Mandatory<SessionInfo, 'score', 'score'> =>
                unit.identifier === s.activityId && s.score != null && s.score.score != null
            )
            .sort((a, b) => {
                return a.score.score.percentage > b.score.score.percentage ? -1 : 1;
            })[0];
        return best ? best.score : undefined;
    }

    /**
     * Returns the status for the given unit.
     *
     * Will either return a status object including the score (enable `includeScore`),
     * or just the UserLearningStatus string.
     * Note: two additional status strings ('passed' and 'failed') will be added to the
     * return type when 'includeScore' is enabled.
     *
     * @param id
     * @param includedScore     set to True to return an object including score
     */
    getStatusForUnit(id: string): Exclude<UserLearningStatus, 'passed' | 'failed'>;
    getStatusForUnit(id: string, includeScore: true): UnitStatusWithScore;
    getStatusForUnit(id: string, includeScore?: true): UserLearningStatus | UnitStatusWithScore {
        const unitSessions = this.relevantSessions.filter(s => s.activityId === id);
        const status: UnitStatusWithScore = {
            status: 'not-started'
        };
        if (unitSessions.length > 0) {
            const completed: boolean = unitSessions.some((session: SessionInfo) => session.completed);
            status.status = completed ? 'completed' : 'in-progress';

            if (includeScore === true) {
                // Check if there's a score
                const bestResult = this.getBestResultForUnit(id);
                if (bestResult) {
                    status.status = (bestResult) ? bestResult.passed ? 'passed' : 'failed' : status.status;
                    status.score = bestResult.score.percentage;
                }
            }
        }
        return (includeScore === true) ? status : status.status;
    }

    /**
     * Returns a date representing the unit's last activity
     * @param id. @see {getLastActivity}
     */
    getLastActivityForUnit(id: string): Date | undefined {
        return this.getLastActivity(id);
    }

    /**
     * Returns the date of the assignments last activity.
     * By providing an ID you can get the last activity for a
     * specific unit within the assignment.
     *
     */
    getLastActivity(id?: string): Date | undefined {
        const sessions = (id != null) ? this.relevantSessions.filter(s => s.activityId === id) : this.relevantSessions;
        return sessions.reduce((p: Date | undefined, c: SessionInfo) => {
            if (!p || p < c.from) { return c.from; }
            return p;
        }, undefined);
    }

    /**
     * Searches through all activities in the assignment and returns
     * the one matching the given ID
     * @param id
     */
    private getUnit(id: string): Unit | undefined {
        return this.allActivities.find(u => u.identifier === id);
    }
}
