import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { LoadingBarComponent } from '@app/loading-bar';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent {

    @ViewChild('loader') loader: LoadingBarComponent;

    constructor(
        router: Router
    ) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.loader.start();
            }
            if (event instanceof NavigationEnd) {
                this.loader.complete();
            }
            if (event instanceof NavigationError) {
                this.loader.complete();
            }
        });
    }
}
