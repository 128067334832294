import { Action, ActionReducer } from '@ngrx/store';

/**
 * Undo action type
 */
export const UNDO_ACTION = '[NovoUndo] Undo Action';

/**
 * Action to use when undoing an action
 */
export class UndoAction implements Action {
    readonly type = UNDO_ACTION;
    constructor(readonly action: Action) { }
}

// Ngrx "core" actions which should be skipped when recording
const IGNORE_ACTIONS: string[] = [
    '@ngrx/store/init',
    '@ngrx/store/update-reducers',
    '@ngrx/store-devtools/recompute'
];

/**
 * Number of actions which should be stored.
 *
 * Note that Actions can only be undone when they're
 * available in the history.
 */
const ACTION_HISTORY_SIZE = 50;

/**
 * The number of actions between every snapshot.
 * More snapshots will increase the memory footprint while
 * less snapshots will increase the CPU usage during an Undo.
 */
const SNAPSHOT_INTERVAL = 10;

/**
 * History of actions. Increase or decrease the size of this array
 * by changing @see {ACTION_HISTORY_SIZE}.
 */
let executedActions: Array<Action> = [];

/**
 * State snapshots. Increase or decrease the number of snapshots by
 * changing @see {SNAPSHOT_INTERVAL}
 */
const stateSnapshots: any[] = [];


const isUndoAction = (action: Action): action is UndoAction => {
    return action.type === UNDO_ACTION;
}

export function undoReducer<T>(rootReducer: ActionReducer<T>): ActionReducer<T> {
    return (state: T, action: Action): T => {
        // Check if action is of type Undo. If so replay all actions except
        // the one provided in the Undo Action.
        if (isUndoAction(action)) {
            const actionIndex = executedActions.findIndex(eAct => eAct === action.action);

            if (actionIndex === -1) {
                console.warn(`[undoReducer] Action ${action.action.type} does not exist in history. Consider increasing the ACTION_HISTORY_SIZE?`);
            }
            else {
                // Grab nearest snapshot
                const snapshotIndex = Math.floor(actionIndex / SNAPSHOT_INTERVAL);
                const stateSnapshot = stateSnapshots[snapshotIndex];

                if (!stateSnapshot) {
                    console.error(`[undoReducer] No matching snapshot for action on index ${actionIndex}.`);
                }
                else {
                    // Invalidate all snapshots, since there data wont represent the correct state.
                    // The invalidated snapshots will be re-created as soon as
                    // the executedActions are 'replayed'
                    stateSnapshots.splice(snapshotIndex + 1);

                    // Remove action from executedActions list
                    executedActions.splice(actionIndex, 1);

                    // Replay all actions on the snapshot, starting from the action
                    // belonging to the snapshot.
                    const newState = executedActions.slice(snapshotIndex * SNAPSHOT_INTERVAL).reduce((state, action, index) => {
                        state = rootReducer(state, action);
                        // Make sure to create new snapshots
                        if (((snapshotIndex * SNAPSHOT_INTERVAL) + index + 1) % SNAPSHOT_INTERVAL === 0) {
                            stateSnapshots.push(state);
                        }
                        return state;
                    }, stateSnapshot);

                    return newState;
                }
            }
        }

        // Create an initial snapshot of the first available state.
        // This snapshot usually contains the 'default' state.
        if (executedActions.length === 0 && stateSnapshots.length === 0) {
            stateSnapshots.push(state);
        }

        // Push new action to executedActions if it's not a "Ngrx Core" action
        if (!IGNORE_ACTIONS.includes(action.type)) {
            executedActions.push(action);
        }

        const updatedState = rootReducer(state, action);

        // Check if a new snapshot should be created
        const actionSize = executedActions.length;
        if (actionSize > 0 && actionSize % SNAPSHOT_INTERVAL === 0) {
            // Add last state as snapshot
            stateSnapshots.push(updatedState);

            // Clear previous snapshot and actions when executedActions
            // size crosses the max history size
            if (actionSize >= (ACTION_HISTORY_SIZE + SNAPSHOT_INTERVAL)) {
                // Remove the first 'chunk' of actions and
                // clear the belonging snapshot
                executedActions.splice(0, SNAPSHOT_INTERVAL);
                stateSnapshots.splice(0, 1);
            }
        }

        return updatedState;
    };
}