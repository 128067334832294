import { CorrectResponse, CorrectResponseOption, FeedbackMessage, Interaction, InteractionInputItem, InteractionType } from './interaction/interaction';
import { AudioInteractionOption, ImageInteractionOption, InteractionOption, PronunciationInteractionOption, TextInteractionOption } from './interaction/option';

/**
 * Create an InteractionItem with default settings
 */
export class InteractionItemFactory {

    create(type: InteractionType): Interaction {
        const interaction = new Interaction(type);
        interaction.feedbackMessages = new Map<string, FeedbackMessage>();

        if (type.startsWith('inline')) {
            interaction.inputMethod = 'mouse';
        } else if (type.startsWith('choice') || type.startsWith('branching')) {
            interaction.items.push(this.createChoiceItem(type));
            interaction.inputMethod = 'mouse';
        } else if (type.startsWith('order')) {
            interaction.items.push(this.createOrderItem(type));
            interaction.inputMethod = 'mouse';
        } else if (type === 'conversation') {
            interaction.inputMethod = 'microphone';
        } else if (type === 'pronunciation') {
            interaction.items.push(this.createPronunciationItem());
            interaction.inputMethod = 'microphone';
        } else if (type.indexOf('text-entry') >= 0) {
            interaction.items.push(new InteractionInputItem(type));
            if (type.indexOf('text-entry-set') === 0) {
                const inputItem = (interaction.items[0] as InteractionInputItem);
                inputItem.options.push(this.createOption(type));

                const corOption = new CorrectResponse();
                corOption.source = inputItem.options[0].id;
                const correctOption = new CorrectResponseOption();
                correctOption.values.push(corOption);
                inputItem.correctResponses.push(correctOption);
            }
            interaction.inputMethod = 'keyboard';
        } else if (type.startsWith('hot-spot-vocabulary')) {
            interaction.items.push(new InteractionInputItem(type));
            interaction.inputMethod = 'microphone';
        } else {
            interaction.items.push(new InteractionInputItem(type));
            interaction.inputMethod = 'mouse';
        }
        return interaction;
    }

    private createPronunciationItem(): InteractionInputItem {
        const item = new InteractionInputItem('pronunciation');
        item.shuffle = true;
        item.minResponses = 1;
        item.maxResponses = 1;
        item.display = { mode: 'block' };

        const o: PronunciationInteractionOption = new PronunciationInteractionOption();
        item.options.push(o);

        const response = new CorrectResponseOption();
        const sources: CorrectResponse[] = item.options.map((pio: PronunciationInteractionOption) => {
            const c = new CorrectResponse();
            c.source = pio.id;
            return c;
        });
        response.values = sources;
        item.correctResponses[0] = response;

        return item;
    }

    private createOrderItem(type: InteractionType): InteractionInputItem {
        const item = new InteractionInputItem(type);
        item.shuffle = true;
        item.minResponses = 1;
        item.maxResponses = 1;
        item.display = { mode: 'inline' };

        // Add two response options
        for (let i = 0; i < 2; i++) {
            item.options.push(this.createOption(type));
        }
        // No default correct response. The default correct response will be initialized
        // in the editor to include all items in the order specified by the content editor
        // as this is the most likely corrcet response.
        item.correctResponses = [];

        return item;
    }

    private createChoiceItem(type: InteractionType): InteractionInputItem {
        const item = new InteractionInputItem(type);
        item.shuffle = true;
        item.minResponses = 1;
        item.maxResponses = 1;
        item.display = { mode: 'block' };

        // Add two response options
        for (let i = 0; i < 2; i++) {
            item.options.push(this.createOption(type));
        }

        if (type.indexOf('branch') >= 0) {
            item.correctResponses = new Array<CorrectResponseOption>();
            // Add a correct response for each option for branching
            for (const option of item.options) {
                const correctResponse = new CorrectResponse();
                correctResponse.source = option.id;
                const correctOption = new CorrectResponseOption();
                correctOption.values.push(correctResponse);
                item.correctResponses.push(correctOption);
            }
        } else {
            // Mark the first response option correct
            const firstOption = new CorrectResponse();
            firstOption.source = item.options[0].id;
            const correctOption = new CorrectResponseOption();
            correctOption.values.push(firstOption);
            item.correctResponses.push(correctOption);
        }

        return item;
    }

    private createOption(type: InteractionType): InteractionOption {
        let option;
        if (type.indexOf('graphic') >= 0) {
            option = new ImageInteractionOption();
        } else if (type.indexOf('audio') >= 0) {
            option = new AudioInteractionOption();
        } else {
            option = new TextInteractionOption();
        }
        return option;
    }
}
