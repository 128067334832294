// Word error rate calculations

// Compute minimum edit distance between two arrays, and keep track of the alignment.

export enum decisionCode {
    CORRECT = 0,
    SUBSTITUTION,
    INSERTION,
    DELETION
};

export interface WERAlignment {
    refali: number[],
    hypali: number[],
    evalali: number[]
};

/**
 * Determine the index and the value of the minimum in an array
 * @param a The array containing the values
 */
export function indmin(a: number[]): { min: number, ind: number } {
    if (a.length === 0) { return { ind: -1, min: 0 }; }
    let min = a[0];
    let ind = 0;
    for (let i = 1; i < a.length; i++) {
        if (a[i] < min) {
            min = a[i];
            ind = i;
        }
    }
    return { ind, min };
}

export class WordErrorRate {
    ref: string[];
    hyp: string[];
    nsub: number;
    nins: number;
    ndel: number;
    refeval: number[];
    hypeval: number[];

    constructor(ref: string[], hyp: string[], subcost = 4, inscost = 3, delcost = 3) {
        const nrow = ref.length + 1;
        const ncol = hyp.length + 1;
        // initialization
        const cost = new Array(nrow).fill(0).map(x => Array(ncol).fill(0));
        const decision = new Array(nrow).fill(0).map(x => Array(ncol).fill(0));
        for (let i = 1; i < nrow; i++) {
            cost[i][0] = i * delcost;
            decision[i][0] = decisionCode.DELETION;
        }
        for (let j = 1; j < ncol; j++) {
            cost[0][j] = j * inscost;
            decision[0][j] = decisionCode.INSERTION;
        }
        // computation
        for (let i = 1; i < nrow; i++) {
            for (let j = 1; j < ncol; j++) {
                if (ref[i - 1] === hyp[j - 1]) {
                    cost[i][j] = cost[i - 1][j - 1];
                    decision[i][j] = decisionCode.CORRECT;
                } else {
                    const sid = [cost[i - 1][j - 1] + subcost, cost[i][j - 1] + inscost, cost[i - 1][j] + delcost];
                    const { ind, min } = indmin(sid);
                    cost[i][j] = min;
                    decision[i][j] = ind + 1; // 1 => subs, etc
                }
            }
        }
        // backtrack
        this.refeval = [];
        this.hypeval = [];
        let i = nrow - 1,
            j = ncol - 1;
        this.nsub = this.nins = this.ndel = 0;
        while ((i > 0) || (j > 0)) {
            const dec = decision[i][j];
            if (dec < 2) { // correct, substitution
                this.nsub += dec; // 1 if substitution, 0 otherwise
                i -= 1;
                j -= 1;
                this.refeval.splice(0, 0, dec); // insert at start
                this.hypeval.splice(0, 0, dec);
            } else if (dec === decisionCode.INSERTION) { // insertion
                j -= 1;
                this.hypeval.splice(0, 0, dec);
                this.nins += 1;
            } else { // deletion
                i -= 1;
                this.refeval.splice(0, 0, dec);
                this.ndel += 1;
            }
        }
        this.ref = ref;
        this.hyp = hyp;
    }

    get wer(): number {
        return (this.nsub + this.nins + this.ndel) / this.ref.length;
    }

    get align(): WERAlignment {
        const refali: number[] = [];
        const hypali: number[] = [];
        const evalali: number[] = [];
        let ri = 0, hi = 0;
        while (ri < this.refeval.length || hi < this.hypeval.length) {
            if ((ri < this.refeval.length) && this.refeval[ri] === decisionCode.DELETION) {
                hypali.push(-1);
                refali.push(ri);
                evalali.push(decisionCode.DELETION);
                ri += 1;
            } else if ((hi < this.hypeval.length) && this.hypeval[hi] === decisionCode.INSERTION) {
                refali.push(-1);
                hypali.push(hi);
                evalali.push(decisionCode.INSERTION);
                hi += 1;
            } else {
                refali.push(ri);
                hypali.push(hi);
                evalali.push(this.refeval[ri]);
                ri += 1;
                hi += 1;
            }
        }
        return { refali, hypali, evalali };
    }

}

