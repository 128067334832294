import { UUID } from 'angular2-uuid';
import { classToPlain, Type } from 'class-transformer';
import { InternationalText, Language } from '../international-text';
import { ImageAvatarInstance, MediaInstance } from '../media';
import { InputMethod, InteractionInstruction } from '../model-types';
import { InteractionScore, ScoringSettings } from '../score';
import { notEmpty, serializeType } from '../utilities';
import { MatchTarget } from './match';
import { InteractionOption, PronunciationInteractionOption } from './option';

// The known types of interactions a user can define
export type InteractionType
    = 'choice-text'
    | 'choice-audio'
    | 'choice-graphic'
    | 'conversation'
    | 'branching-choice-text'
    | 'branching-choice-graphic'
    | 'branching-choice-audio'
    | 'pronunciation'
    | 'inline-choice-text'
    | 'inline-match-text'
    | 'inline-text-entry'
    | 'hot-spot-match-text'
    | 'hot-spot-navigation'
    | 'order-text'
    | 'order-graphic'
    | 'open-text-entry'
    | 'text-entry-set';

export type DisplayMode = 'inline'  // Options are shown within the surrounding text
    | 'block'    // Options are presented as a block; separated from the surrounding text
    | 'hidden';  // Options are not shown on the slide

export type PresentationMode = 'all' // Show all options at once
    | 'sequential'; // Only for 'match' interactions. Show one option at a time; when that one has been matched show the next



export class CorrectResponse {
    source?: string = undefined;
    target?: string = undefined;

    static initialize(json: any): CorrectResponse {
        const option = new CorrectResponse();
        for (const key of Object.keys(option)) {
            option[key] = json[key];
        }
        return option;
    }
}

export class CorrectResponseOption {
    @Type(serializeType(CorrectResponse)) values: CorrectResponse[] = [];
    // The slide to go to when this option has been selected by the user
    nextSlide?: string = undefined;
    // The score the user will get when responding with this option.
    @Type(serializeType(InteractionScore)) score?: InteractionScore = undefined;

    static initialize(json: any): CorrectResponseOption {
        const option = new CorrectResponseOption();
        for (const key of Object.keys(option)) {
            if (key === 'values') {
                for (const value of json.values) {
                    option.values.push(CorrectResponse.initialize(value));
                }
            } else if (key === 'score') {
                option[key] = InteractionScore.initialize(json[key]);
            } else {
                option[key] = json[key];
            }
        }
        return option;
    }

    constructor() {
        this.values = new Array<CorrectResponse>();
    }

    hasSameValuesAs(response: CorrectResponseOption): boolean {
        if (this.values.length !== response.values.length) { return false; }
        for (let k = 0; k < response.values.length; k++) {
            if (this.values[k].source != response.values[k].source || this.values[k].target != response.values[k].target) {
                return false;
            }
        }
        return true;
    }
}

export abstract class InteractionItem {

    // The type of the interaction
    readonly type: 'text' | InteractionType | 'conversation-turn' | 'conversation-input-turn';

    identifier: string;
    static initialize(json: any): InteractionItem {
        let result;
        if (json.type === 'text') {
            result = InteractionTextItem.initialize(json);
        } else if (json.type.startsWith('conversation')) {
            result = InteractionConversationItem.initialize(json);
        } else {
            result = InteractionInputItem.initialize(json);
        }
        return result;
    }

    constructor(type: 'text' | InteractionType | 'conversation-turn' | 'conversation-input-turn') {
        this.type = type;
        this.identifier = UUID.UUID();
    }

    isTextInteraction(): boolean {
        return this.type === 'text';
    }

    static isInputInteraction(item: InteractionItem): item is InteractionInputItem {
        return item.type !== 'text';
    }

    isLanguageCompatible(lang: Language): boolean {
        return true;
    }
}

export interface DisplayOption {
    mode?: DisplayMode;
    show?: PresentationMode;
}

export class InteractionConversationItem extends InteractionItem {

    public display: DisplayOption = {};
    @Type(serializeType(ImageAvatarInstance)) public avatar?: ImageAvatarInstance = undefined;
    @Type(serializeType(PronunciationInteractionOption)) public options: PronunciationInteractionOption[] = [];

    static initialize(json: any): InteractionConversationItem {
        const interaction = new InteractionConversationItem(json.type);
        for (const key of Object.keys(interaction)) {
            if (json[key] == null) {
                continue;
            }

            if (key === 'options') {
                interaction.options = json.options.map(o => PronunciationInteractionOption.initialize(o));
            } else if (key === 'display') {
                interaction.display = json.display;
            } else if (key === 'avatar') {
                // Use MediaInstance.initialize() as the media files are otherwise not correctly initialized
                interaction.avatar = MediaInstance.initialize(json.avatar) as ImageAvatarInstance;
            } else {
                interaction[key] = json[key];
            }
        }
        return interaction;
    }

    constructor(type: 'conversation-turn' | 'conversation-input-turn') {
        super(type);
    }

    isLanguageCompatible(lang: Language): boolean {
        return this.options.every(o => o == null || o.isLanguageCompatible(lang));
    }
}

export class InteractionInputItem extends InteractionItem {

    shuffle = true; // Do the options need to be shuffled before presenting them to the user?
    display: DisplayOption = {};
    minResponses?: number = undefined; // The minimum number of options to be chosen / matches made
    maxResponses?: number = undefined; // The maximum number of options to be chosen / matches made

    @Type(serializeType(InteractionOption)) options: InteractionOption[] = []; // The set of options for the user
    @Type(serializeType(MatchTarget)) matchTarget?: MatchTarget = undefined; // The context for a match
    @Type(serializeType(CorrectResponseOption)) correctResponses: CorrectResponseOption[] = [];

    static initialize(json: any): InteractionItem {
        const interaction = new InteractionInputItem(json.type);
        for (const key of Object.keys(interaction)) {
            if (json[key] == null) {
                continue;
            }
            if (key === 'options') {
                for (const option of json.options) {
                    const io = InteractionOption.initialize(option);
                    if (io != null) {
                        interaction.options.push(io);
                    }
                }
            } else if (key === 'correctResponses') {
                interaction.correctResponses = new Array<CorrectResponseOption>(); // Remove default content for a new slide
                for (const response of json.correctResponses) {
                    interaction.correctResponses.push(CorrectResponseOption.initialize(response));
                }
            } else if (key === 'display') {
                interaction.display = json.display;
            } else if (key === 'matchTarget') {
                interaction.matchTarget = MatchTarget.initialize(json.matchTarget);
            } else {
                interaction[key] = json[key];
            }
        }
        return interaction;
    }

    static is(item: InteractionItem): item is InteractionInputItem {
        return item.type !== 'text' && !item.type.startsWith('conversation');
    }

    constructor(type: InteractionType) {
        super(type);
    }

    getNumberOfCorrectResponses(): number {
        return this.correctResponses.length;
    }

    getScores(): InteractionScore[] {
        return this.correctResponses.map(cro => cro.score).filter(notEmpty);
    }

    addCorrectResponses() {
        this.correctResponses.push(new CorrectResponseOption());
    }

    removeCorrectResponses() {
        this.correctResponses.splice(this.correctResponses.length - 1, 1);
    }

    /**
     * Remove any reference to an option from the correct responses
     */
    removeCorrectOption(id: string) {
        for (let i = 0; i < this.correctResponses.length; i++) {
            const response = this.correctResponses[i];
            response.values = response.values.filter(option => option.source !== id && option.target !== id);
        }
    }

    isLanguageCompatible(lang: Language): boolean {
        return this.options.every(o => o == null || o.isLanguageCompatible(lang));
    }
}

export class InteractionTextItem extends InteractionItem {

    text = '';
    static initialize(json: any): InteractionItem {
        const interaction = new InteractionTextItem();
        for (const key of Object.keys(interaction)) {
            interaction[key] = json[key];
        }
        return interaction;
    }

    static is(item: InteractionItem): item is InteractionTextItem {
        return item.type === 'text';
    }

    constructor() {
        super('text');
    }
}

export class FeedbackMessage {
    enabled = false;
    text: InternationalText = new InternationalText();

    static initialize(json: any): FeedbackMessage {
        const fm = new FeedbackMessage();
        if (json.enabled) {
            fm.enabled = json.enabled;
        }
        if (json.text) {
            fm.text = InternationalText.initialize(json.text);
        }
        return fm;
    }
}

export class Interaction {

    get instruction(): InteractionInstruction[] {

        let steps: InteractionInstruction[] = [];
        let hiddenText: boolean | undefined;

        if (this.type === 'choice-text') {
            if (this.inputMethod === 'microphone') {
                const item = (this.items[0] as InteractionInputItem);
                hiddenText = item.display && item.display.mode === 'hidden';
                steps = [
                    'start-recording',
                    hiddenText ? 'say' : 'select-speech-option'
                ];
            } else if ((this.items[0] as InteractionInputItem).maxResponses === 1) {
                steps = [
                    'select-text-option'
                ];
            } else {
                steps = [
                    'select-text-options'
                ];
            }
        } else if (this.type === 'choice-audio') {
            if ((this.items[0] as InteractionInputItem).maxResponses === 1) {
                steps = [
                    'play-audio-options',
                    'select-audio-option'
                ];
            } else {
                steps = [
                    'play-audio-options',
                    'select-audio-options'
                ];
            }
        } else if (this.type === 'choice-graphic') {
            if ((this.items[0] as InteractionInputItem).maxResponses === 1) {
                steps = [
                    'select-img-option'
                ];
            } else {
                steps = [
                    'select-img-options'
                ];
            }
        } else if (this.type === 'branching-choice-text') {
            if (this.inputMethod === 'microphone') {
                steps = [
                    'start-recording',
                    'select-speech-option'
                ];
            } else {
                steps = [
                    'select-text-option'
                ];
            }
        } else if (this.type === 'branching-choice-graphic') {
            steps = [
                'select-img-option'
            ];
        } else if (this.type === 'branching-choice-audio') {
            steps = [
                'play-audio-options',
                'select-audio-option'
            ];
        } else if (this.type === 'pronunciation') {
            hiddenText = (this.items[0] as InteractionInputItem).display.mode === 'hidden';
            const o: PronunciationInteractionOption = (this.items[0] as InteractionInputItem).options[0] as PronunciationInteractionOption;
            const hasExampleAudio: boolean = o.exampleAudio != null;
            if (hasExampleAudio) { steps.push('play-example-audio'); }
            steps.push('start-recording');
            if (hiddenText) {
                steps.push('say');
            } else {
                steps.push('read-text');
            }
        } else if (this.type === 'inline-choice-text') {
            steps = [
                'select-inline-options'
            ];
        } else if (this.type === 'inline-match-text') {
            steps = [
                'match-inline-options'
            ];
        } else if (this.type === 'inline-text-entry') {
            steps = [
                'enter-inline-text'
            ];
        } else if (this.type === 'hot-spot-match-text') {
            steps = [
                'match-text-hotspot'
            ];
        } else if (this.type === 'order-text') {
            steps = [
                'order-text-options'
            ];
        } else if (this.type === 'order-graphic') {
            steps = [
                'order-img-options'
            ];
        } else if (this.type === 'open-text-entry') {
            steps = [
                'enter-text'
            ];
        } else if (this.type === 'text-entry-set') {
            steps = [
                'enter-text'
            ];
        }

        return steps;
    }

    /**
     * The type of the interaction defines the lay-out and behavior of the interaction
     * An inline type allows for instance multiple items in the items array while an
     * interaction which allows a branch for every response of a multiple choice
     * interaction only allows one item.
     */
    public readonly type: InteractionType;
    public inputMethod: InputMethod = 'none';
    public display: DisplayOption = {};
    public items: InteractionItem[] = [];

    @Type(serializeType(FeedbackMessage))
    public feedbackMessages?: Map<string, FeedbackMessage> = undefined;

    static initialize(json: any): Interaction {
        const interaction = new Interaction(json.type);
        interaction.feedbackMessages = new Map<string, FeedbackMessage>();

        for (const key of Object.keys(interaction)) {
            if (json[key] == null) {
                continue;
            }
            if (key === 'display') {
                interaction.display = json.display;
            } else if (key === 'items') {
                for (const item of json.items) {
                    interaction.items.push(InteractionItem.initialize(item));
                }
            } else if (key === 'feedbackMessages') {
                for (const msgKey of Object.keys(json.feedbackMessages)) {
                    interaction.feedbackMessages.set(msgKey, FeedbackMessage.initialize(json.feedbackMessages[msgKey]));
                }
            } else if (key !== 'type') {
                interaction[key] = json[key];
            }
        }
        return interaction;
    }

    static correctScoring(interaction: any, scoringSettings: ScoringSettings | undefined): any {
        interaction.items.forEach(item => {
            if (item.correctResponses != null) {
                item.correctResponses.forEach(resp => {
                    if (scoringSettings == null) {
                        delete resp.score;
                    } else if (resp.score == null) {
                        resp.score = scoringSettings.getDefaultScore();
                    } else {
                        // Set default score if there is a different scoring dimension
                        const respDims = resp.score.dimensions;
                        let incorrectScoringDimension = false;
                        if (respDims instanceof Map) {
                            // Interaction was passed as argument
                            respDims.forEach((value, dimId) => {
                                incorrectScoringDimension = incorrectScoringDimension
                                    || scoringSettings.dimensions.findIndex(dim => dim.identifier === dimId) < 0;
                            });
                        } else {
                            // Plain object was passed as argument
                            incorrectScoringDimension = Object.keys(respDims)
                                .some(dimId => scoringSettings.dimensions.findIndex(dim => dim.identifier === dimId) < 0);
                        }
                        incorrectScoringDimension = incorrectScoringDimension ||
                            scoringSettings.dimensions.some(dim => respDims[dim.identifier] == null);

                        if (incorrectScoringDimension) {
                            const newScore = scoringSettings.getDefaultScore();
                            if (respDims instanceof Map) {
                                // Interaction was passed as argument
                                resp.score = newScore;
                            } else {
                                // Plain object was passed as argument
                                resp.score = classToPlain(newScore);
                            }
                        }
                    }
                });
            }
        });
        return interaction;
    }

    constructor(type: InteractionType) {
        this.type = type;
    }

    isBranchingInteraction(): boolean {
        return this.type.indexOf('branch') >= 0 || this.type.indexOf('hot-spot-navigation') >= 0;
    }

    getSampleSolution(): InternationalText | undefined {
        return this.getFeedbackMessage('sampleSolution');
    }

    hasPronunciationFeedback(): boolean {
        return ['pronunciation', 'conversation'].includes(this.type);
    }

    getFeedbackMessage(key: string): InternationalText | undefined {
        const message = this.feedbackMessages && this.feedbackMessages.get(key);
        if (message != null && message.enabled) {
            return message.text;
        }
    }

    isLanguageCompatible(lang: Language): boolean {
        return this.inputMethod !== 'microphone' || this.items.every(item => item == null || item.isLanguageCompatible(lang));
    }
}
