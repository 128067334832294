import { InteractionResponse, InteractionResponseItem, InteractionResponseOption, ResponseByUser } from '../interaction-response';
import { Interaction, InteractionInputItem } from '../interaction/interaction';
import { TextInteractionOption } from '../interaction/option';
import { InteractionScore, NoInteractionScore } from '../score';
import { isEqual, StringEqualityOptions } from '../utilities';
import { BaseInteractionModel, ResponseFeedbackItem, ResponseStatistics } from './base-interaction-model';
export class TextEntrySetInteractionModel extends BaseInteractionModel {
    private static readonly equalOptions: StringEqualityOptions = {
        ignoreCase: true,
        ignorePunctuation: false,
        ignoreExtraneousWhitespace: true,
        ignoreWhitespaceBeforePunctuation: true
    };

    private maxAnswers: number;
    private minRequired: number;
    private inputItem: InteractionInputItem;
    private options: string[];

    constructor(interaction: Interaction) {
        super();
        this.inputItem = interaction.items[0] as InteractionInputItem;
        this.options = this.inputItem.options.map(option => (option as TextInteractionOption).text);
        this.maxAnswers = this.inputItem.maxResponses || this.inputItem.options.length;
        this.minRequired = this.inputItem.minResponses || this.maxAnswers;

    }

    isCorrect(responseTemplate: InteractionResponse): boolean {
        // Get the number of unique answers
        const answers: { [option: string]: true } = {};
        responseTemplate.items.forEach(item => {
            if (item == null) { return; }
            const answer = item.options[0];
            const enteredOption = this.options.find(option => {
                return isEqual(option, answer.text, TextEntrySetInteractionModel.equalOptions);
            });
            if (enteredOption && !answers[enteredOption]) {
                item.correct = true;
                answers[enteredOption] = true;
            }
        });
        return Object.keys(answers).length >= this.minRequired;
    }

    getScore(responseTemplate: InteractionResponse): InteractionScore | NoInteractionScore {
        const answers: { [option: string]: true } = {};
        responseTemplate.items.forEach(item => {
            if (item == null) { return; }
            const answer = item.options[0];
            const enteredOption = this.inputItem.options.find((option: TextInteractionOption) => {
                return isEqual(option.text, answer.text, TextEntrySetInteractionModel.equalOptions);
            });
            if (enteredOption) {
                answers[enteredOption.id] = true;
            }
        });

        let score: InteractionScore | NoInteractionScore = new NoInteractionScore();
        for (const key of Object.keys(answers)) {
            const corResponse = this.inputItem.correctResponses.find(cr => cr.values[0].source === key);
            // TODO: decide 0 score when 1 failure?
            if (corResponse && corResponse.score) {
                score = score.add(corResponse.score);
            }
        }
        return score;
    }

    getMaxScore(): InteractionScore | NoInteractionScore {
        const scores: { [key: string]: number[] } = {};
        this.inputItem.correctResponses.forEach((cr, idx) => {
            if (cr.score) {
                Object.entries(cr.score.dimensions).forEach(([key, value]) => {
                    if (idx === 0) {
                        scores[key] = [value!];
                    } else {
                        scores[key].push(value!);
                    }
                });
            }
        });

        const maxScore = new InteractionScore();
        Object.keys(scores).forEach(key => {
            maxScore.dimensions[key] = scores[key]
                .sort((s1, s2) => s2 - s1)
                .slice(0, this.maxAnswers)
                .reduce((prev, curr) => prev + curr, 0);

        });
        return maxScore;
    }

    getResponseTemplate(interaction: Interaction): InteractionResponse {
        const ir = new InteractionResponse();
        for (let i = 0; i < this.maxAnswers; i++) {
            const iri = new InteractionResponseItem();
            iri.options.push(new InteractionResponseOption());
            ir.items.push(iri);
        }
        return ir;
    }

    public collectStatistics(responses: ResponseByUser[]): ResponseStatistics[] {
        const stats: { [key: string]: ResponseStatistics } = {};
        stats['missed'] = { id: 'missed', count: 0, tooltip: undefined, correct: false };

        this.options.forEach(option => {
            stats[option] = { id: option, count: 0, tooltip: option, correct: true };
        });

        responses.forEach(userResponse => {
            const answers = userResponse.response.items;
            // Only count empty fields below the minimum as missing
            if (answers.length < this.minRequired) {
                stats['missed'].count += answers.length - this.minRequired;
            }

            answers.forEach(rItem => {
                if (rItem == null) { return; }
                const option = rItem.options[0];
                const selectedId = Object.keys(stats)
                    .find(key => isEqual(stats[key].tooltip as string, option.text, TextEntrySetInteractionModel.equalOptions))
                    || option.text;

                if (selectedId != null) {
                    if (stats[selectedId] == null) {
                        stats[selectedId] = { id: selectedId, count: 0, tooltip: selectedId, correct: false };
                    }
                    stats[selectedId].count++;
                }
            });
        });
        return Object.keys(stats).map(key => stats[key]);
    }

    public getResponseFeedback(response: InteractionResponse): ResponseFeedbackItem[] {
        const result: ResponseFeedbackItem[] = [];
        const answers: { [key: string]: boolean } = {};
        response.items.forEach(item => {
            if (item == null) { return; }

            const answer = item.options[0].text;
            if (answer) {
                const enteredOption = this.options.find(option => isEqual(option, answer, TextEntrySetInteractionModel.equalOptions));
                if (enteredOption && !answers[enteredOption]) {
                    answers[enteredOption] = true;
                    result.push({ item: answer, statusCode: 'correct' });
                } else {
                    result.push({ item: answer, statusCode: 'incorrect' });
                }
            }
        });

        if (this.maxAnswers === this.inputItem.options.length) {
            this.options.forEach(option => {
                if (!answers[option]) {
                    result.push({ item: option, statusCode: 'deleted' });
                }
            });
        }
        return result;
    }
}
