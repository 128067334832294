import { InputMethod, MediaInstance, MediaInteractionOption, ResponseFeedbackItem, StatusCode, TextInteractionOption } from '..';
import { InteractionResponse, InteractionResponseItem, ResponseByUser } from '../interaction-response';
import { Interaction, InteractionInputItem } from '../interaction/interaction';
import { InteractionScore, NoInteractionScore } from '../score';
import { notEmpty } from '../utilities';
import { BaseInteractionModel, ResponseStatistics } from './base-interaction-model';

export class BranchingInteractionModel extends BaseInteractionModel {
    private inputItem: InteractionInputItem;
    private inputMethod: InputMethod;

    constructor(interaction: Interaction) {
        super();
        this.inputItem = interaction.items[0] as InteractionInputItem;
        this.inputMethod = interaction.inputMethod;
    }

    getResponseTemplate(interaction: Interaction): InteractionResponse {
        const rItem = new InteractionResponseItem();
        const rResponse = new InteractionResponse();
        rResponse.items.push(rItem);
        return rResponse;
    }

    isCorrect(responseTemplate: InteractionResponse): boolean {
        const item = responseTemplate.items[0];
        if (item == null) { return false; }
        return item.options.length === 1;
    }

    getScore(responseTemplate: InteractionResponse): InteractionScore | NoInteractionScore {
        const item = responseTemplate.items[0];
        if (this.isCorrect(responseTemplate) && item != null) {
            const selectedId = item.options[0].source;
            const option = this.inputItem.correctResponses.find(cro => cro.values[0].source === selectedId);
            if (option && option.score) { return option.score; }
        }
        return new NoInteractionScore();
    }

    getMaxScore(): InteractionScore | NoInteractionScore {
        return this.inputItem.correctResponses.reduce(
            (prev: InteractionScore | NoInteractionScore, curr) => curr.score ? InteractionScore.max(prev, curr.score) : prev, new NoInteractionScore()
        );
    }

    public collectStatistics(responses: ResponseByUser[]): ResponseStatistics[] {
        const stats: { [key: string]: ResponseStatistics } = { 'missed': { id: 'missed', count: 0, tooltip: undefined, correct: false } };
        this.inputItem.options.forEach(option => {
            let tooltip: string | MediaInstance | undefined;
            if (option instanceof TextInteractionOption) {
                tooltip = option.text;
            } else if (option instanceof MediaInteractionOption) {
                tooltip = option.media;
            }
            stats[option.id] = { id: option.id, count: 0, tooltip: tooltip, correct: true };
        });

        responses.forEach(userResponse => {
            const responseItem = userResponse.response.items[0];
            if (responseItem == null || responseItem.options.length === 0) {
                stats['missed'].count++;
            } else {
                responseItem.options.forEach(option => {
                    const selectedId = option.source || 'missed';
                    stats[selectedId].count++;
                });
            }
        });

        return Object.keys(stats).map(key => stats[key]);
    }

    public getResponseFeedback(response: InteractionResponse): ResponseFeedbackItem[] {
        const responseItem = response.items[0];
        if (responseItem == null) { return []; }

        const selectedOptions = responseItem.options.map(option => option.source);

        const correctResponses = this.inputItem.correctResponses
            .reduce((p: string[], c) => p.concat(c.values.map(value => value.source).filter(notEmpty)), []);

        const result = selectedOptions.map(optionId => {
            const option = this.inputItem.options.find(o => o.id === optionId);
            let item;
            if (this.inputMethod === 'microphone') {
                item = responseItem.asrFeedback;
            } else if (option instanceof TextInteractionOption) {
                item = option.text;
            } else if (option instanceof MediaInteractionOption) {
                item = option.media;
            }
            const statusCode = (correctResponses.find(cr => cr === optionId)) ? 'correct' : 'incorrect' as StatusCode;

            return { item, statusCode };
        });

        return result;
    }

}
