import { Store } from '@ngrx/store';
import { Dictionary } from '@novo/platform-common/models';
import { LoggingDataMiddleware } from '@novo/platform-common/services/error-logger';
import { storeSelect } from '@novo/platform-common/services/error-logger/error-logger.util';
import { merge } from 'rxjs';
import { filter, last, map, scan, startWith } from 'rxjs/operators';
import { isOnline } from '../app-status-selectors';
import { getActivePlayState, selectActivitySessionId } from '../play/play-selectors';
import { selectedSection } from '../section-selectors';
import { AppState } from '../state';

export class CommonStateLoggingMiddleware implements LoggingDataMiddleware {

    constructor(
        private store: Store<AppState>
    ) { }

    /**
     * Return information that is useful for the investigation of the cause of an error
     */
    async provideData(): Promise<Dictionary<any>> {
        const select = storeSelect(this.store);
        const stateData = await merge(
            select(isOnline).pipe(map(online => ({ online }))),
            select(selectedSection).pipe(map(v => ({ activityId: v.identifier }))),
            select(s => s.slide).pipe(map(s => ({ slideId: s.slideId }))),
            select(selectActivitySessionId).pipe(map(activitySessionId => ({ activitySessionId }))),
            select(getActivePlayState).pipe(
                filter(p => p.latestResponse != null),
                map(p => ({ latestResponse: p.latestResponse }))
            )
        ).pipe(
            startWith({}),
            scan((acc, value) => ({ ...acc, ...value }), {}),
            last()
        ).toPromise();
        return stateData;
        // const speechData = {};
        // if (this.speech?.activeSessions) {
        //     for (const sessionKey in this.speech.activeSessions) {
        //         if (this.speech.activeSessions[sessionKey]) {
        //             const session = this.speech.activeSessions[sessionKey];
        //             speechData[sessionKey] = {
        //                 asrSessionUuid: session.currentContextId,
        //                 connectionQuality: session.transport?.connectionQuality
        //             };
        //         }
        //     }
        // }

        // return {
        //     ...stateData,
        //     speechData
        // };
    }

}
