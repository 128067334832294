import * as moment from 'moment';
import { InternationalText } from './international-text';
import { AssignableUnit, AssignableUnitSection, Assignment, LearningTrack } from './learning-track';
import { AssignmentTemplate } from './learning-track-template';
import { UserLearningStatus } from './model-types';
import { User } from './user';
import { initConstructor, notEmpty } from './utilities';


export class LRSScore {

    get percentage() {
        if (this.scaled != null) {
            return 100 * (this.scaled + 1) / 2;
        } else {
            if ((this.max - this.min) === 0) { return 0; }
            return 100 * (this.raw - this.min) / (this.max - this.min);
        }
    }

    static fromPercentage(perc: number) {
        const scaled = Math.max(-1, Math.min(1, ((perc / 100) * 2) - 1));
        return new LRSScore(0, 100, perc, scaled);
    }

    constructor(
        private min: number,
        private max: number,
        private raw: number,
        private scaled?: number
    ) {

    }
}

export class UnitResult {
    completed = false;
    passed = false;
    score?: LRSScore = undefined;

    static initialize(json: { completed: boolean, passed: boolean, score?: { min: number; max: number; raw: number; } }): UnitResult {
        const result = new UnitResult();
        result.completed = json.completed;
        result.passed = json.passed;
        if (json.score) {
            result.score = new LRSScore(json.score.min, json.score.max, json.score.raw);
        }
        return result;
    }
}

export class UserActivitySession {
    userId = 'userId';
    sessionId = 'sessionId';
    activityId = 'activityId';
    courseId?: string = undefined;
    trackId?: string = undefined;
    assignmentId?: string = undefined;
    classId?: string = undefined;
    result?: UnitResult = undefined;
    start: moment.Moment = moment();
    end?: moment.Moment = moment();
    duration: moment.Duration = moment.duration();

    static initialize(json: any): UserActivitySession {
        const us = new UserActivitySession();
        us.activityId = json.activityId;
        us.sessionId = json.sessionId;
        us.courseId = json.courseId;
        us.assignmentId = json.assignmentId;
        us.trackId = json.trackId;
        us.classId = json.classId;
        us.duration = moment.duration(json.duration);
        us.start = moment(json.start);
        if (json.end) { us.end = moment(json.end); }
        if (json.result) {
            us.result = UnitResult.initialize(json.result);
        }
        return us;
    }

    get completed(): boolean {
        return this.result != null && this.result.completed;
    }
}

export interface UserActivityStatusModel { userId: string; activityId: string; activityTitle: InternationalText; sessions: UserActivitySession[]; }

export class UserActivityStatus implements UserActivityStatusModel {
    userId: string;
    activityId: string;
    activityTitle: InternationalText;
    activityType: 'section' = 'section';
    sessions: UserActivitySession[] = [];

    static initialize(json): UserActivityStatus {

        let sessions = [];
        if (json.sessions) {
            sessions = json.sessions
                .map(s => UserActivitySession.initialize(s))
                .sort((a: UserActivitySession, b: UserActivitySession) => {
                    if (a.start < b.start) { return 1; }
                    return -1;
                });
        }

        return new UserActivityStatus({
            userId: json.userId,
            activityId: json.activityId,
            activityTitle: InternationalText.initialize(json.activityTitle),
            sessions
        });
    }
    static createEmptyUserActivityStatus(userId: string, s: AssignableUnitSection): UserActivityStatus {
        return new UserActivityStatus({
            userId,
            activityId: s.identifier,
            activityTitle: s.title,
            sessions: []
        });
    }

    /**
     * Method to check if the given set of data is a UserActivityStatus
     */
    static is(data: any): data is UserActivityStatus {
        return (data.activityId && data.activityTitle);
    }

    constructor(data: UserActivityStatusModel) {
        initConstructor<UserActivityStatusModel>(this, data);
    }

    get status(): UserLearningStatus {
        if (this.sessions.length === 0) {
            return 'not-started';
        } else {
            if (this.sessions.some(session => session.result?.passed === true)) {
                return 'passed';
            }
            if (this.sessions.some(session => session.result?.passed === false)) {
                return 'failed';
            }

            const completed = this.sessions.some((session: UserActivitySession) => session.completed);
            return completed ? 'completed' : 'in-progress';
        }
    }

    get isCompleted(): boolean {
        return ['passed', 'failed', 'completed'].includes(this.status);
    }

    getTimeSpent(from?: moment.Moment, to?: moment.Moment): moment.Duration {
        return this.sessions
            .map((s: UserActivitySession) => {
                if (from && to) {
                    if (s.end && s.end >= from && s.start <= to) {
                        return s.duration;
                    }
                    return moment.duration();
                } else {
                    return s.duration;
                }
            })
            .reduce((prev: moment.Duration, cur: moment.Duration) => {
                return prev.add(cur);
            }, moment.duration());
    }

    get bestResult(): UnitResult | undefined {
        return this.sessions
            .filter((s: UserActivitySession) => s.result && s.result.score)
            .map(s => s.result)
            .reduce((prev: UnitResult, cur: UnitResult) => {
                if (cur == null || cur.score == null) { return prev; }
                if (prev == null || prev.score == null) { return cur; }
                return (cur.score.percentage > prev.score.percentage) ? cur : prev;
            }, undefined);
    }

    get completeCount(): number {
        return this.isCompleted ? 1 : 0;
    }

    get activityCount(): number {
        return 1;
    }

    get lastActive(): moment.Moment | undefined {
        return this.sessions.reduce((p: moment.Moment, c: UserActivitySession) => {
            if (!p || (c.end && p < c.end)) { return c.end; }
            return p;
        }, undefined);
    }
}

export interface UserCourseStatusModel { userId: string; courseId: string; courseTitle: InternationalText; activityStatus: UserActivityStatus[]; }

export class UserCourseStatus implements UserCourseStatusModel {

    get status(): UserLearningStatus {
        const allCompleted = this.activityStatus.every((activity: UserActivityStatus) => activity.isCompleted);
        if (allCompleted) {
            return 'completed';
        } else {
            const someStarted = this.activityStatus.some((activity: UserActivityStatus) => activity.status !== 'not-started');
            return someStarted ? 'in-progress' : 'not-started';
        }
    }

    get progress(): number {
        if (this.activityStatus.length === 0) { return 100; }
        return 100 * this.completeCount / this.activityStatus.length;
    }

    get completeCount(): number {
        return this.activityStatus.filter(as => as.isCompleted).length;
    }

    get isCompleted(): boolean {
        return ['passed', 'failed', 'completed'].includes(this.status);
    }


    get activityCount(): number {
        return this.activityStatus.length;
    }

    get lastActive(): moment.Moment | undefined {
        return this.activityStatus.reduce((p: moment.Moment, c) => {
            if (c.lastActive != null && (!p || p < c.lastActive)) { return c.lastActive; }
            return p;
        }, undefined);
    }
    userId: string;
    courseId: string;
    courseTitle: InternationalText;
    activityStatus: UserActivityStatus[] = [];

    static initialize(json): UserCourseStatus {
        let activityStatus = [];
        if (json.activityStatus) {
            activityStatus = json.activityStatus.map(s => UserActivityStatus.initialize(s));
        }
        return new UserCourseStatus({
            userId: json.userId,
            courseId: json.courseId,
            courseTitle: InternationalText.initialize(json.courseTitle),
            activityStatus
        });
    }

    static createEmptyUserCourseStatus(userId: string, unit: AssignableUnit): UserCourseStatus {
        return new UserCourseStatus({
            userId,
            courseId: unit.identifier,
            courseTitle: unit.title,
            activityStatus: unit.sections.map(s => UserActivityStatus.createEmptyUserActivityStatus(userId, s))
        });
    }

    /**
     * Method to check if the given set of data is a UserCourseStatus
     */
    static is(data: any): data is UserCourseStatus {
        return (data.courseId && data.courseTitle);
    }

    constructor(data: UserCourseStatusModel) {
        initConstructor<UserCourseStatusModel>(this, data);
    }

    ensureAllStatusPresent(course: AssignableUnit, user: User) {
        this.activityStatus = course.sections.map(act => {
            const status = this.activityStatus.find(actStatus => actStatus.activityId === act.identifier);
            if (status != null) {
                return status;
            } else {
                return new UserActivityStatus({
                    userId: user.identifier,
                    activityId: act.identifier,
                    activityTitle: act.title,
                    sessions: []
                });
            }
        });
    }

    getTimeSpent(from?: moment.Moment, to?: moment.Moment): moment.Duration {
        return this.activityStatus.reduce((prev, cur) => prev.add(cur.getTimeSpent(from, to)), moment.duration());
    }
}

export interface UserAssignmentStatusModel { userId: string; classId?: string; assignmentId: string; assignmentName: string; unitStatus: (UserCourseStatus | UserActivityStatus)[]; }

export class UserAssignmentStatus implements UserAssignmentStatusModel {
    userId = 'userId';
    classId = '';
    assignmentId = 'assignmentId';
    assignmentName = 'Assignment name';
    unitStatus: (UserCourseStatus | UserActivityStatus)[] = []; // only within context of assignment

    static initialize(json): UserAssignmentStatus {
        let unitStatus = [];
        if (json.unitStatus) {
            unitStatus = json.unitStatus.map(s => {
                return (s.courseId != null) ? UserCourseStatus.initialize(s) : UserActivityStatus.initialize(s);
            });
        }
        return new UserAssignmentStatus({
            userId: json.userId,
            classId: json.classId,
            assignmentId: json.assignmentId,
            assignmentName: json.assignmentName,
            unitStatus
        });
    }

    static createEmptyAssignmentStatus(userId: string, assignment: Assignment | AssignmentTemplate): UserAssignmentStatus {
        return new UserAssignmentStatus({
            userId,
            assignmentId: assignment.identifier,
            assignmentName: assignment.name,
            unitStatus: assignment.units.map(unit => {
                if (unit.type === 'course') {
                    return UserCourseStatus.createEmptyUserCourseStatus(userId, unit);
                }
                return UserActivityStatus.createEmptyUserActivityStatus(userId, unit);
            })
        });
    }

    constructor(data: UserAssignmentStatusModel) {
        initConstructor<UserAssignmentStatusModel>(this, data);
    }

    ensureAllStatusPresent(assignment: Assignment | AssignmentTemplate, user: User) {
        this.unitStatus = assignment.units.map(unit => {
            if (unit.type === 'course') {
                const status = this.unitStatus
                    .find(us => us instanceof UserCourseStatus && us.courseId === unit.identifier) as UserCourseStatus;
                if (status != null) {
                    status.ensureAllStatusPresent(unit, user);
                    return status;
                } else {
                    return UserCourseStatus.createEmptyUserCourseStatus(user.identifier, unit);
                }
            } else if (unit.type === 'activity') {
                const status = this.unitStatus.find(us => us instanceof UserActivityStatus && us.activityId === unit.identifier);
                if (status != null) {
                    return status;
                } else {
                    return UserActivityStatus.createEmptyUserActivityStatus(user.identifier, unit);
                }
            }
        }).filter(notEmpty);
    }

    getTimeSpent(from?: moment.Moment, to?: moment.Moment): moment.Duration {
        return this.unitStatus.reduce((prev, cur) => prev.add(cur.getTimeSpent(from, to)), moment.duration());
    }

    get status(): UserLearningStatus {
        const allCompleted = this.unitStatus.every((unit: (UserActivityStatus | UserCourseStatus)) => unit.isCompleted);
        if (allCompleted) {
            return 'completed';
        } else {
            const someStarted = this.unitStatus.some((unit: UserActivityStatus | UserCourseStatus) => unit.status !== 'not-started');
            return someStarted ? 'in-progress' : 'not-started';
        }
    }

    get progress(): number {
        if (this.unitStatus.length === 0) { return 100; }
        return 100 * this.completeCount / this.activityCount;
    }

    get completeCount(): number {
        return this.unitStatus.reduce((p, c) => p + c.completeCount, 0);
    }

    get isCompleted(): boolean {
        return ['passed', 'failed', 'completed'].includes(this.status);
    }

    get activityCount(): number {
        return this.unitStatus.reduce((p, c) => p + c.activityCount, 0);
    }

    get lastActive(): moment.Moment | undefined {
        return this.unitStatus.reduce((p: moment.Moment, c) => {
            if (c.lastActive != null && (!p || p < c.lastActive)) { return c.lastActive; }
            return p;
        }, undefined);
    }
}

export interface UserTrackStatusModel { userId: string; trackId: string; assignmentStatus: UserAssignmentStatus[]; }

export class UserTrackStatus implements UserTrackStatusModel {
    userId: string;
    trackId: string;
    assignmentStatus: UserAssignmentStatus[] = [];

    static initialize(json): UserTrackStatus {
        let assignmentStatus = [];
        if (json.assignmentStatus) {
            assignmentStatus = json.assignmentStatus.map(s => UserAssignmentStatus.initialize(s));
        }
        return new UserTrackStatus({ userId: json.userId, trackId: json.trackId, assignmentStatus });
    }

    static createEmptyTrackStatus(userId: string, track: LearningTrack): UserTrackStatus {
        return new UserTrackStatus({
            userId,
            trackId: track.identifier,
            assignmentStatus: track.assignments.map(ta => UserAssignmentStatus.createEmptyAssignmentStatus(userId, ta))
        });
    }

    constructor(data: UserTrackStatusModel) {
        initConstructor<UserTrackStatusModel>(this, data);
    }

    ensureAllStatusPresent(track: LearningTrack, user: User) {
        this.assignmentStatus = track.assignments.map(ta => {
            const status = this.assignmentStatus.find(asgStatus => asgStatus.assignmentId === ta.identifier);
            if (status != null) {
                status.ensureAllStatusPresent(ta, user);
                return status;
            }
            return UserAssignmentStatus.createEmptyAssignmentStatus(user.identifier, ta);
        });
    }

    getTimeSpent(from?: moment.Moment, to?: moment.Moment): moment.Duration {
        return this.assignmentStatus.reduce((prev, cur) => prev.add(cur.getTimeSpent(from, to)), moment.duration());
    }

    get status(): UserLearningStatus {
        const allCompleted = this.assignmentStatus.every((unit: UserAssignmentStatus) => unit.isCompleted);
        if (allCompleted) {
            return 'completed';
        } else {
            const someStarted = this.assignmentStatus.some((unit: UserAssignmentStatus) => unit.status !== 'not-started');
            return someStarted ? 'in-progress' : 'not-started';
        }
    }

    get progress(): number {
        if (this.assignmentStatus.length === 0) { return 100; }
        return 100 * this.completeCount / this.activityCount;
    }

    get completeCount(): number {
        return this.assignmentStatus.reduce((p, c) => p + c.completeCount, 0);
    }

    get activityCount(): number {
        return this.assignmentStatus.reduce((p, c) => p + c.activityCount, 0);
    }

    get lastActive(): moment.Moment | undefined {
        return this.assignmentStatus.reduce((p: moment.Moment, c) => {
            if (c.lastActive != null && (!p || p < c.lastActive)) { return c.lastActive; }
            return p;
        }, undefined);
    }
}
