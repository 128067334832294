import { Exclude } from 'class-transformer';
import { NotificationTemplates } from './notification-templates';
import { initialize, Json } from './utilities';

// this should be the same as in .../api/.../i18n.js, but here it has an extra field `icon`
export const notificationTypes = [
    {
        type: NotificationTemplates.MESSAGE_TEST,
        title: 'NOTIFICATION.MESSAGE_TEST.TITLE',
        keyBase: 'NOTIFICATION.MESSAGE_TEST',
        icon: 'adb'
    },
    {
        type: NotificationTemplates.MESSAGE_CHAT,
        title: 'NOTIFICATION.MESSAGE_CHAT.TITLE',
        keyBase: 'NOTIFICATION.MESSAGE_CHAT',
        icon: 'chat'
    },
    {
        type: NotificationTemplates.MESSAGE_CHAT_ACTION,
        title: 'NOTIFICATION.MESSAGE_CHAT_ACTION.TITLE',
        keyBase: 'NOTIFICATION.MESSAGE_CHAT_ACTION',
        icon: 'chat'
    },
    {
        type: NotificationTemplates.ASSIGNMENT_STARTED_ASSIGNMENT,
        title: 'NOTIFICATION.ASSIGNMENT_STARTED.TITLE',
        keyBase: 'NOTIFICATION.ASSIGNMENT_STARTED_ASSIGNMENT',
        icon: 'assignment'
    },
    {
        type: NotificationTemplates.ASSIGNMENT_STARTED_CLASS,
        title: 'NOTIFICATION.ASSIGNMENT_STARTED.TITLE',
        keyBase: 'NOTIFICATION.ASSIGNMENT_STARTED_CLASS',
        icon: 'assignment'
    },
    {
        type: NotificationTemplates.ASSIGNMENT_STARTED_TRACK,
        title: 'NOTIFICATION.ASSIGNMENT_STARTED.TITLE',
        keyBase: 'NOTIFICATION.ASSIGNMENT_STARTED_TRACK',
        icon: 'assignment'
    },
    {
        type: NotificationTemplates.ASSIGNMENT_STARTED_TRACK_CLASS,
        title: 'NOTIFICATION.ASSIGNMENT_STARTED.TITLE',
        keyBase: 'NOTIFICATION.ASSIGNMENT_STARTED_TRACK_CLASS',
        icon: 'assignment'
    },
    {
        type: NotificationTemplates.CERTIFICATE_EXPIRATION,
        title: 'NOTIFICATION.CERTIFICATION_EXPIRATION.TITLE',
        icon: 'school'
    }
];

export type NotificationChannelType = 'email' | 'push' | 'none' | undefined;
// The types below should be consistent with strings in api/src/cloud/email-templates.js
// TODO: Or union of email-templates and notification-templates?
export type MessageType = 'unknown' |
    'class-added' |
    'message-chat' |
    'message-chat-action' |
    'assignment-started-assignment' |
    'assignment-started-class' |
    'assignment-started-track' |
    'assignment-started-track-class' |
    'certificate-expiration';

export interface MessageVariables {
    nrAssignments?: number;
    trackId?: string;
    trackName?: string;
    classId?: string;
    className?: string;
    assignmentName?: string;
    assignmentId?: string;
    userAssignmentId?: string;
    message?: string;
    _plural?: boolean;
    actorId?: string;
    userIds?: string[];
    action?: string;
    oldTitle?: string;
    chatTitle?: string;
    _you_actor?: boolean;
    _you_object?: boolean;
    token?: string;
}

export interface SentStatus {
    sentAt?: Date;
    skippedAt?: Date;
    channel?: string;
    reason?: string;
}

export class Message {
    readonly identifier?: string = undefined;
    type: MessageType | 'unknown' = 'unknown';
    senderId?: string = undefined;
    receiverId?: string = undefined;
    readonly chatId?: string = undefined;
    summary?: string = undefined; // determined by type and locale, therefore don't init it here
    variables: MessageVariables = {}; // variable substitution map of summary
    @Exclude() isAutomatic = false;
    queuedAt: Date = new Date();
    sentStatus: SentStatus = {};
    scheduledAt?: Date = undefined;
    readAt?: Date = undefined;
    dismissedAt?: Date = undefined;
    icon?: string = undefined;

    static initialize(json: Json<Message> & { message?: string }): Message {
        const message = initialize(Message, json);
        for (const field of ['queuedAt', 'scheduledAt', 'readAt', 'dismissedAt']) {
            if (json[field]) {
                message[field] = new Date(json[field]);
            }
        }
        for (const field of ['sentAt', 'skippedAt']) {
            if (json.sentStatus && json.sentStatus[field]) {
                message.sentStatus[field] = new Date(json.sentStatus[field]);
            }
        }
        message.isAutomatic = message.senderId == null;
        const info = notificationTypes.find(n => n.type === json.type);
        message.icon = info?.icon;
        if (info && info.keyBase) {
            // tslint:disable-next-line: prefer-template
            message.summary = info.keyBase +
                (json.variables?._plural ? '_PLURAL' : '') +
                (json.variables?._you_actor ? '_YOU_ACTOR' : '') +
                (json.variables?._you_object ? '_YOU_OBJECT' : '') +
                '.BODY';
        } else if (json.variables?.message) {
            message.summary = json.variables.message;
        } else {
            message.summary = json.type; // should not happen
        }
        return message;
    }

    static sort(a: Message, b: Message) {
        return a.queuedAt > b.queuedAt ? -1 : a.queuedAt < b.queuedAt ? 1 : 0;
    }

    static equalTo(a: Message, b: Message) {
        const equal = a.queuedAt.getTime() === b.queuedAt.getTime() &&
            (a.receiverId === b.receiverId ||
                a.chatId === b.chatId) &&
            a.senderId === b.senderId;
        return equal;
    }

    isUnread(meId: string): boolean {
        return this.type === 'message-chat' && this.senderId !== meId && this.readAt == null;
    }

}

export class Chat {
    readonly identifier?: string = undefined;
    title?: string = undefined;
    participants: string[] = [];
    isGroup = false;
    @Exclude() messages: Message[] = [];

    static initialize(json: Json<Chat>): Chat {
        const c = initialize(Chat, json);
        c.messages = (json.messages || []).map(m => Message.initialize(m!));
        return c;
    }

    /**
     * sort in descending order
     * @param a one chat
     * @param b another chat
     */
    static sort(a: Chat, b: Chat) {
        const now = new Date();
        const atime = a.messages && a.messages.length > 0 && a.messages[0].queuedAt || now;
        const btime = b.messages && b.messages.length > 0 && b.messages[0].queuedAt || now;
        return atime > btime ? -1 : atime < btime ? 1 : 0;
    }
}
export interface ChatMap {
    [chatId: string]: Chat;
}
