/**
 * Constant holding fallback session ID
 */
export const UNKNOWN_SESSION_ID = '_nosession_';

/**
 * Local session used when no remote session is required. For
 * example when in 'content editing' mode.
 */
export const LOCAL_SESSION_ID = '_LOCAL_';
