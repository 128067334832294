import { cloneDeep } from 'lodash-es';
import { InteractionResponseItem } from '../interaction-response';
import { CorrectResponseOption, Interaction, InteractionInputItem, InteractionItem } from '../interaction/interaction';
import { TextInteractionOption } from '../interaction/option';
import { InlineInteractionModel } from './inline-interaction-model';


export class InlineMatchTextInteractionModel extends InlineInteractionModel {

    private incorrectMatchOptions: InteractionInputItem;
    private identicalMatchOptions: { [key: string]: string[] } = {};

    constructor(interaction: Interaction) {
        super(interaction);
        this.items = cloneDeep(interaction.items);

        [this.incorrectMatchOptions] = this.items.splice(0, 1) as InteractionInputItem[];

        const textOptions: { [key: string]: string[] } = {};
        this.items.forEach(item => {
            if (InteractionItem.isInputInteraction(item)) {
                const text = (item.options[0] as TextInteractionOption).text;
                if (textOptions[text] == null) {
                    textOptions[text] = [item.options[0].id];
                } else {
                    textOptions[text].push(item.options[0].id);
                }
                this.identicalMatchOptions[item.options[0].id] = textOptions[text];
            }
        });
    }

    isItemCorrect(item: InteractionInputItem, responseItem: InteractionResponseItem): boolean {
        // Only one option is correct for a specific gap in the text for the inline-match interaction
        const correctOptionId = item.correctResponses[0].values[0].source;
        if (correctOptionId == null) { return false; }

        // Get the option filled in the gap
        const answeredOptionId = responseItem.options[0].source;
        if (answeredOptionId == null) { return false; }

        // identicalMatchOptions contains the correct option and other options with (exactly) the same text
        const correctAnswers = this.identicalMatchOptions[correctOptionId];
        return correctAnswers.indexOf(answeredOptionId) >= 0;
    }

    getCorrectResponse(item: InteractionInputItem, responseItem: InteractionResponseItem): CorrectResponseOption | undefined {
        if (this.isItemCorrect(item, responseItem)) {
            return item.correctResponses[0]; // There is only 1 correct response for match text
        }
    }

    getTextOptions(item: InteractionInputItem): { id: string, text: string, correct: boolean }[] {
        const option = item.options[0] as TextInteractionOption;
        return [{ id: option.id, text: option.text, correct: true }, ...this.incorrectMatchOptions.options.map((imo: TextInteractionOption) => ({ id: imo.id, text: imo.text, correct: false }))];
    }

    getResponseOption(rItem: InteractionResponseItem, idx: string): string {
        return rItem.options[0].source || 'missed';
    }

    getMissingIncorrectOption(id: string): string {
        const selectedOption = this.incorrectMatchOptions.options.find(o => o.id === id) as TextInteractionOption;
        if (selectedOption != null) {
            return selectedOption.text;
        }
        // Selected a different option
        const iii = this.items.filter((item): item is InteractionInputItem => item instanceof InteractionInputItem).find((item) => item.options[0].id === id);
        const option = iii && iii.options[0];
        if (option != null && TextInteractionOption.is(option)) {
            return option.text;
        }
        return '';
    }

    getAllTextInteractionOptions(): { [key: string]: TextInteractionOption } {
        const allOptions: { [key: string]: TextInteractionOption } = {};
        this.items.forEach(item => {
            if (item instanceof InteractionInputItem) {
                item.options.forEach((option: TextInteractionOption) => allOptions[option.id] = option);
            }
        });
        this.incorrectMatchOptions.options.forEach((option: TextInteractionOption) => allOptions[option.id] = option);
        return allOptions;
    }
}
