import { xor } from 'lodash-es';
import { MediaInstance, MediaInteractionOption, notEmpty, ResponseFeedbackItem, StatusCode, TextInteractionOption } from '..';
import { InteractionResponse, InteractionResponseItem, ResponseByUser } from '../interaction-response';
import { Interaction, InteractionInputItem } from '../interaction/interaction';
import { InteractionScore, NoInteractionScore } from '../score';
import { BaseInteractionModel, ResponseStatistics } from './base-interaction-model';

export class ChoiceInteractionModel extends BaseInteractionModel {

    private interaction: Interaction;

    constructor(interaction: Interaction) {
        super();
        this.interaction = interaction;
    }

    isMultipleChoice(): boolean {
        const inputItem = this.interaction.items[0] as InteractionInputItem;
        return inputItem.maxResponses !== 1;
    }

    getResponseTemplate(interaction: Interaction): InteractionResponse {
        const rItem = new InteractionResponseItem();
        const rResponse = new InteractionResponse();
        rResponse.items.push(rItem);
        return rResponse;
    }

    isCorrect(responseTemplate: InteractionResponse): boolean {
        const responseItem = responseTemplate.items[0];
        if (responseItem == null) { return false; }

        const selectedOptions = responseItem.options
            .map(option => option.source);

        const item = this.interaction.items[0];
        let itemIsCorrect = false;

        for (const resp of (item as InteractionInputItem).correctResponses) {
            const correctResponse = resp.values.map(value => value.source);
            if (xor(correctResponse, selectedOptions).length === 0) {
                itemIsCorrect = true;
                break;
            }
        }
        if (!itemIsCorrect) {
            // One of the interaction items is incorrect
            return false;
        }

        // All of the interaction items are correct
        return true;
    }

    getScore(responseTemplate: InteractionResponse): InteractionScore | NoInteractionScore {
        const responseItem = responseTemplate.items[0];
        if (responseItem == null) { return new NoInteractionScore(); }

        const selectedOptions = responseItem.options.map(option => option.source);

        const item = this.interaction.items[0];
        let score: InteractionScore | NoInteractionScore = new NoInteractionScore();

        for (const resp of (item as InteractionInputItem).correctResponses) {
            const correctResponse = resp.values.map(value => value.source);
            if (xor(correctResponse, selectedOptions).length === 0) {
                score = resp.score || score;
                break;
            }
        }
        return score;
    }

    getMaxScore(): InteractionScore | NoInteractionScore {
        const inputItem = (this.interaction.items[0]) as InteractionInputItem;
        return inputItem.correctResponses.reduce(
            (prev: InteractionScore | NoInteractionScore, curr) => curr.score ? InteractionScore.max(prev, curr.score) : prev, new NoInteractionScore()
        );
    }

    public getResponseFeedback(response: InteractionResponse): ResponseFeedbackItem[] {
        const responseItem = response.items[0];
        if (responseItem == null) { return []; }

        const selectedOptions = responseItem.options.map(option => option.source);

        const inputItem = (this.interaction.items[0]) as InteractionInputItem;
        const correctResponses = inputItem.correctResponses.reduce((p: string[], c) => p.concat(c.values.map(value => value.source).filter(notEmpty)), []);

        const result = selectedOptions.map(optionId => {
            const option = inputItem.options.find(o => o.id === optionId);
            let item;
            if (this.interaction.inputMethod === 'microphone') {
                item = responseItem.asrFeedback;
            } else if (option instanceof TextInteractionOption) {
                item = option.text;
            } else if (option instanceof MediaInteractionOption) {
                item = option.media;
            }
            const statusCode = (correctResponses.find(cr => cr === optionId)) ? 'correct' : 'incorrect' as StatusCode;

            return { item, statusCode };
        });

        if (this.isMultipleChoice()) {
            correctResponses.forEach(optionId => {
                if (!selectedOptions.includes(optionId)) {
                    const option = inputItem.options.find(o => o.id === optionId);
                    let item;
                    if (option instanceof TextInteractionOption) {
                        item = option.text;
                    } else if (option instanceof MediaInteractionOption) {
                        item = option.media;
                    }

                    result.push({
                        item,
                        statusCode: 'deleted' as StatusCode
                    });
                }
            });
        }
        return result;
    }

    public collectStatistics(responses: ResponseByUser[]): ResponseStatistics[] {
        const inputItem = (this.interaction.items[0]) as InteractionInputItem;
        const stats: { [key: string]: ResponseStatistics } = { 'missed': { id: 'missed', count: 0, tooltip: undefined, correct: false } };
        inputItem.options.forEach(option => {
            let tooltip: string | MediaInstance | undefined;
            if (option instanceof TextInteractionOption) {
                tooltip = option.text;
            } else if (option instanceof MediaInteractionOption) {
                tooltip = option.media;
            }

            const correct = inputItem.correctResponses.find(cr => cr.values.findIndex(val => val.source === option.id) >= 0) != null;
            stats[option.id] = { id: option.id, count: 0, tooltip, correct };
        });

        responses.forEach(userResponse => {
            const responseItem = userResponse.response.items[0];
            if (responseItem == null || responseItem.options.length === 0) {
                stats['missed'].count++;
            } else {
                responseItem.options.forEach(option => {
                    const selectedId = option.source || 'missed';
                    stats[selectedId].count++;
                });
            }
        });
        return Object.keys(stats).map(key => stats[key]);
    }
}
