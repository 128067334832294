import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterState } from '@novo/platform-common/state/state';

/**
 * A custom router state serializer is needed as the app breaks when Redux DevTools is enabled in Chrome
 */
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterState> {
    serialize(routerState: RouterStateSnapshot): RouterState {
        const url = routerState.url;
        let route = routerState.root;
        let area: 'cms' | 'admin' | 'support';
        const params: Params = {};

        while (route) {
            if (route.routeConfig) {
                if (['cms', 'admin', 'support'].indexOf(route.routeConfig.path || '') >= 0) {
                    area = route.routeConfig.path as typeof area;
                }
            }
            if (route.params) {
                Object.assign(params, route.params);
            }
            route = route.firstChild;
        }

        return { url, params, area };
    }
}
