import { Injectable, Optional } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, of } from 'rxjs';
import { catchError, finalize, first, map, switchMap, take, tap } from 'rxjs/operators';
import { Group, User, UserRoleType } from '../../models';
import { Permission, Role, UserRole } from '../../models/roles';
import { ParseAPIService } from '../api/parse-api.service';
import { ErrorLoggerService } from '../error-logger';
import { AuthenticationStorage } from './auth-storage/auth.storage.service';
import { LoginType, PasswordLoginCredentials, SocialLoginCredentials, isPasswordLogin } from './auth.interfaces';
import { SessionTokenService } from './session-token/session-token.service';

@Injectable()
export class AuthService {
    /**
     * Keeps track of logged in user
     */
    constructor(
        private localStorage: LocalStorageService,
        private api: ParseAPIService,
        private sessionTokenService: SessionTokenService,
        private authStore: AuthenticationStorage,
        @Optional() private errorLogger: ErrorLoggerService
    ) {
        if (this.errorLogger) {
            this.authStore.user$.subscribe(user => {
                if (user == null) {
                    this.errorLogger.clearUserContext();
                } else {
                    this.errorLogger.setUserContext(user.identifier);
                }
            });
        }
    }

    /**
     * Gets or generates a new installationId for the user
     */
    private _currentInstallationId(): string {
        let installationId = this.localStorage.retrieve('installationId');
        if (installationId == null) {
            installationId = UUID.UUID();
            this.localStorage.store('installationId', installationId);
        }
        return installationId;
    }

    /**
     * Updates the local user state by retrieving it's information from
     * local storage OR the remote API.
     *
     * @returns {Observable<User>}
     */
    updateUserState(): Observable<User | null> {
        const sessionToken = this.sessionTokenService.get();

        if (sessionToken == null) {
            // Not logged in
            this.authStore.clear();
            return of(null);
        }

        return this.api.getMyInfo().pipe(
            map(resUser => {
                const user = User.initialize(resUser.user);
                user.permissions = resUser.permissions;
                this.authStore.userChange(user);
                this.authStore.groupChange(resUser.groups.map(g => Group.initialize(g)));
                return user;
            }),
            catchError(_ => of(null))
        );
    }



    /**
     * Login the user and save it's data to session storage
     *
     **/
    login(type: LoginType, credentials: PasswordLoginCredentials | SocialLoginCredentials, permissionRequired = false): Observable<User | null | undefined> {

        // Check if already logged in
        const currentUser = this.authStore.user;
        if (currentUser && isPasswordLogin(type, credentials) && credentials.username && (currentUser.username === credentials.username || currentUser.email === credentials.username)) {
            return of(this.authStore.user);
        }

        const installationId = this._currentInstallationId();

        return this.api.login(type, credentials, installationId).pipe(
            map(res => {
                const user = User.initialize(res.user);
                user.permissions = res.permissions;
                if (
                    permissionRequired &&
                    Object.keys(res.permissions.course).length === 0 &&
                    Object.keys(res.permissions.group).length === 0
                ) {
                    throw { code: 418, message: 'No access to Novo Studio' };
                }

                const groups = res.groups.map(g => Group.initialize(g));

                this.authStore.userChange(user);
                this.authStore.groupChange(groups);
            }),
            switchMap(_ => {
                return this.authStore.user$.pipe(
                    first((u): u is User => u != null),
                );
            })
        );
    }

    linkWithIdProvider(credentials: SocialLoginCredentials, toLink: boolean): Observable<User | null> {
        return this.api.linkWithIdProvider(credentials, toLink).pipe(
            map(res => User.initialize(res))
        );
    }

    /**
     * Logout the current user
     */
    logout(): Observable<null> {
        let observable: Observable<unknown>;
        if (this.authStore.user == null) {
            observable = of(null);
        } else {
            observable = this.api.logout();
        }
        return observable.pipe(
            finalize(() => {
                this.authStore.clear();
            }),
            map(_ => null)
        );
    }

    resetPassword(username: string): Observable<void> {
        return this.api.resetPassword(username).pipe(tap(() => { if (this.authStore.user) { this.logout(); } }));
    }

    changePassword(password: string): Observable<void> {
        if (this.authStore.user) {
            return this.api.changePassword(this.authStore.user.username, password);
        } else {
            throw new Error('Not logged in');
        }
    }

    setPassword(username: string, password: string, token: string): Observable<void> {
        return this.api.setPassword(username, password, token);
    }


    getUserRoles(user: User, type: UserRoleType): Observable<UserRole[]> {
        return this.api.getUserRoles(user.identifier, type)
            .pipe(
                map(roles => roles.map(ur => UserRole.initialize(ur)))
            );
    }

    getRoles(): Observable<Role[]> {
        return this.api.getRoles().pipe(map(roles => roles.map(r => Role.initialize(r))));
    }

    grantPermission(userId: string, roleId: string, targetType: UserRoleType, targetId: string): Observable<UserRole> {
        return this.api.grantPermission({
            role: roleId,
            targetId,
            type: targetType,
            userId,
        }).pipe(
            map(ur => UserRole.initialize(ur))
        );
    }

    revokePermission(userRoleId: string): Observable<void> {
        return this.api.revokePermission(userRoleId);
    }

    /**
     * Returns whether the requested permissions are available in the requested target type
     *
     * @param type          The permission type (group, course, activity, class, system)
     * @param targetId      The identifier of the type
     * @param permissions
     * @param user
     */
    hasPermission(type: UserRoleType, targetId: string, permission: Permission, user?: User | null): boolean {
        if (user == null) {
            this.authStore.user$.pipe(take(1)).subscribe(u => user = u);
        }

        const userPermissions = user && user.permissions && user.permissions[type];
        if (!userPermissions) { return false; }

        let granted = false;
        if (userPermissions[targetId]) {
            granted = userPermissions[targetId].indexOf(permission) >= 0;
        }
        if (type === 'system' && !granted) {
            // Any targetId will do
            granted = Object.keys(userPermissions).some(key => userPermissions[key].indexOf(permission) >= 0);
        }
        if (type === 'group' && !granted) {
            // Check if the user has the permission for one of the groups from targetId to the root
            granted = this.authStore.getAncestorsOf(targetId).some(ancestor =>
                userPermissions[ancestor.identifier] != null &&
                userPermissions[ancestor.identifier].indexOf(permission) >= 0);
        }
        return granted;
    }
}
