import { ImageMatchTarget } from '..';
import { InteractionResponse, InteractionResponseItem, InteractionResponseOption, ResponseByUser } from '../interaction-response';
import { Interaction, InteractionInputItem } from '../interaction/interaction';
import { TextInteractionOption } from '../interaction/option';
import { InteractionScore, NoInteractionScore } from '../score';
import { notEmpty } from '../utilities';
import { BaseInteractionModel, ResponseFeedbackItem, ResponseStatistics } from './base-interaction-model';

export class HotSpotMatchTextInteractionModel extends BaseInteractionModel {
    private interactionItem: InteractionInputItem;

    constructor(interaction: Interaction) {
        super();
        this.interactionItem = interaction.items[0] as InteractionInputItem;
    }


    isCorrect(responseTemplate: InteractionResponse): boolean {
        // Check selected hot-spots for all items
        const response = this.interactionItem.correctResponses;
        const responseItem = responseTemplate.items[0];
        return responseItem != null && responseItem.options.every(a => {
            return response.findIndex(cr => cr.values[0].source === a.source && cr.values[0].target === a.target) >= 0;
        });
    }

    getScore(responseTemplate: InteractionResponse): InteractionScore | NoInteractionScore {
        const response = this.interactionItem.correctResponses;
        let score: InteractionScore | NoInteractionScore = new NoInteractionScore();
        const responseItem = responseTemplate.items[0];
        if (responseItem == null) { return new NoInteractionScore(); }

        for (const option of responseItem.options) {
            const correctResponse = response.find(cr => cr.values[0].source === option.source && cr.values[0].target === option.target);
            if (!correctResponse) {
                score = new NoInteractionScore();
                break;
            }
            score = score.add(correctResponse.score);
        }
        return score;
    }

    getMaxScore(): InteractionScore | NoInteractionScore {
        return this.interactionItem.correctResponses.reduce(
            (prev: InteractionScore | NoInteractionScore, curr) => prev.add(curr.score), new NoInteractionScore()
        );
    }

    getResponseTemplate(interaction: Interaction): InteractionResponse {
        const inputItem = interaction.items[0] as InteractionInputItem;

        const iri = new InteractionResponseItem();
        iri.options = inputItem.options.map(o => {
            const iro = new InteractionResponseOption();
            iro.source = o.id;
            return iro;
        });

        const ir = new InteractionResponse();
        ir.items = [iri];
        return ir;
    }

    public collectStatistics(responses: ResponseByUser[]): ResponseStatistics[][] {
        const stats: { [targetId: string]: { [key: string]: ResponseStatistics } } = {};
        const targets = this.interactionItem.correctResponses.map(cr => cr.values[0].target).filter(notEmpty);

        targets.forEach(target => {
            stats[target] = {};
            stats[target]['missed'] = { id: 'missed', count: 0, tooltip: undefined, correct: false };
            this.interactionItem.options.forEach((option: TextInteractionOption) => {
                stats[target][option.id] = { id: option.id, count: 0, tooltip: option.text, correct: false };
            });

            const match = this.interactionItem.correctResponses.find(cr => cr.values[0].target === target);
            if (match && match.values[0].source) {
                stats[target][match.values[0].source].correct = true;
            }
        });
        responses.forEach(userResponse => {
            const response = userResponse.response;
            const userMatched: { [targetId: string]: string } = {};
            targets.forEach(target => {
                const responseItem = response.items[0];
                const targetOption = responseItem && responseItem.options.find(o => o.target === target);
                userMatched[target] = (targetOption && targetOption.source) ? targetOption.source : 'missed';
            });

            Object.keys(userMatched).forEach(key => stats[key][userMatched[key]].count++);
        });
        return Object.keys(stats).map(s => Object.keys(stats[s]).map(key => stats[s][key]));
    }

    public getResponseFeedback(response: InteractionResponse): ResponseFeedbackItem[] {
        const result: ResponseFeedbackItem[] = [];

        const optionTexts: { [optionId: string]: string } = {};
        this.interactionItem.options.forEach((option: TextInteractionOption) => optionTexts[option.id] = option.text);
        const responseItem = response.items[0];
        if (responseItem == null) { return []; }

        const options = responseItem.options;
        const matchTarget = this.interactionItem.matchTarget as ImageMatchTarget;

        this.interactionItem.correctResponses.forEach(cr => {
            const correctMatch = cr.values[0];
            const chosenMatch = options.find(option => correctMatch.target === option.target);
            if (chosenMatch == null || chosenMatch.source == null) { return; }

            if (correctMatch.source === chosenMatch.source) {
                result.push({
                    item: {
                        media: matchTarget.background,
                        hotspot: matchTarget.hotspots.find(h => h.id === chosenMatch.target),
                        feedback: [{ item: optionTexts[chosenMatch.source], statusCode: 'correct' }]
                    },
                    statusCode: 'correct'
                });
            } else {
                const feedback: ResponseFeedbackItem[] = [{ item: optionTexts[chosenMatch.source], statusCode: 'incorrect' }];
                if (correctMatch.source) {
                    feedback.push({ item: optionTexts[correctMatch.source], statusCode: 'deleted' });
                }

                result.push({
                    item: {
                        media: matchTarget.background,
                        hotspot: matchTarget.hotspots.find(h => h.id === chosenMatch.target),
                        feedback
                    },
                    statusCode: 'correct'
                });
            }
        });
        return result;
    }
}
