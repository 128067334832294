import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { Country } from './model/country.model';
import { CountryCode } from './resource/country-code';

@Component({
    selector: 'tel-input',
    templateUrl: './tel-input.component.html',
    styleUrls: [
        './tel-input.component.css',
        './resource/intl-tel-input.css'
    ],
    providers: [
        CountryCode,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TelInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useValue: (c: FormControl) => {
                if (c.value == null) { return null; } // Empty is valid...

                const phoneUtil = PhoneNumberUtil.getInstance();
                try {
                    const number: PhoneNumber = phoneUtil.parse(c.value);
                    if (phoneUtil.isValidNumber(number) && phoneUtil.isPossibleNumber(number)) {
                        return null;
                    } else {
                        return { error: 'Not a valid phone number' };
                    }
                } catch (err) {
                    return { error: 'Not a valid phone number' };
                }
            },
            multi: true
        }
    ]
})
export class TelInputComponent implements ControlValueAccessor {
    @Input() value;
    @Input() preferredCountries: Array<string> = ['id', 'nl', 'vn'];
    @Input() useTranslation = true;
    formatted: string;

    phoneNumber = '';
    allCountries: Array<Country> = [];
    preferredCountriesInDropDown: Array<Country> = [];
    selectedCountry: Country = new Country();
    allCountriesLabel: string;
    private phoneUtil = PhoneNumberUtil.getInstance();

    constructor(private countryCodeData: CountryCode, private translate: TranslateService) {
        this.fetchCountryData();
        if (this.preferredCountries.length) {
            this.preferredCountries.forEach(iso2 => {
                const preferredCountry = this.allCountries.find(c => c.iso2 === iso2);
                if (preferredCountry) {
                    this.preferredCountriesInDropDown.push(preferredCountry);
                }
            });
        }
        this.allCountriesLabel = this.translate.instant('ALL_COUNTRIES');
    }

    writeValue(value: string) {
        try {
            const number: PhoneNumber = this.phoneUtil.parse(value);
            const countryCode = number.getCountryCode();
            if (countryCode) {
                const regionCode: string = this.phoneUtil.getRegionCodeForCountryCode(countryCode);
                const nationalNumber = number.getNationalNumber();
                if (regionCode && nationalNumber) {
                    this.phoneNumber = nationalNumber.toString();
                    const country = this.allCountries.find(c => regionCode.toUpperCase() === c.iso2.toUpperCase());
                    if (country) { this.selectedCountry = country; }
                    this.onChange();
                }
            }
        } catch (err) {
            // Error parsing number
        }
    }

    propagateChange = (_: any) => { };

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() { }

    public onPhoneNumberChange(): void {
        this.onChange();
    }

    public onCountrySelect(country: Country): void {
        this.selectedCountry = country;
        if (this.phoneNumber.length > 0) {
            this.onChange();
        }
    }

    public onChange() {
        try {
            if (this.phoneNumber.length === 0) {
                this.propagateChange(null);
            } else {
                const number: PhoneNumber = this.phoneUtil.parse(this.phoneNumber, this.selectedCountry.iso2.toUpperCase());
                this.formatted = this.phoneUtil.format(number, PhoneNumberFormat.E164);
                this.propagateChange(this.formatted);
            }
        } catch (err) {
            // Not a valid number to parse
        }
    }

    public getLabel(key: ('COUNTRY' | 'ALL_COUNTRIES' | 'PHONE_NUMBER')): string {
        if (this.useTranslation) {
            return this.translate.instant(key);
        } else {
            switch (key) {
                case 'COUNTRY':
                    return 'Country';
                case 'ALL_COUNTRIES':
                    return 'All countries';
                case 'PHONE_NUMBER':
                    return 'Phone number';
            }
            return '';
        }
    }

    public onInputKeyPress(event): void {
        const pattern = /[0-9\+\-\ ]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    protected fetchCountryData(): void {
        this.countryCodeData.allCountries.forEach(c => {
            const country = new Country();
            country.name = c[0].toString();
            country.iso2 = c[1].toString();
            country.dialCode = c[2].toString();
            country.priority = +c[3] || 0;
            country.areaCode = +c[4] || null;
            country.flagClass = country.iso2.toLocaleLowerCase();
            this.allCountries.push(country);
        });
    }
}
