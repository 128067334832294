import { SessionInfo } from '../../services/learning-record-store/learning-record-store.interfaces';
import { AssignableUnit, AssignableUnitSection } from '../learning-track';
import { Prerequisite } from '../prerequisite/prerequisite';

type Unit = AssignableUnit | AssignableUnitSection;

/**
 * The UnitPrerequisiteChecker is a helper class to determine prerequisite
 * fulfilment between units within the same assignment.
 *
 * The class is expected to be initialized with a list of AssignableUnits or
 * AssignableUnitSections and a list of all (relevant) sessions.
 *
 * Use the @see {AssignmentPrerequisiteChecker} to check prerequisites
 * between assigments.
 */
export class UnitPrerequisiteChecker {

    constructor(readonly units: Unit[], readonly sessions: SessionInfo[]) { }

    /**
     * Checks if the requested activity is 'Active'. To determine if an activity is active
     * the following criteria must be met:
     *
     * - When a Unit has a 'settings' object with the 'active' setting it should be set to true
     * - All prerequisites should be satisfied.
     *
     * @param unitOrIdentifier
     */
    isActivityActive(unitOrIdentifier: string | Unit): boolean {
        const unit = typeof unitOrIdentifier === 'string' ? this.getUnit(unitOrIdentifier) : unitOrIdentifier;

        // Inactive if the assignable unit settings are not active
        if ('settings' in unit && !unit.settings.active) { return false; }

        // Active if there are no prerequisites
        if (unit.prerequisites == null || unit.prerequisites == null || unit.prerequisites.length === 0) { return true; }

        // Active if all prerequistes are satisfied
        return unit.prerequisites.every(pq => this.isActivityCompleted(pq.targetId));
    }

    /**
     * Checks if the provided Activity OR Course is satisfied. This is a shortcut
     * for either @see {isActivityCompleted} or @see {isCourseCompleted}.
     *
     * @param identifier
     * @param checkScoring        enable to also check if scored units are passed. False by default.
     */
    isSatisfied(unitOrIdentifier: string | Unit, checkScoring = false): boolean {
        const unit = typeof unitOrIdentifier === 'string' ? this.getUnit(unitOrIdentifier) : unitOrIdentifier;
        return (unit instanceof AssignableUnit && unit.type === 'course') ? this.isCourseCompleted(unit, checkScoring) : this.isActivityCompleted(unit, checkScoring);
    }

    /**
     * Checks if the given unit has at least one completed session.
     *
     * Note that we're currently also marking prerequisites as satisfied
     * when the session's scoring did not pass.
     *
     * Note that this does not check if activeness of an activity. In order to
     * check if an activity can be played (is active) one should use @see {isActiveAndPrerequisitesSatisfied}
     *
     * @param unitOrIdentifier  unit to check, or identifier of the unit to check
     * @param checkPassed    set to true (disabled by default) to also check if the activity was passed (when scoring is enabled)
     */
    isActivityCompleted(unitOrIdentifier: string | Unit, checkScoring = false): boolean {
        const identifier = typeof unitOrIdentifier === 'string' ? unitOrIdentifier : unitOrIdentifier.identifier;
        const sessions = this.getUnitSessions(identifier);
        return sessions.some(s => {
            if (!s.completed) { // not completed, ignore
                return false;
            } else if (s.score == null || checkScoring == false) { // completed and no scoring: completed
                return true;
            } else { // completed and scoring, check if passed
                return s.score.passed === true;
            }
        });
    }

    /**
     * Checks if an entire Course is passed. See @see {isActivityCompleted} for more
     * information about what we consider as 'passed'.
     *
     * @param unitOrIdentifier  unit to check, or identifier of the unit to check
     * @param checkPassed    set to true (disabled by default) to also check if the activity was passed (when scoring is enabled)
     */
    isCourseCompleted(unitOrIdentifier: string | Unit, checkPassed = false): boolean {
        const unit = typeof unitOrIdentifier === 'string' ? this.getUnit(unitOrIdentifier) : unitOrIdentifier;
        if (!(unit instanceof AssignableUnit) || unit.type !== 'course') { throw new Error(`[UnitPrerequisiteChecker#isCourseCompleted] requires a course, but activity was given.`); }
        return unit.sections.every(s => this.isActivityCompleted(s.identifier, checkPassed));
    }

    /**
     * Check if a unit is active and its prerequisites are satisfied
     *
     * @param unitOrIdentifier
     * @param checkPassed    set to true (disabled by default) to also check if the activity was passed (when scoring is enabled)
     */
    isActiveAndPrerequisitesSatisfied(unitOrIdentifier: string | Unit, checkPassed = false): boolean {
        const unit = typeof unitOrIdentifier === 'string' ? this.getUnit(unitOrIdentifier) : unitOrIdentifier;
        if ('settings' in unit && !unit.settings.active) { return false; }
        if (unit.prerequisites == null || unit.prerequisites.length === 0) { return true; }
        return unit.prerequisites.every(pq => this.isSatisfied(pq.targetId, checkPassed));
    }

    /**
     * Returns the name of units which have unsatisfying prerequisites for the
     * requested unit
     */
    getUnsatisfiedUnits(unitOrIdentifier: string | Unit): string[] {
        const unit = typeof unitOrIdentifier === 'string' ? this.getUnit(unitOrIdentifier) : unitOrIdentifier;
        if (unit.prerequisites == null) { return []; }

        return unit.prerequisites
            .filter(pq => !this.isUnitPrerequisiteSatisfied(pq))
            .map(pq => {
                const unit = this.units.find(u => u.identifier == pq.targetId);
                if (unit == null) { return ''; }
                return unit.title.defaultText;
            });
    }

    /**
     * Check if the given prerequisite (unit) is completed and passed
     *
     * @param prerequisite
     */
    private isUnitPrerequisiteSatisfied(prerequisite: Prerequisite): boolean {
        const unit = this.units.find(u => u.identifier === prerequisite.targetId);
        if (!unit) {
            return true;
        }
        if (unit instanceof AssignableUnit && unit.type == 'course') {
            return this.isCourseCompleted(unit);
        }
        return this.isActiveAndPrerequisitesSatisfied(unit) && this.isActivityCompleted(unit);
    }

    /**
     * Returns the requested assignment from the list of provided assignments
     *
     * @throws {Error} when requested assignment was not found
     * @param identifier
     */
    private getUnit(identifier: string): Unit {
        const unit = this.units.find(u => u.identifier === identifier);
        if (!unit) {
            throw new Error(`[UnitPrerequisiteChecker] Unit with ID ${identifier} does not exist in provided list of 'units'`);
        }
        return unit;
    }

    /**
     * Returns relevant sessions for the given unit
     *
     * @param identifier
     */
    private getUnitSessions(identifier: string): SessionInfo[] {
        return this.sessions.filter(s => s.activityId === identifier);
    }

}
