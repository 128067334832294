import { Type } from 'class-transformer';
import { languageMap } from '../data';
import { Background } from './background';
import { Content, ContentModel } from './content';
import { Group } from './group';
import { InternationalText, Language } from './international-text';
import { LanguageCode } from './model-types';
import { Section } from './section';
import { initConstructor, LocaleCompare, serializeType } from './utilities';


export interface CourseModel extends ContentModel {
    language: LanguageCode;
    translations: LanguageCode[];

    level: 'novice' | 'intermediate' | 'expert';

    sections: Section[];
    title: InternationalText;
    description: InternationalText;
    coverImage: Background;
}

export class Course extends Content implements CourseModel {

    get languages(): Language[] {
        return [this.language, ...(this.translations || [])].map(l => languageMap[l]).sort((a, b) => a.name < b.name ? -1 : 1);
    }

    language: LanguageCode;
    readonly translations: LanguageCode[];

    level: 'novice' | 'intermediate' | 'expert';
    @Type(serializeType(Group)) public owner: Group;
    @Type(serializeType(Section)) sections: Section[];
    @Type(serializeType(InternationalText)) title: InternationalText;
    @Type(serializeType(InternationalText)) description: InternationalText;
    @Type(serializeType(Background)) coverImage: Background;

    static initialize(json: any): Course {
        const content = super.initializeContent(json);
        const language = json.language || 'en';

        let sections: Section[];
        if (json.sections) {
            sections = json.sections.map(s => Section.initialize(s));
        } else {
            sections = [];
        }
        let title: InternationalText;
        if (json.title) {
            title = InternationalText.initialize(json.title);
        } else {
            title = new InternationalText();
            title.default = language;
        }

        let description: InternationalText;
        if (json.description) {
            description = InternationalText.initialize(json.description);
        } else {
            description = new InternationalText();
            description.default = language;
        }

        let coverImage;
        if (json.coverImage) {
            coverImage = Background.initialize(json.coverImage);
        } else {
            coverImage = new Background();
        }

        return new Course({
            ...content,
            coverImage,
            description,
            level: json.level || 'novice',
            language,
            sections,
            title,
            translations: json.translations || []
        });
    }

    static sort(courseA: Course, courseB: Course): number {
        const a = courseA.title.defaultText; // new ContentTranslatePipe().transform(courseA.title);
        const b = courseB.title.defaultText; // new ContentTranslatePipe().transform(courseB.title);
        return LocaleCompare.compare(a, b);
    }

    constructor(data: CourseModel) {
        super(data);
        initConstructor<CourseModel>(this, data);
    }

    setSectionTitle(sectionId: string, title: InternationalText): void {
        const section = this.sections.find(s => s.identifier === sectionId);
        if (section) {
            section.title = title;
        }
    }

    setASREnabled(sectionId: string, enabled: boolean): void {
        const section = this.sections.find(s => s.identifier === sectionId);
        if (section) {
            section.asrEnabled = enabled;
        }
    }
}
