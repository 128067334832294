import { UUID } from 'angular2-uuid';
import { InteractionResponseItem } from '../interaction-response';
import { CorrectResponseOption, Interaction, InteractionInputItem } from '../interaction/interaction';
import { TextInteractionOption } from '../interaction/option';
import { isEqual } from '../utilities';
import { InlineInteractionModel } from './inline-interaction-model';


export class InlineTextEntryInteractionModel extends InlineInteractionModel {

    private correctTextOptions: { [key: string]: { id: string, text: string, correct: boolean }[] } = {};

    constructor(interaction: Interaction) {
        super(interaction);
        this.items.filter((r): r is InteractionInputItem => r instanceof InteractionInputItem).map(iItem => {
            this.correctTextOptions[iItem.identifier] = iItem.options.map((option: TextInteractionOption) => {
                const id = UUID.UUID().substring(0, 8);
                return { id, text: option.text, correct: true };
            });
        });
    }

    isItemCorrect(item: InteractionInputItem, responseItem: InteractionResponseItem): boolean {
        const correctAnswers = item.options.map(opt => (opt as TextInteractionOption).text);
        return correctAnswers.some((correct: string) => {
            return responseItem.options[0].text != null && isEqual(correct, responseItem.options[0].text, InlineInteractionModel.equalOptions);
        });
    }

    getCorrectResponse(item: InteractionInputItem, responseItem: InteractionResponseItem): CorrectResponseOption | undefined {
        const chosenOption = item.options.find((o: TextInteractionOption) => {
            return responseItem.options[0].text != null && isEqual(responseItem.options[0].text, o.text, InlineInteractionModel.equalOptions);
        });
        if (chosenOption) {
            return item.correctResponses.find(cr => cr.values[0].source === chosenOption.id);
        }
    }

    getTextOptions(item: InteractionInputItem): { id: string, text: string, correct: boolean }[] {
        return this.correctTextOptions[item.identifier];
    }

    getResponseOption(rItem: InteractionResponseItem, idx: string): string {
        const option = this.correctTextOptions[idx]
            .find(key => rItem.options[0].text != null &&
                isEqual(key.text, rItem.options[0].text, InlineInteractionModel.equalOptions));
        return (option && option.id) || rItem.options[0].text || 'missed';
    }

    getMissingIncorrectOption(id: string): string {
        return id;
    }

    getAllTextInteractionOptions(): { [key: string]: TextInteractionOption } {
        const allOptions: { [key: string]: TextInteractionOption } = {};
        this.items.forEach(item => {
            if (item instanceof InteractionInputItem) {

                item.options.forEach((option: TextInteractionOption) => {
                    const cto = this.correctTextOptions[item.identifier].find(o => o.text === option.text);
                    if (cto && cto.id != null) { allOptions[cto.id] = option; }
                });
            }
        });
        return allOptions;
    }
}
