import { Type } from 'class-transformer';
import { uniqBy } from 'lodash-es';
import { languageMap } from '../data';
import { Background } from './background';
import { Content, ContentModel } from './content';
import { Icon } from './icon';
import { InternationalText, Language } from './international-text';
import { MediaFile } from './media';
import { LanguageCode } from './model-types';
import { NavigationLink } from './navigation';
import { Prerequisite } from './prerequisite';
import { OverallScore, ScoringSettings } from './score';
import { Slide } from './slide';
import { NavigationBlock, ScoreBlock, SlideBlock } from './slide-block';
import { Theme } from './theme';
import { initConstructor, LocaleCompare, notEmpty, serializeType } from './utilities';


export type SectionCategory =
    'intro' |
    'outro' |
    'online-activity' |
    'offline-activity' |
    'supportive-information';

export interface SectionModel extends ContentModel {
    title: InternationalText;
    description: InternationalText;
    slides: { [key: string]: Slide };
    version: number;
    firstSlide?: string;
    language: LanguageCode;
    translations: LanguageCode[];
    category: SectionCategory;
    asrEnabled: boolean;
    scoringSettings?: ScoringSettings;
    updatedAt?: Date;
    theme?: Theme;
    prerequisites: Prerequisite[];
    icon?: Icon;
    lastSpokenResponseCount?: { count: number, activityIds: string[] };
}

/**
 * A section is a part of a course. It is also called activity.
 */
export class Section extends Content implements SectionModel {

    get supportsASR(): boolean {
        if (this.language) {
            // languageMap[this.language] can be null for a newly introduces language in an older studio
            return languageMap[this.language] != null && languageMap[this.language].asrEnabled;
        }
        return false;
    }

    get usesASR(): boolean {
        if (!this.asrEnabled) {
            return false;
        }

        if (!this.slides) {
            throw new Error('Cannot determine whether there are any ASR interactions');
        }

        const slideIds: string[] = Object.keys(this.slides);
        for (const slideId of slideIds) {
            const slide: Slide = this.slides[slideId];
            if (slide && slide.interaction && slide.interaction.inputMethod === 'microphone') {
                return true;
            }
        }

        return false;
    }

    get languages(): Language[] {
        return [this.language, ...this.translations].map(l => languageMap[l])
            .sort((a: Language, b: Language) => a.name < b.name ? -1 : 1);
    }

    @Type(serializeType(InternationalText)) title: InternationalText;
    @Type(serializeType(InternationalText)) description: InternationalText;
    @Type(serializeType(Slide)) slides: { [key: string]: Slide };
    version = 1;
    firstSlide?: string;
    language: LanguageCode;
    translations: LanguageCode[];
    category: SectionCategory;
    asrEnabled: boolean;

    // For stand-alone sections owner must be defined.
    @Type(serializeType(ScoringSettings)) scoringSettings?: ScoringSettings;
    @Type(serializeType(Date)) updatedAt: Date;

    theme?: Theme;
    prerequisites: Prerequisite[];
    icon?: Icon;
    lastSpokenResponseCount?: { count: number, activityIds: string[] };

    static initialize(json: any): Section {

        const content = super.initializeContent(json);
        const language = json.language || 'en';

        const slides: { [key: string]: Slide } = {};
        if (Array.isArray(json.slides)) {
            for (const slide of json.slides) {
                const s = Slide.initialize(slide);
                if (s.identifier != null) { slides[s.identifier] = s; }
            }
        }

        let scoringSettings: ScoringSettings | undefined;
        if (json.scoringSettings) {
            scoringSettings = ScoringSettings.initialize(json.scoringSettings);
        }
        let title: InternationalText;
        if (json.title) {
            title = InternationalText.initialize(json.title);
        } else {
            title = new InternationalText();
            title.default = language;
        }

        const icon = Icon.initialize(json.icon);

        let description: InternationalText;
        if (json.description) {
            description = InternationalText.initialize(json.description);
        } else {
            description = new InternationalText();
            description.default = language;
        }

        let updatedAt: Date | undefined;
        if (json.updatedAt != null) {
            updatedAt = new Date(json.updatedAt);
        }

        const section = new Section({
            ...content,
            asrEnabled: json.asrEnabled || false,
            category: json.category || 'online-activity',
            description,
            firstSlide: json.firstSlide,
            icon,
            language,
            prerequisites: json.prerequisites || [],
            scoringSettings,
            slides,
            theme: json.theme,
            title,
            translations: json.translations || [],
            updatedAt,
            version: json.version || 1,
            lastSpokenResponseCount: json.lastSpokenResponseCount
        });

        // Use language codes directly for removal of excessive languages as Language may not
        // be defined in older players (for new languages);
        section.removeTranslationsBesides(section, [section.language, ...section.translations]);
        return section;
    }

    static sort(activityA: Section, activityB: Section) {
        const a = activityA.title.defaultText; // new ContentTranslatePipe().transform(activityA.title);
        const b = activityB.title.defaultText; // new ContentTranslatePipe().transform(activityB.title);
        return LocaleCompare.compare(a, b);
    }

    constructor(data: SectionModel) {
        super(data);
        initConstructor<SectionModel>(this, data);
    }

    ensureDefaultLanguage(obj: any) {
        if (obj instanceof InternationalText) {
            if (obj.translation[this.language] == null) {
                // Copy default text if no text has been given for the primary language
                obj.translation[this.language] = obj.translation[obj.default];
            }
            obj.default = this.language;
        } else if (obj instanceof Array || obj instanceof Map) {
            obj.forEach(val => this.ensureDefaultLanguage(val));
        } else if (obj instanceof Object) {
            for (const prop in obj) {
                if (obj.hasOwnProperty(prop) && obj[prop] != null) {
                    this.ensureDefaultLanguage(obj[prop]);
                }
            }
        }
    }

    /**
     * Remove all translations in InternationalText objects within obj besides languages
     *
     * @param obj
     * @param languages
     */
    removeTranslationsBesides(obj: any, languages: string[]) {
        if (obj instanceof InternationalText) {
            obj.removeTranslationsBesides(languages);
        } else if (obj instanceof Array) {
            for (let i = 0; i < obj.length; i++) {
                this.removeTranslationsBesides(obj[i], languages);
            }
        } else if (obj instanceof Map) {
            obj.forEach((value, _) => {
                this.removeTranslationsBesides(value, languages);
            });
        } else if (obj instanceof Object) {
            for (const prop in obj) {
                if (obj.hasOwnProperty(prop) && obj[prop] != null) {
                    this.removeTranslationsBesides(obj[prop], languages);
                }
            }
        }
    }

    getMediaFiles(obj: MediaFile | Array<any> | Object): MediaFile[] {
        let files: MediaFile[] = [];

        if (obj instanceof MediaFile) {
            files = [...files, obj];
        } else if (obj instanceof Array) {
            for (let i = 0; i < obj.length; i++) {
                files = [...files, ...this.getMediaFiles(obj[i])];
            }
        } else if (obj instanceof Object) {
            for (const prop in obj) {
                if (obj.hasOwnProperty(prop) && obj[prop] != null) {
                    files = [...files, ...this.getMediaFiles(obj[prop])];
                }
            }
        }
        return files;
    }

    getMediaFilesToPreload(): MediaFile[] {
        let files: MediaFile[] = this.getMediaFiles(this);
        // files = files.filter((file: MediaFile) => file.provider === 'internal');
        files = uniqBy(files, 'src');
        return files;
    }

    getParentSlideIds(slideId: string): string[] {
        return this.getParentSlides(slideId).map(s => s.identifier).filter(notEmpty);
    }

    createFinalSlide(nextActivityId?: string, customNavLinks?: NavigationLink[], score?: OverallScore | null): Slide {
        const background = new Background();
        background.type = 'color';
        background.color = '#ffffff';
        background.size = 'full';

        const blocks: SlideBlock[] = [];
        const block = new ScoreBlock();
        block.score = score || new OverallScore();
        if (this.scoringSettings && this.scoringSettings.enabled) {
            block.dimensions = this.scoringSettings.dimensions;
        }
        block.style = {
            card: false,
            backgroundColor: 'white',
            color: 'rgba(0,0,0,0.87)',
            margin: { top: 0, right: 0, bottom: 0, left: 0 },
            pointerEvents: 'all'
        };
        blocks.push(block);

        // Add navigation buttons outside activity (e.g. continue, retry, quit)
        const navBlock: NavigationBlock = new NavigationBlock();
        navBlock.style = {
            card: false,
            backgroundColor: '#ffffff',
            color: 'rgba(0,0,0,0.87)',
            margin: { top: 0, right: 0, bottom: 0, left: 0 },
            pointerEvents: 'all'
        };

        navBlock.links = [];

        // Show custom nav links if they are defined,
        // otherwise show a continue button (to next activity)
        // if a next activity is available
        if (customNavLinks && customNavLinks.length > 0) {
            navBlock.links = customNavLinks;
        } else if (nextActivityId) {
            navBlock.links.push(NavigationLink.initialize({
                action: 'goto-section',
                target: nextActivityId,
                isPrimary: true
            }));
        }

        // Always show retry and quit activity buttons
        navBlock.links = [
            ...navBlock.links,
            NavigationLink.initialize({
                action: 'retry-section',
                isPrimary: false
            }),
            NavigationLink.initialize({
                action: 'quit-section',
                isPrimary: false
            })
        ];
        blocks.push(navBlock);

        return new Slide({
            identifier: 'final',
            background,
            blocks,
            blockAlignment: 'center'
        });
    }

    getParentSlides(slideId: string): Slide[] {
        const result = new Array<Slide>();

        for (const key of Object.keys(this.slides)) {
            const potentialParent = this.slides[key];
            if (potentialParent.nextSlide === slideId) {
                result.push(potentialParent);
                continue;
            }
            const branchItem = potentialParent.getBranchingItem();
            if (branchItem) {
                for (const correctResponse of branchItem.correctResponses) {
                    if (correctResponse.nextSlide === slideId) {
                        result.push(potentialParent);
                        break;
                    }
                }
            }
        }
        return result;
    }

    hasTimeLimit(): boolean {
        return this.scoringSettings != null && this.scoringSettings.timeLimit > 0;
    }
}
