import { PreloadingStrategy, Route, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppPreloadingStrategy implements PreloadingStrategy {

    constructor(private router: ActivatedRoute) { }

    preload(route: Route, load: Function): Observable<{} | null> {
        if (this.router.firstChild && this.router.firstChild.snapshot.url.length > 0) {
            if (route.data && route.data.parent === this.router.firstChild.snapshot.url[0].path) {
                return load();
            }
        }
        return of(null);
    }
}
