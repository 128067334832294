// Note: these values should be the same in our API
export enum NotificationTemplates {
    MESSAGE_CHAT = 'message-chat',
    MESSAGE_CHAT_ACTION = 'message-chat-action',
    MESSAGE_TEST = 'message-test',
    ASSIGNMENT_STARTED_ASSIGNMENT = 'assignment-started-assignment',
    ASSIGNMENT_STARTED_CLASS = 'assignment-started-class',
    ASSIGNMENT_STARTED_TRACK = 'assignment-started-track',
    ASSIGNMENT_STARTED_TRACK_CLASS = 'assignment-started-track-class',
    CERTIFICATE_EXPIRATION = 'certificate-expiration',
}
