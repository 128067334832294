import { createSelector } from '@ngrx/store';
import { SessionInfo } from '@novo/platform-common/services/learning-record-store/learning-record-store.interfaces';
import { LOCAL_SESSION_ID, UNKNOWN_SESSION_ID } from '../play/play-constants';
import { AppState } from '../state';
import { SessionsState } from './sessions.interface';

/**
 * Returns the sessions state
 *
 * @param state
 */
export const selectSessionsState = (state: AppState): SessionsState => state.sessions;

/**
 * Returns the curent session loading state
 */
export const isSessionsLoading = createSelector(
    selectSessionsState,
    (sessionsState: SessionsState): boolean => sessionsState.loading
);

/**
 * Returns a selector returning all sessions for the given assignmentId and activityId.
 * Reserved (internally used) sessions are filtered from this list.
 *
 * When no activityId is provided all sessions belonging to the given assignmentId
 * will be returned.
 *
 * @param assignmentId
 * @param activityId
 */
export const selectSessions = (assignmentId?: string, activityId?: string) => createSelector(
    selectSessionsState,
    (sessionsState): SessionInfo[] => {
        return ((!assignmentId && !activityId)
            ? sessionsState.sessions
            : sessionsState.sessions.filter(s => (activityId == null || s.activityId === activityId) && s.assignmentId === assignmentId)
        ).filter(session => {
            return session != null && ![LOCAL_SESSION_ID, UNKNOWN_SESSION_ID].includes(session.sessionId);
        }).map<SessionInfo>(session => ({
            ...session,
            from: new Date(session.from),
        }));
    }
);

/**
 * Selector selecting session with given session ID
 *
 * @param sessionId
 */
export const sessionById = (sessionId: string) => createSelector(
    selectSessions(),
    (sessions: SessionInfo[]): SessionInfo | undefined => {
        return sessions.find(s => s.sessionId === sessionId);
    }
);

/**
 * Returns a key-value pair with all locally stored sessions
 * and their version
 */
export const sessionsWithVersions = createSelector(
    selectSessions(),
    (sessions): { [sessionId: string]: number } => sessions.reduce((acc, session) => ({
        ...acc,
        [session.sessionId]: session.version
    }), {})
);

/**
 * Returns the last hydration timestamp
 */
export const lastSessionHydration = createSelector(
    selectSessionsState,
    sessionState => sessionState.lastHydration
);
