import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Group, User } from '@novo/platform-common/models';
import { ClearAuthentication, SetAuthentication, SetSessionToken, SetUserPermissions } from '@novo/platform-common/state/auth-actions';
import { getCurrentUser, selectSessionToken } from '@novo/platform-common/state/auth-reducer';
import { SetGroups } from '@novo/platform-common/state/group-actions';
import { getPathToRoot } from '@novo/platform-common/state/group-reducer';
import { AppState } from '@novo/platform-common/state/state';
import { take } from 'rxjs/operators';
import { SessionTokenService } from '../session-token/session-token.service';
import { AuthenticationStorage } from './auth.storage.service';

/**
 * Use the redux store of the studio and player to store user
 */
@Injectable()
export class AuthenticationReduxStorage implements AuthenticationStorage {

    redirectUrl: string;

    readonly user$ = this.store.select(getCurrentUser);
    readonly sessionToken$ = this.store.select(selectSessionToken);

    constructor(private store: Store<AppState>, protected sessionTokenService: SessionTokenService) {
        this.store.dispatch(new SetSessionToken(sessionTokenService.get()));
    }

    userChange(user: User): void {
        if (user != null) {
            if (!user.sessionToken) {
                throw new Error(`[AuthenticationReduxStorage] Expects user to have a sessionToken`);
            }
            // Note the order below, make sure `SetSessionToken` is always called first
            // to prevent Race conditions with the auth interceptor
            this.store.dispatch(new SetSessionToken(user.sessionToken));
            this.store.dispatch(new SetAuthentication(user));
            this.store.dispatch(new SetUserPermissions(user.permissions));
            this.sessionTokenService.set(user.sessionToken);
        } else {
            this.clear();
        }
    }

    clear(): void {
        this.store.dispatch(new ClearAuthentication());
        this.sessionTokenService.remove();
    }

    groupChange(groups: Group[]): void {
        this.store.dispatch(new SetGroups(groups));
    }

    getAncestorsOf(groupId: string): Group[] {
        let ancestors: Group[] = [];
        this.store.select(getPathToRoot(groupId)).pipe(take(1)).subscribe(path => ancestors = path);
        return ancestors;
    }

    get user(): User | undefined {
        let user;
        this.user$.pipe(take(1)).subscribe(u => user = u);
        return user;
    }

    get sessionToken(): string | undefined {
        let token;
        this.sessionToken$.pipe(take(1)).subscribe(t => token = t);
        return token;
    }
}
