import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorLoggerService, ERROR_LOGGER_TOKEN } from './error-logger.service';

@Injectable()
export class NovoErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }
    handleError(error: string | Error | ErrorEvent) {
        const loggingService = this.injector.get<ErrorLoggerService>(ERROR_LOGGER_TOKEN);
        loggingService.captureException(error);
    }
}
