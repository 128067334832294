import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Background } from '@novo/platform-common/models';
import { Class, ClassMember } from '@novo/platform-common/models/class';
import { Assignment, LearningTrack } from '@novo/platform-common/models/learning-track';
import { UserAssignment } from '@novo/platform-common/models/user-assignment';
import { ClearAuthentication, CLEAR_AUTHENTICATION, SetAuthentication, SET_AUTHENTICATED_USER } from '@novo/platform-common/state/auth-actions';
import { AppState, CacheActivityUnit, ClassMemberAssignments, OfflineCacheItem, TrackUserAssignments, UserAssignmentsOfflineCacheState, UserAssignmentsStatusState } from '@novo/platform-common/state/state';
import * as UserAssignmentsStatusActions from '@novo/platform-common/state/user-assignment-status-actions';
import { CACHE_ASSIGNMENT, REMOVE_ALL_CACHED_ASSIGNMENTS, REMOVE_ALL_CACHED_ASSIGNMENTS_FULFILLED, REMOVE_CACHED_ASSIGNMENT, REMOVE_CACHED_ASSIGNMENT_FULFILLED, REMOVE_CACHED_ASSIGNMENT_PROGRESS, SET_CLASS_DETAILS, UPDATE_CACHED_ASSIGNMENTS, UPDATE_CACHED_ASSIGNMENTS_FAILED, UPDATE_CACHED_ASSIGNMENTS_FULFILLED, UPDATE_CACHED_ASSIGNMENTS_PROGRESS } from '@novo/platform-common/state/user-assignment-status-actions';
import { AssignmentStatusHelper } from '@novo/platform-common/util/assignment-status-helper.class';
import { cloneDeep } from 'lodash-es';
import { SessionInfo } from '../services/learning-record-store/learning-record-store.interfaces';
import { ContentType } from '../services/parse-store';
import { selectSessions } from './sessions/sessions.selectors';

const createStateFromUserAssignments = (
    userAssignments: UserAssignment[], classes: Class[], personalTracks: LearningTrack[]): Partial<UserAssignmentsStatusState> => {
    const classIds: any[] = [];
    const personalTrackIds: any[] = [];
    const classAssignments: any[] = [];
    const personalTrackAssignments: any[] = [];
    const personalAssignments: any[] = [];

    userAssignments.forEach(ua => {
        if (ua.classId) {
            if (classIds.findIndex(id => id === ua.classId) === -1) {
                classIds.push(ua.classId);
                classAssignments.push({ classId: ua.classId });
            }
            const asgn = classAssignments.find(el => el.classId === ua.classId);
            if (asgn != null) {
                asgn.userAssignments = [...(asgn.userAssignments || []), ua];
            }

        } else if (ua.trackId) {
            if (personalTrackIds.findIndex(id => id === ua.trackId) === -1) {
                personalTrackIds.push(ua.trackId);
                personalTrackAssignments.push({ trackId: ua.trackId });
            }
            const asgn = personalTrackAssignments.find(el => el.trackId === ua.trackId);
            if (asgn != null) {
                asgn.userAssignments = [...(asgn.userAssignments || []), ua];
            }
        } else {
            personalAssignments.push(ua);
        }
    });

    return {
        classAssignments: classAssignments,
        personalTrackAssignments: personalTrackAssignments,
        personalAssignments: personalAssignments,
        classes: classes,
        personalTracks: personalTracks,
        hydrating: false,
    };
};

/**
 * Applies 'changes' on the offlineCache item with a matching 'assignmentId'.
 * Passing undefined as 'changes' will remove the item.
 */
export const modifyOfflineCache = (state: UserAssignmentsStatusState, assignmentId: string, changes: Partial<OfflineCacheItem> | undefined): UserAssignmentsStatusState => {
    const offlineCache = state.offlineCache[assignmentId];
    state = {
        ...state,
        offlineCache: {
            ...state.offlineCache,
            [assignmentId]: (offlineCache && changes) ? {
                ...offlineCache,
                ...changes,
            } : undefined
        }
    };
    // Remove item if changes equals undefined
    if (offlineCache && changes === undefined) {
        delete state.offlineCache[assignmentId];
    }
    return state;
};

/**
 * Tries to make the given error less generic by checking its contents. The returned
 * key should be an existing key in the i18n files.
 *
 * @param error
 */
export const errorToMessage = (error: Error): string => {
    // Check if the name or message contains anything about quota
    if (error instanceof Error && (error.name + error.message).indexOf('QuotaExceededError') > -1) {
        return 'NO_SPACE_LEFT_ERROR_MSG';
    }
    return 'GENERIC_ERROR_TRY_AGAIN_MSG';
};

export const defaultUserAssignmentStatusState: UserAssignmentsStatusState = {
    hydrating: false,
    offlineCache: {}
};

export function userAssignmentsReducer(
    state: UserAssignmentsStatusState = defaultUserAssignmentStatusState,
    action: UserAssignmentsStatusActions.UserAssignmentsStatusAction | ClearAuthentication | SetAuthentication
): UserAssignmentsStatusState {
    let result = state;
    switch (action.type) {

        case UserAssignmentsStatusActions.HYDRATE_USER_ASSIGNMENT_STATUS:
            result = {
                ...result,
                ...createStateFromUserAssignments(action.userAssignments, action.classes, action.personalTracks)
            };
            break;

        case UserAssignmentsStatusActions.SET_ASSIGNMENTS_LAST_UPDATED:
            result = {
                ...state,
                lastUpdated: action.date
            };
            break;

        case SET_AUTHENTICATED_USER:
        case UserAssignmentsStatusActions.FAIL_HYDRATE_USER_ASSIGNMENT_STATUS:
            result = {
                ...result,
                hydrating: false,
                lastUpdated: undefined,
            };
            break;

        case UserAssignmentsStatusActions.UPDATE_USER_ASSIGNMENT_STATUS:
            result = {
                ...state,
                hydrating: true,
                lastUpdated: new Date()
            };
            break;

        case CLEAR_AUTHENTICATION:
            result = {
                hydrating: false,
                offlineCache: result.offlineCache,
            };
            break;

        case SET_CLASS_DETAILS:
            result = cloneDeep(state);
            if (result.classes) {
                const idx: number = result.classes.findIndex(ug => ug.identifier === action.userClass.identifier);
                if (idx !== -1) {
                    result.classes[idx] = action.userClass;
                }
            }
            break;

        case CACHE_ASSIGNMENT:
            result = {
                ...result,
                offlineCache: {
                    ...result.offlineCache,
                    [action.assignmentId]: result.offlineCache[action.assignmentId] || { cachedUnits: [] }
                }
            };
            break;

        case UPDATE_CACHED_ASSIGNMENTS:
            result = modifyOfflineCache(result, action.assignmentId, {
                caching: true,
                progress: 0,
                error: undefined,
            });
            break;

        case UPDATE_CACHED_ASSIGNMENTS_PROGRESS:
            const cacheItem = result.offlineCache[action.assignmentId];
            if (cacheItem) {
                result = modifyOfflineCache(result, action.assignmentId, {
                    caching: true,
                    failed: false,
                    progress: action.progress,
                    cachedUnits: action.items
                });
            }
            break;

        case UPDATE_CACHED_ASSIGNMENTS_FULFILLED:
            result = modifyOfflineCache(result, action.assignmentId, {
                caching: false,
                failed: false,
                progress: 1,
                error: undefined,
                cachedUnits: action.items
            });
            break;

        case UPDATE_CACHED_ASSIGNMENTS_FAILED:
            result = modifyOfflineCache(result, action.assignmentId, {
                caching: false,
                progress: 0,
                failed: true,
                error: errorToMessage(action.error),
                cachedUnits: []
            });
            break;

        case REMOVE_CACHED_ASSIGNMENT:
            result = modifyOfflineCache(result, action.assignmentId, {
                removing: true,
                progress: 0
            });
            break;

        case REMOVE_CACHED_ASSIGNMENT_PROGRESS:
            result = modifyOfflineCache(result, action.assignmentId, {
                progress: action.progress,
                removing: true
            });
            break;

        case REMOVE_CACHED_ASSIGNMENT_FULFILLED:
            result = modifyOfflineCache(result, action.assignmentId, undefined);
            break;

        case REMOVE_ALL_CACHED_ASSIGNMENTS:
            // Mark all as removing
            Object.keys(result.offlineCache).forEach(assignmentId => {
                result = modifyOfflineCache(result, assignmentId, {
                    progress: 0,
                    removing: true
                });
            });
            break;

        case REMOVE_ALL_CACHED_ASSIGNMENTS_FULFILLED:
            result = {
                ...result,
                offlineCache: {}
            };
            break;
    }
    return result;
}

export const selectAllUserAssignments = (state: AppState) => state.userAssignments;

export const selectIsHydratingUserAssignments = (state: AppState) => state.userAssignments.hydrating;

export const selectIsUserAssignmentsHydrated = createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState) => {
    // When user assignment status is loaded, personalTracks are at least an empty object (and not null)
    return (asgn && !asgn.hydrating && asgn.personalTracks != null);
});

export const selectClassAssignments = (classId: string) => createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState) => {
    if (asgn && !asgn.hydrating && asgn.classAssignments) {
        const userClass: ClassMemberAssignments | undefined = asgn.classAssignments.find(el => el.classId === classId);
        return userClass ? userClass.userAssignments : null;
    }
});

export const selectTrackAssignments = (id: string) => createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState) => {
    if (asgn && !asgn.hydrating && (asgn.personalTrackAssignments || asgn.classAssignments)) {
        const track = (asgn.personalTrackAssignments || []).find(el => el.trackId === id);
        if (track) { return track.userAssignments; }
        const cls = (asgn.classAssignments || []).find(el => el.classId === id);
        if (cls) { return cls.userAssignments; }
    }
});

/**
 * Searches through all class, personal and track assignments for the given assignment.
 * Note that this will return the assignment object, not an UserAssignment.
 *
 * @param id
 * @param includeSiblings   set to true to return the requested assignment as well as all
 *                          siblings from the same track/class/assignment
 */
export function selectAssignment(id: string): MemoizedSelector<any, Assignment | undefined>;
export function selectAssignment(id: string, includeSiblings: true): MemoizedSelector<any, { assignment: Assignment, siblings: Assignment[] } | undefined>;
export function selectAssignment(id: string, includeSiblings?: true): MemoizedSelector<any, { assignment: Assignment, siblings: Assignment[] } | Assignment | undefined> {
    return createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState): { assignment: Assignment, siblings: Assignment[] } | Assignment | undefined => {
        let assignment: Assignment | undefined;
        let siblings: Assignment[] | undefined;
        // First check class assignments
        if (asgn.classAssignments) {
            for (const ca of asgn.classAssignments) {
                const ua = ca.userAssignments.find(_ua => _ua.assignment.identifier === id);
                if (ua) {
                    assignment = ua.assignment;
                    siblings = includeSiblings ? ca.userAssignments.map(_ua => _ua.assignment) : [];
                }
            }
        }
        // if not found, check personal assignments
        if (asgn.personalAssignments) {
            const pa = asgn.personalAssignments.find(_pa => _pa.assignment.identifier === id);
            if (pa) {
                assignment = pa.assignment;
                siblings = includeSiblings ? asgn.personalAssignments.map(_pa => _pa.assignment) : [];
            }
        }
        // if not found, check track assignments
        if (asgn.personalTrackAssignments) {
            for (const pta of asgn.personalTrackAssignments) {
                const ua = pta.userAssignments.find(_ua => _ua.assignment.identifier === id);
                if (ua) {
                    assignment = ua.assignment;
                    siblings = includeSiblings ? pta.userAssignments.map(_ua => _ua.assignment) : [];
                }
            }
        }

        return (includeSiblings) ? assignment && siblings ? { assignment, siblings } : undefined : assignment;
    });
}

export const selectPersonalTrackAssignments = (trackId: string) => createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState) => {
    if (asgn && !asgn.hydrating && asgn.personalTrackAssignments) {
        const track: TrackUserAssignments | undefined = asgn.personalTrackAssignments.find(el => el.trackId === trackId);
        return track ? track.userAssignments : undefined;
    }
});

export const selectPersonalAssignments = createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState) => {
    if (asgn && !asgn.hydrating && asgn.personalAssignments) {
        return asgn.personalAssignments;
    }
});

export const selectUserAssignmentByAssignmentId = (assignmentId: string) => createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState): UserAssignment | undefined => {
    if (asgn && !asgn.hydrating) {
        if (asgn.classAssignments) {
            let ua: UserAssignment | undefined;
            asgn.classAssignments.forEach(ca => {
                ua = ua || ca.userAssignments.find(cua => cua.assignment.identifier === assignmentId);
            });
            if (ua != null) { return ua; }
        }

        if (asgn.personalTrackAssignments) {
            let ua: UserAssignment | undefined;
            asgn.personalTrackAssignments.forEach(pta => {
                ua = ua || pta.userAssignments.find(ptua => ptua.assignment.identifier === assignmentId);
            });
            if (ua != null) { return ua; }
        }

        if (asgn.personalAssignments) {
            const ua = asgn.personalAssignments.find(pa => pa.assignment.identifier === assignmentId);
            if (ua != null) { return ua; }
        }
    }
});

/**
 * Returns a list of activities for the given Assignment ID. This automatically
 * reduces all courses to their inner activities.
 *
 * @param assignmentId
 */
export const selectAssignmentActivities = (assignmentId: string) => createSelector(
    selectAssignment(assignmentId),
    (assignment): CacheActivityUnit[] | null => {
        if (!assignment) {
            return null;
        }
        return assignment.units.reduce<CacheActivityUnit[]>((accTwo, unit) => [
            ...accTwo,
            { identifier: unit.identifier, type: <ContentType>unit.type },
            ...((unit.type === 'course') ? unit.sections.map(s => ({ identifier: s.identifier, type: <ContentType>'activity' })) : []) // Add all course sections
        ], []);
    }
);

/**
 * Returns cache state for given assignment
 */
export const selectAssignmentCache = (assignmentId: string) => createSelector(
    selectAllUserAssignments,
    (state) => state.offlineCache[assignmentId]
);

/**
 * Returns cache state
 */
export const selectCache = createSelector(
    selectAllUserAssignments,
    (state) => state.offlineCache
);

export interface SelectAssignmentCacheStatus {
    cached: boolean;
    failed?: string | null;
    units: CacheActivityUnit[];
    add: CacheActivityUnit[];
    remove: CacheActivityUnit[];
}

/**
 * Returns the cache status for a given assignment. The returned response will contain
 * a diff with ID's of activities which need to be downloaded or removed, based on the
 * current userAssignments state
 *
 * @param assignmentId
 */
export const selectAssignmentCacheStatus = (assignmentId: string) => createSelector(
    selectAssignmentCache(assignmentId),
    selectAssignmentActivities(assignmentId),
    (cache, units): SelectAssignmentCacheStatus => {
        const cachedUnits = (cache && cache.cachedUnits) ? cache.cachedUnits : [];
        const trackUnits = units || [];

        // Return status object with all ID's which need to be added and/or removed
        return {
            cached: (cache != null && cache.failed !== true),
            failed: (cache != null && cache.failed === true) ? cache.error : null,
            units: trackUnits,
            add: trackUnits.filter(unit => cachedUnits.find(cu => cu.identifier === unit.identifier && cu.type === unit.type) === undefined),
            remove: cachedUnits.filter(cu => trackUnits.find(tu => tu.identifier === cu.identifier && tu.type === cu.type) === undefined),
        };
    }
);

/**
 * Returns a list of all activities ids in our cache.
 */
export const allCachedActivities = createSelector(
    selectCache,
    (cache: UserAssignmentsOfflineCacheState) => {
        return Object.keys(cache).reduce<string[]>((acc, key) => {
            const cacheItem = cache[key];
            if (cacheItem != null) {
                acc.push(...cacheItem.cachedUnits.filter(u => u.type === 'activity').map(u => u.identifier));
            }
            return acc;
        }, []);
    }
);

export const initializingUserAssignmentStatus = createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState) => {
    return asgn && asgn.hydrating;
});

export const getClassMembers = (classId) => createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState) => {
    if (asgn && asgn.classes) {
        const ug = asgn.classes.find(c => c.identifier === classId);
        if (ug && ug.users) {
            return ug.users.map((ugu: ClassMember) => ugu.user);
        }
    }
});

export const getClass = (classId) => createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState) => {
    if (asgn && asgn.classes) {
        const ug = asgn.classes.find(c => c.identifier === classId);
        if (ug) {
            return ug;
        } else {
            return null;
        }
    }
});

export const getPersonalTrack = (trackId: string) => createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState) => {
    if (asgn && asgn.personalTracks) {
        const track = asgn.personalTracks.find(ug => ug.identifier === trackId);
        if (track) {
            return track;
        }
    }
});

export const getLearningTrack = (trackId: string) => createSelector(selectAllUserAssignments, (asgn: UserAssignmentsStatusState) => {
    if (asgn.classes) {
        for (const clazz of asgn.classes) {
            const track = clazz.getLearningTrack(trackId);
            if (track) { return track; }
        }
    }
    return asgn.personalTracks?.find(persTrack => persTrack.identifier === trackId);
});

export type LearnDashboardType = 'class' | 'track' | 'personal-assignments';

/**
 * Custom ID used for the composed 'individual assignments' dashboard item
 */
export const INDIVIDUAL_ASSIGNMENTS_ID = 'INDIVIDUAL_ASSIGNMENTS_ID';

export interface LearnDashboard {
    title: string;
    coverImage?: Background;
    id: string;
    assignments: UserAssignment[];
    type?: LearnDashboardType;
    status: LearnDashboardData;
    disableOffline?: boolean;
}

export interface LearnDashboardData {
    nrAssignmentsPending: number;
    nrAssignmentsOverdue: number;
    pendingAssignments: UserAssignment[];
    nrActivitiesPassed: number;
    nrActivitiesFailed: number;
    avgHiScore: number;
    percActivitiesPassed: number;
    lastActive?: Date;
}

export const getDashboardData = (assignments: UserAssignment[], sessions: SessionInfo[]): LearnDashboardData => {
    const now = new Date();
    let nrAssignmentsPending = 0;
    let nrAssignmentsOverdue = 0;
    const pendingAssignments: UserAssignment[] = [];
    let nrActivitiesFailed = 0;
    let nrActivitiesPassed = 0;
    let sumHiScore = 0;
    let nrScoreActivities = 0;
    let nrActivities = 0;
    let lastActive: Date | undefined;

    assignments.forEach((ua: UserAssignment) => {
        const assignmentStatus = new AssignmentStatusHelper(ua, sessions);
        const status = assignmentStatus.getStatus();

        if (status !== 'completed' && now > ua.assignment.startDate) {
            nrAssignmentsPending += 1;
            pendingAssignments.push(ua);
            if (ua.assignment.dueDate && now > ua.assignment.dueDate) {
                nrAssignmentsOverdue += 1;
            }
        }

        assignmentStatus.allActivities.forEach((unit) => {
            const bestResult = assignmentStatus.getBestResultForUnit(unit.identifier);
            const unitStatus = assignmentStatus.getStatusForUnit(unit.identifier);
            const unitLastActive = assignmentStatus.getLastActivityForUnit(unit.identifier);
            if (bestResult && bestResult.score) {
                nrScoreActivities += 1;
                sumHiScore += bestResult.score.percentage;
            }
            if (unitStatus === 'completed') {
                if (bestResult && !bestResult.passed) {
                    nrActivitiesFailed += 1;
                } else {
                    nrActivitiesPassed += 1;
                }
            }
            if (unitLastActive && (lastActive == null || unitLastActive > lastActive)) {
                lastActive = unitLastActive;
            }
            nrActivities += 1;
        });
    });

    let avgHiScore = 0;
    let percActivitiesPassed = 0;
    if (nrScoreActivities > 0) {
        avgHiScore = sumHiScore / nrScoreActivities;
    }
    if (nrActivities > 0) {
        percActivitiesPassed = 100 * (nrActivitiesPassed / nrActivities);
    }

    return {
        nrAssignmentsPending,
        nrAssignmentsOverdue,
        pendingAssignments,
        nrActivitiesPassed,
        nrActivitiesFailed,
        avgHiScore,
        percActivitiesPassed,
        lastActive
    };
};

export const getDashboards = createSelector(
    selectAllUserAssignments,
    selectSessions(),
    (asgn: UserAssignmentsStatusState, sessions: SessionInfo[]): LearnDashboard[] | null => {
        const isLoaded: boolean = asgn != null && (
            asgn.classAssignments != null ||
            asgn.classes != null ||
            asgn.personalAssignments != null ||
            asgn.personalTrackAssignments != null ||
            asgn.personalTracks != null
        );
        if (!isLoaded) {
            return null;
        }
        const dashboards: LearnDashboard[] = [];
        if (asgn) {
            if (asgn.classAssignments) {
                asgn.classAssignments.forEach((classAsgn: ClassMemberAssignments) => {
                    const currentClass = (asgn.classes || []).find(ca => ca.identifier === classAsgn.classId);
                    if (currentClass != null) {
                        const title: string = currentClass.name;
                        const coverImage = currentClass.coverImage;
                        dashboards.push({
                            title: title,
                            coverImage: coverImage,
                            assignments: classAsgn.userAssignments,
                            type: 'class',
                            id: classAsgn.classId,
                            status: getDashboardData(classAsgn.userAssignments, sessions)
                        });
                    } else {
                        // Raven.captureMessage('Class assignments found while class not available', { extra: { classId: classAsgn.classId } });
                    }
                });
            }
            if (asgn.personalTrackAssignments) {
                asgn.personalTrackAssignments.forEach((trackAsgn: TrackUserAssignments) => {
                    const track = (asgn.personalTracks || []).find(pt => pt.identifier === trackAsgn.trackId);
                    if (track != null) {
                        const title: string = track.name;
                        const coverImage: Background = track.coverImage;
                        dashboards.push({
                            title: title,
                            coverImage: coverImage,
                            assignments: trackAsgn.userAssignments,
                            type: 'track',
                            id: trackAsgn.trackId,
                            status: getDashboardData(trackAsgn.userAssignments, sessions)
                        });
                    } else {
                        //    Raven.captureMessage('Track assignments found while track not available', { extra: { trackId: trackAsgn.trackId } });
                    }
                });
            }
            if (asgn.personalAssignments && asgn.personalAssignments.length > 0) {
                dashboards.push({
                    id: INDIVIDUAL_ASSIGNMENTS_ID,
                    title: 'INDIVIDUAL_ASSIGNMENTS',
                    assignments: asgn.personalAssignments,
                    type: 'personal-assignments',
                    status: getDashboardData(asgn.personalAssignments, sessions),
                    disableOffline: true
                });
            }
            // Sort dashboards on last active date (last active first)
            dashboards.sort((a: LearnDashboard, b: LearnDashboard) => {
                if (a.status.lastActive == null) { return 1; }
                if (b.status.lastActive == null) { return -1; }
                return (a.status.lastActive > b.status.lastActive) ? -1 : 1;
            });
        }
        return dashboards;
    }
);
