import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthErrorHandlerService } from './auth-error-handler.service';

@Injectable()
export class AuthErrorHandlerStudioService extends AuthErrorHandlerService {

    constructor(private router: Router) {
        super();
    }

    public handleAuthenticationError(): void {
        void this.router.navigate(['/login']);
    }
}
