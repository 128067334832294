import { HotSpot, ImageMatchTarget, MediaInstance, ResponseFeedbackItem, TextInteractionOption } from '..';
import { InteractionResponse, InteractionResponseItem, ResponseByUser } from '../interaction-response';
import { Interaction, InteractionInputItem } from '../interaction/interaction';
import { NoInteractionScore } from '../score';
import { BaseInteractionModel, ResponseStatistics } from './base-interaction-model';

export class HotSpotNavigationInteractionModel extends BaseInteractionModel {
    private interactionItem: InteractionInputItem;

    private stats: { [key: string]: ResponseStatistics };

    constructor(interaction: Interaction) {
        super();
        this.interactionItem = interaction.items[0] as InteractionInputItem;
        this.stats = { 'missed': { id: 'missed', count: 0, tooltip: undefined, correct: undefined } };
        this.interactionItem.correctResponses.forEach(response => {
            let tooltip: string | { media: MediaInstance, hotspot: HotSpot } | undefined;
            if (response instanceof TextInteractionOption) {
                tooltip = response.text;
            } else {
                if (this.interactionItem.matchTarget instanceof ImageMatchTarget) {
                    const hotspot = this.interactionItem.matchTarget.hotspots.find(o => o.id === response.values[0].source);
                    const media = this.interactionItem.matchTarget.background;
                    if (hotspot && media) { tooltip = { media, hotspot }; }
                } else {
                    tooltip = 'No text';
                }
            }
            if (response.nextSlide) {
                this.stats[response.nextSlide] = { id: response.nextSlide, count: 0, tooltip, correct: undefined };
            }
        });
    }

    isCorrect(responseTemplate: InteractionResponse): boolean {
        return false;
    }

    getScore(responseTemplate: InteractionResponse): NoInteractionScore {
        // No score for hotspot navigation
        return new NoInteractionScore();
    }

    getMaxScore(): NoInteractionScore {
        return new NoInteractionScore();
    }

    getResponseTemplate(interaction: Interaction): InteractionResponse {
        const ir = new InteractionResponse();
        ir.items = [new InteractionResponseItem()];
        return ir;
    }

    public collectStatistics(responses: ResponseByUser[]): ResponseStatistics[] {
        Object.keys(this.stats).forEach(key => this.stats[key].count = 0);

        responses.forEach(userResponse => {
            const response = userResponse.response.items[0];
            if (response == null || response.options.length === 0) {
                this.stats['missed'].count++;
            } else {
                response.options.forEach(option => {
                    const selectedId = option.target || 'missed';
                    this.stats[selectedId].count++;
                });
            }
        });

        return Object.keys(this.stats).map(key => this.stats[key]);
    }

    public getResponseFeedback(response: InteractionResponse): ResponseFeedbackItem[] {
        const responseItem = response.items[0];
        if (responseItem && responseItem.options[0].target) {
            const target = responseItem.options[0].target;
            const stat = this.stats[target];
            if (stat && stat.tooltip) {
                return [{ item: stat.tooltip, statusCode: 'normal' }];
            }
        }
        return [{ item: 'No link selected', statusCode: 'normal' }];
    }
}
