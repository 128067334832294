import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ClearAuthentication } from '@novo/platform-common/state/auth-actions';
import { AppState } from '@novo/platform-common/state/state';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class AuthenticationInterceptor implements HttpInterceptor {
    constructor(private router: Router, private store: Store<AppState>) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err instanceof HttpErrorResponse && request.url.indexOf('/parse/') > 0) {
                const errJson = err.error;
                // Invalid session or inactive user
                if (errJson.code === 401 || errJson.code === 701 && !request.url.endsWith('/login')) {
                    this.store.dispatch(new ClearAuthentication());
                    void this.router.navigateByUrl('/login');
                }
            }
            return throwError(err);
        }));
    }
}
