import { Action } from '@ngrx/store';
import { DeviceOrientation, DeviceType, LanguageCode, Locale, Settings, ViewMode } from '../models';


export type SettingsAction =
    SetSettings |
    SetUserLanguage |
    SetUserLocale |
    SetDeviceScale |
    SetViewMode |
    ToggleDeviceOrientation |
    ToggleDeviceType |
    SetDeviceOrientation |
    SetDeviceType |
    ToggleSoundFx |
    ToggleDownloadWifiOnly |
    ToggleSmartDownloads;

export const SET_SETTINGS = 'SET_SETTINGS';
export class SetSettings implements Action {
    readonly type = SET_SETTINGS;
    constructor(public settings: Settings) { }
}

export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';
export class SetUserLanguage implements Action {
    readonly type = SET_USER_LANGUAGE;
    constructor(public code: LanguageCode) { }
}

export const SET_USER_LOCALE = 'SET_USER_LOCALE';
export class SetUserLocale implements Action {
    readonly type = SET_USER_LOCALE;
    constructor(public code: Locale) { }
}

export const SET_DEVICE_SCALE = 'SET_DEVICE_SCALE';
export class SetDeviceScale implements Action {
    readonly type = SET_DEVICE_SCALE;
    constructor(public scale: string) { }
}

export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export class SetViewMode implements Action {
    readonly type = SET_VIEW_MODE;
    constructor(public mode: ViewMode) { }
}

export const TOGGLE_DEVICE_ORIENTATION = 'TOGGLE_DEVICE_ORIENTATION';
export class ToggleDeviceOrientation implements Action {
    readonly type = TOGGLE_DEVICE_ORIENTATION;
    constructor() { }
}

export const TOGGLE_DEVICE_TYPE = 'TOGGLE_DEVICE_TYPE';
export class ToggleDeviceType implements Action {
    readonly type = TOGGLE_DEVICE_TYPE;
    constructor() { }
}

export const SET_DEVICE_ORIENTATION = 'SET_DEVICE_ORIENTATION';
export class SetDeviceOrientation implements Action {
    readonly type = SET_DEVICE_ORIENTATION;
    constructor(public orientation: DeviceOrientation) { }
}

export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
export class SetDeviceType implements Action {
    readonly type = SET_DEVICE_TYPE;
    constructor(public deviceType: DeviceType) { }
}

export const TOGGLE_SOUNDFX = 'TOGGLE_SOUNDFX';
export class ToggleSoundFx implements Action {
    readonly type = TOGGLE_SOUNDFX;
    constructor() { }
}

export const TOGGLE_DOWNLOAD_WIFI_ONLY = 'TOGGLE_DOWNLOAD_WIFI_ONLY';
export class ToggleDownloadWifiOnly implements Action {
    readonly type = TOGGLE_DOWNLOAD_WIFI_ONLY;
    constructor() { }
}

export const TOGGLE_SMART_DOWNLOADS = 'TOGGLE_SMART_DOWNLOADS';
export class ToggleSmartDownloads implements Action {
    readonly type = TOGGLE_SMART_DOWNLOADS;
    constructor() { }
}

