import { Type } from 'class-transformer';
import { cloneDeep } from 'lodash-es';
import { WordErrorRate } from '../../util/word-error-rate';
import { AsrResult, AsrWord } from '../asr/asr-result';
import { serializeType } from '../utilities';
import { Pronunciation, PronunciationInteractionOption, PronunciationInteractionOptionWord } from './option';


export const UTT_THRESHOLD_PROB = 10.0;
export const WORD_THRESHOLD_PROB = 47.0;

export interface Transcription {
    type: 'ortographic' | 'phonetic';
    data: any; // e.g. text
    transcriberId: string; // user id
}

export class AsrFeedbackAsrWord extends AsrWord {
    hasErrors: boolean;
    isEvaluated: boolean;
    minDistCorrectPronunciation: Pronunciation;
    showPhoneFeedback = false;

    static initialize(json: any): AsrFeedbackAsrWord {
        const result = super.initialize(json) as AsrFeedbackAsrWord;
        if (json.minDistCorrectPronunciation != null) {
            result.minDistCorrectPronunciation = Pronunciation.initialize(json.minDistCorrectPronunciation);
        }
        return result;
    }

    static initFromAsrResult(word: AsrWord, targetWord: PronunciationInteractionOptionWord): AsrFeedbackAsrWord {
        const fbWord: AsrFeedbackAsrWord = cloneDeep(word) as AsrFeedbackAsrWord;
        let recognizedPronunciation: Pronunciation | undefined;
        let minEditDistance = Infinity;
        let minEditPronunciation: Pronunciation | undefined;

        if (targetWord.pronunciations == null) {
            fbWord.isEvaluated = false;
            return fbWord;
        }

        targetWord.pronunciations.forEach((p: Pronunciation) => {
            const realized = word.phones.map(ph => ph.label); // DvL can move out of this loop?
            const target = p.phones;
            if (target.join(' ') === realized.join(' ')) {
                recognizedPronunciation = p;
            }
            if (p.correct) {
                const distance = new WordErrorRate(target, realized).wer;
                if (distance < minEditDistance) {
                    minEditDistance = distance;
                    minEditPronunciation = p;
                }
            }
        });

        fbWord.isEvaluated = targetWord.isEvaluated;
        if (targetWord.isEvaluated && minEditPronunciation != null) {
            fbWord.minDistCorrectPronunciation = minEditPronunciation; // TODO: remove this member and calculate min dist correct in pronunciation feedback component
            fbWord.hasErrors = fbWord.confidence.prob <= WORD_THRESHOLD_PROB
                || recognizedPronunciation == null
                || !recognizedPronunciation.correct;
        }
        return fbWord;
    }
}

export class AsrFeedback extends AsrResult {
    @Type(serializeType(AsrFeedbackAsrWord)) words: AsrFeedbackAsrWord[] = [];
    isConfident = true;
    hasErrors = false;
    transcription?: Transcription[] = undefined;

    private static correctAudioUrl(audioSrc?: string | { _id: string } | { url: string }): string | undefined | { url: string } {
        if (!audioSrc) { return; }
        if (typeof audioSrc === 'string') {
            return audioSrc;
        }
        if (audioSrc['url']) {
            return audioSrc as { url: string };
        }
        return audioSrc['_id'];
    }

    static initialize(json: any): AsrFeedback {
        // Fix some issues where audio and/or task_uuid are stored incorrectly for the initialization of the AsrResult
        json.task_uuid = json.task_uuid || json.taskUUID;
        if (json.audio) {
            json.audio = {
                mp3: AsrFeedback.correctAudioUrl(json.audio.mp3),
                ogg: AsrFeedback.correctAudioUrl(json.audio.ogg),
                audioKey: json.audio.audioKey
            };
        }
        // End of fix

        const result = super.initialize(json) as AsrFeedback;
        if (json.words != null) {
            result.words = json.words.map(w => AsrFeedbackAsrWord.initialize(w));
        }
        if (json.isConfident != null) {
            result.isConfident = json.isConfident;
        }
        if (json.hasErrors != null) {
            result.hasErrors = json.hasErrors;
        }
        return result;
    }

    static initFromAsrResult(asrResult: AsrResult, option?: PronunciationInteractionOption): AsrFeedback {
        const result: AsrFeedback = new AsrFeedback(asrResult.taskUUID);
        result.audio = asrResult.audio;
        result.recognized = asrResult.recognized;
        // TODO: CHECK IS THIS CORRECT?
        if (asrResult.confidence) {
            result.isConfident = asrResult.confidence.prob >= UTT_THRESHOLD_PROB;
        } else {
            result.isConfident = asrResult['isConfident'] ?? false;
        }
        result.receivedAt = asrResult.receivedAt;

        if (option instanceof PronunciationInteractionOption) {
            // asrResult.words contains all words which are in the pronunciation plus possibly
            // some silence entries or exactly one silence entry
            const asrWords = asrResult.words.filter(w => w.isSpeech);
            for (let wordIndex = 0; wordIndex < option.words.length; wordIndex++) {
                let asrWord;
                if (wordIndex >= asrWords.length) {
                    asrWord = AsrWord.initialize({
                        begin: 0,
                        end: 0,
                        label: { raw: option.words[wordIndex].label },
                        confidence: { llr: -100, prob: 0 },
                        phones: [],
                        isSpeech: true
                    });
                } else {
                    asrWord = asrWords[wordIndex];
                }
                const fbWord = AsrFeedbackAsrWord.initFromAsrResult(asrWord, option.words[wordIndex]);
                result.words.push(fbWord);
            }
            result.hasErrors = result.words.some(word => word.hasErrors || word.liaison?.correct === false);
        } else if (option != null) {
            result.words = asrResult.words.map(w => cloneDeep(w) as AsrFeedbackAsrWord);
        }
        return result;
    }
}
