import { Action } from '@ngrx/store';
import { Background, CustomUserField, Features, Group, GroupModel, Media, New, PaymentModel } from '../models';

export type GroupAction =
    SetGroups |
    SetRootGroup |
    SelectGroup |
    AddGroup |
    AddedGroup |
    RemoveGroup |
    AdjustSeatsActive |
    SetSeatLimit |
    AdjustWistiaUploads |
    SetWistiaLimit |
    SetGroupCover |
    SetGroupName |
    SetGroupLogo |
    SetPaymentModel |
    SetGroupFeatures |
    UpdateCustomUserFields;


export const SET_GROUPS = 'SET_GROUPS';
export class SetGroups implements Action {
    readonly type = SET_GROUPS;
    constructor(public groups: Group[]) { }
}

export const SET_ROOT_GROUP = 'SET_ROOT_GROUP';
export class SetRootGroup implements Action {
    readonly type = SET_ROOT_GROUP;
    constructor(public groupId: string) { }
}

export const SELECT_GROUP = 'SELECT_GROUP';
export class SelectGroup implements Action {
    readonly type = SELECT_GROUP;
    constructor(public groupId: string) { }
}

export const ADD_GROUP = 'ADD_GROUP';
export class AddGroup implements Action {
    readonly type = ADD_GROUP;
    constructor(public parent: Group, public child: New<GroupModel>) { }
}

export const ADDED_GROUP = 'ADDED_GROUP';
export class AddedGroup implements Action {
    readonly type = ADDED_GROUP;
    constructor(public parent: Group, public child: Group) { }
}

export const REMOVE_GROUP = 'REMOVE_GROUP';
export class RemoveGroup implements Action {
    readonly type = REMOVE_GROUP;
    constructor(public identifier: string) { }
}

export const ADJUST_ACTIVE_SEATS = 'ADJUST_ACTIVE_SEATS';
export class AdjustSeatsActive implements Action {
    readonly type = ADJUST_ACTIVE_SEATS;
    constructor(public groupId: string, public diff: number) { }
}

export const SET_LIMIT_SEATS = 'SET_LIMIT_SEATS';
export class SetSeatLimit implements Action {
    readonly type = SET_LIMIT_SEATS;
    constructor(public group: Group, public limit?: number) { }
}

export const ADJUST_WISTIA_UPLOADS = 'ADJUST_WISTIA_UPLOADS';
export class AdjustWistiaUploads implements Action {
    readonly type = ADJUST_WISTIA_UPLOADS;
    constructor(public groupId: string, public diff: number) { }
}

export const SET_LIMIT_WISTIA = 'SET_LIMIT_WISTIA';
export class SetWistiaLimit implements Action {
    readonly type = SET_LIMIT_WISTIA;
    constructor(public group: Group, public limit?: number) { }
}

export const SET_GROUP_COVER = 'SET_GROUP_COVER';
export class SetGroupCover implements Action {
    readonly type = SET_GROUP_COVER;
    constructor(public group: Group, public background: Background) { }
}

export const SET_GROUP_NAME = 'SET_GROUP_NAME';
export class SetGroupName implements Action {
    readonly type = SET_GROUP_NAME;
    constructor(public group: Group, public name: string) { }
}

export const SET_GROUP_LOGO = 'SET_GROUP_LOGO';
export class SetGroupLogo implements Action {
    readonly type = SET_GROUP_LOGO;
    constructor(public group: Group, public logo: Media) { }
}

export const SET_PAYMENT_MODEL = 'SET_PAYMENT_MODEL';
export class SetPaymentModel implements Action {
    readonly type = SET_PAYMENT_MODEL;
    constructor(public group: Group, public paymentModel: PaymentModel | null) { }
}

export const SET_GROUP_FEATURES = 'SET_GROUP_FEATURES';
export class SetGroupFeatures implements Action {
    readonly type = SET_GROUP_FEATURES;
    constructor(public group: Group, public features: Features) { }
}

export const UPDATE_CUSTOM_USER_FIELDS = 'UPDATE_CUSTOM_USER_FIELDS';
export class UpdateCustomUserFields implements Action {
    readonly type = UPDATE_CUSTOM_USER_FIELDS;
    constructor(public group: Group, public fields: CustomUserField[]) { }
}


