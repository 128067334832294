import { UUID } from 'angular2-uuid';
import { InteractionResponse, InteractionResponseItem, InteractionResponseOption, ResponseByUser } from '../interaction-response';
import { Interaction, InteractionInputItem } from '../interaction/interaction';
import { PronunciationInteractionOption } from '../interaction/option';
import { AsrFeedback } from '../interaction/pronunciation-feedback';
import { InteractionScore, NoInteractionScore } from '../score';
import { BaseInteractionModel, ResponseFeedbackItem, ResponseStatistics } from './base-interaction-model';

export class PronunciationInteractionModel extends BaseInteractionModel {
    constructor(private interaction: Interaction) {
        super();
    }

    getResponseTemplate(interaction: Interaction): InteractionResponse {
        const ir = new InteractionResponse();
        const iri = new InteractionResponseItem();
        iri.options.push(new InteractionResponseOption());
        ir.items.push(iri);
        return ir;
    }

    isCorrect(response: InteractionResponse): boolean {
        const item = response.items[0];
        if (item && item.asrFeedback) {
            const fb: AsrFeedback = item.asrFeedback;
            return fb.isConfident && !fb.hasErrors;
        }
        return false;
    }

    getScore(response: InteractionResponse): InteractionScore | NoInteractionScore {
        if (this.isCorrect(response)) {
            const item = this.interaction.items[0] as InteractionInputItem;
            const option = item.options[0];
            if (option instanceof PronunciationInteractionOption && option.score) {
                return option.score;
            }
        }
        return new NoInteractionScore();
    }

    getMaxScore(): InteractionScore | NoInteractionScore {
        const item = this.interaction.items[0] as InteractionInputItem;
        return (item.options[0] as PronunciationInteractionOption).score || new NoInteractionScore();
    }

    public collectStatistics(responses: ResponseByUser[]): ResponseStatistics[] {

        const inputItem = this.interaction.items[0] as InteractionInputItem;
        const option = inputItem.options[0] as PronunciationInteractionOption;
        const words = option.words.filter(w => w.isEvaluated);

        const stats: ResponseStatistics[] = [];
        words.forEach((word, index) => {
            stats.push({ id: UUID.UUID().slice(0, 8), count: 0, tooltip: word.label, correct: true });
        });

        responses.forEach(userResponse => {
            const response = userResponse.response.items[0];
            if (response && response.asrFeedback && response.asrFeedback.words) {
                response.asrFeedback.words
                    .filter(w => w.isEvaluated)
                    .forEach((item, index) => {
                        if (!item.hasErrors) {
                            stats[index].count++;
                        }
                    });
            }
        });
        return stats;
    }

    public getResponseFeedback(response: InteractionResponse): ResponseFeedbackItem[] {
        const item = response.items[0];
        if (item && item.asrFeedback) {
            const interactionItem = this.interaction.items[0] as InteractionInputItem;
            const pronunciationOption = interactionItem.options[0] as PronunciationInteractionOption;
            return [{ item: AsrFeedback.initFromAsrResult(item.asrFeedback, pronunciationOption), statusCode: 'normal' }];
        }
        return [];
    }
}
