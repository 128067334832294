import { classToPlain, Type } from 'class-transformer';
import { cloneDeep } from 'lodash-es';
import { Background } from './background';
import { Interaction, InteractionInputItem, InteractionItem } from './interaction/interaction';
import { NavigationLink } from './navigation';
import { InteractionBlock, NavigationBlock, SlideBlock } from './slide-block';
import { initConstructor, serializeType } from './utilities';


export type SlideBlockAlignment = 'top' | 'center' | 'bottom';

export type SlideTemplate = { [P in keyof Slide]?: Slide[P] extends Function ? never : Slide[P]; };

export interface SlideModel {
    readonly identifier: string;
    interaction?: Interaction;
    background: Background;
    blocks: SlideBlock[];
    updatedAt?: Date;

    blockAlignment: SlideBlockAlignment;
    nextSlide?: string | null; // Set to null to remove
    label?: string;
    version?: number;
}

/**
 * A slide is a single screen in a section
 */
export class Slide implements SlideModel {
    readonly identifier: string;
    @Type(serializeType(Interaction)) interaction?: Interaction;
    @Type(serializeType(Background)) background: Background;
    @Type(serializeType(SlideBlock)) blocks: SlideBlock[];
    @Type(serializeType(Date)) updatedAt?: Date;

    blockAlignment: SlideBlockAlignment;
    nextSlide?: string | null;
    label?: string;
    version: number;

    static initialize(json: any): Slide {
        let interaction: Interaction | undefined;
        if (json.interaction) {
            interaction = Interaction.initialize(json.interaction);
        }

        let hasInteractionBlock = false;
        const blocks: SlideBlock[] = []; // Remove default content for a new slide
        if (json.blocks) {
            for (const block of json.blocks) {
                const slideBlock = SlideBlock.initialize(block);
                hasInteractionBlock = hasInteractionBlock || slideBlock.type === 'interaction';
                blocks.push(slideBlock);
            }
        }
        if (json.interaction && !hasInteractionBlock) {
            // Savety measure if for some reason there is an interaction, but no interaction block
            // Users could get stuck on such a slide
            blocks.push(new InteractionBlock());
        }

        let background: Background;
        if (json.background) {
            background = Background.initialize(json.background);
        } else {
            background = new Background();
        }

        let updatedAt: Date | undefined;
        if (json.updatedAt != null) {
            updatedAt = new Date(json.updatedAt);
        }

        return new Slide({
            identifier: json.identifier,
            interaction,
            background,
            blocks,
            blockAlignment: json.blockAlignment || 'center',
            label: json.label,
            nextSlide: json.nextSlide,
            updatedAt,
            version: json.version || 1
        });
    }

    static createFromTemplateJSON(template: any, identifier?: string, version?: number, nextSlide?: string): Slide {
        const tmpl = cloneDeep(template);
        tmpl.identifier = identifier;
        tmpl.version = version;
        tmpl.nextSlide = nextSlide;
        const slide = Slide.initialize(tmpl);
        return slide;
    }

    constructor(data: SlideModel) {
        initConstructor<SlideModel>(this, data);
    }

    /**
     * Check if branching can be enabled for this slide.
     */
    allowBranching(): boolean {
        if (!this.interaction) {
            return false;
        }
        // Branching is allowed when there is an interaction with at most 1 InteractionInputItem
        // One set of correct answers is enough as the correct answer can branch of
        // to a different slide as the other (incorrect) answers. The incorrect
        // answers will lead to the default next slide.
        if (this.interaction == null) {
            return false;
        }

        let numInputItems = 0;
        for (const item of this.interaction.items) {
            if (InteractionItem.isInputInteraction(item)) {
                numInputItems++;
            }
        }
        return numInputItems < 2;
    }

    canBeFinalSlide(): boolean {
        // No next slide and the interaction is not a branching interaction
        return (this.nextSlide == null || this.nextSlide.length === 0) && (this.interaction == null || !this.interaction.isBranchingInteraction());
    }

    setDefaultNextSlide(nextSlide: Slide | null) {
        if (nextSlide == null) {
            this.nextSlide = null;
        } else {
            this.nextSlide = nextSlide.identifier;
        }
    }

    getDefaultNextSlide(): string | undefined | null {
        return this.nextSlide;
    }

    getBranchingItem(): InteractionInputItem | undefined {
        if (this.interaction && this.allowBranching() && this.interaction.isBranchingInteraction()) {
            for (const item of this.interaction.items) {
                if (InteractionItem.isInputInteraction(item)) {
                    return item;
                }
            }
        }
    }

    setNextSlideForAnswerSet(nextSlide: Slide | null, set: number): void {
        const item = this.getBranchingItem();
        if (item == null) {
            console.error('Branching is not allowed for slide %s.', this.identifier);
        } else {
            if (set < 0 || set > item.correctResponses.length) {
                console.error('Incorrect answer set specified to set the next slide');
            } else if (nextSlide == null) {
                item.correctResponses[set].nextSlide = undefined;
            } else {
                item.correctResponses[set].nextSlide = nextSlide.identifier;
            }
        }
    }

    getNextSlideForAnswerSet(set: number): string | undefined | null {
        if (this.interaction && this.allowBranching() && this.interaction.isBranchingInteraction()) {
            for (const item of this.interaction.items) {
                if (InteractionItem.isInputInteraction(item)) {
                    if (item.correctResponses.length > set && set >= 0 && item.correctResponses[set].nextSlide) {
                        return item.correctResponses[set].nextSlide;
                    }
                }
            }
        }
        return this.nextSlide;
    }

    get hasNavigationBlock(): boolean {
        return this.blocks.some((block: SlideBlock) => {
            // Do not consider it to be a navigation block if there is only one option
            return block instanceof NavigationBlock && block.links.length > 1;
        });
    }

    get canGoToNextAfterResponse(): boolean {
        if (!this.interaction) { return true; }
        if (!this.nextSlide && (this.interaction.isBranchingInteraction() || this.hasNavigationBlock)) {
            // Disable next if branching or navigation block and there is no next slide defined
            return false;
        } else {
            return true;
        }
    }

    getTemplateJSON(): SlideTemplate {

        const copy: Slide = cloneDeep(this);

        // Remove next slide link
        copy.setDefaultNextSlide(null);

        // Remove branching links
        if (copy.interaction && copy.interaction.isBranchingInteraction()) {
            for (const item of copy.interaction.items) {
                if (item instanceof InteractionInputItem) {
                    for (let set = 0; set < item.correctResponses.length; set++) {
                        copy.setNextSlideForAnswerSet(null, set);
                    }
                }
            }
        }

        // Remove navigation links to slides
        for (let i = 0; i < copy.blocks.length; i++) {
            const block: SlideBlock = copy.blocks[i];
            if ((block as NavigationBlock).links != null) {
                (block as NavigationBlock).links = (block as NavigationBlock).links.map((link: NavigationLink) => {
                    if (link.action === 'goto-slide') {
                        link.target = '';
                    }
                    return link;
                });
            }
        }

        // Remove instance-specific data
        const slideJSON = classToPlain(copy);
        delete slideJSON['objectId'];
        delete slideJSON['identifier'];
        delete slideJSON['updatedAt'];
        delete slideJSON['version'];

        return slideJSON as SlideTemplate;
    }
}
