import { Type } from 'class-transformer';
import { ImageInstance, Media, MediaInstance } from '../media';
import { Json, serializeType } from '../utilities';
import { HotSpot } from './hotspot';
import { InteractionOption } from './option';


export abstract class MatchTarget {
    static initialize(json: Json<MatchTarget>): MatchTarget {
        if (json.type === 'options') {
            return OptionsMatchTarget.initialize(json);
        }
        if (json.type === 'confusion') {
            return ConfusionsMatchTarget.initialize(json);
        }
        return ImageMatchTarget.initialize(json);
    }

    static isConfusionMatch(target?: MatchTarget): target is ConfusionsMatchTarget {
        return target?.type === 'confusion';
    }

    constructor(public readonly type: 'options' | 'image' | 'confusion') {
        this.type = type;
    }
}

export class OptionsMatchTarget extends MatchTarget {
    @Type(serializeType(InteractionOption))
    options: InteractionOption[] = [];
    static initialize(json: any): MatchTarget {
        const options = new OptionsMatchTarget();
        for (const key of Object.keys(options)) {
            if (key === 'options') {
                if (json.options) {
                    for (const option of json.options) {
                        const io = InteractionOption.initialize(option);
                        if (io) { options.options.push(io); }
                    }
                }
            } else {
                options[key] = json[key];
            }
        }
        return options;
    }

    constructor() {
        super('options');
    }
}

export class ConfusionsMatchTarget extends MatchTarget {
    confusionsKey: string;

    static initialize(json: any): MatchTarget {
        const options = new ConfusionsMatchTarget();
        options.confusionsKey = json.confusionsKey;
        return options;
    }

    constructor() {
        super('confusion');
    }
}

export class ImageMatchTarget extends MatchTarget {
    @Type(serializeType(HotSpot)) hotspots: HotSpot[] = [];
    @Type(serializeType(MediaInstance)) background?: ImageInstance = undefined;
    display: 'hidden' // None: hotspot not visible;
        | 'outline' // Outline: the border of the hotspot is visible
        | 'fill' // Fill: the entire hotspot is visible
        | 'icon' // Icon: an icon is displayed in the center of the hotspot
        = 'outline';
    iconUrl?: string = undefined;

    static initialize(json: any): MatchTarget {
        const target = new ImageMatchTarget();
        for (const key of Object.keys(target)) {
            if (key === 'hotspots') {
                if (json.hotspots) {
                    target.hotspots = json.hotspots.map(hs => HotSpot.initialize(hs));
                }
            } else if (key === 'background') {
                if (json.background) {
                    if (json.background['role'] != null) {
                        // backward compatibility
                        const background = Media.initialize(json.background);
                        if (background != null) {
                            target.background = new ImageInstance();
                            target.background.setMedia(background);
                        }
                    } else {
                        target.background = MediaInstance.initialize(json.background) as ImageInstance;
                    }
                }
            } else {
                target[key] = json[key];
            }
        }
        return target;
    }

    constructor() {
        super('image');
    }
}
