import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { languageMap } from '../data';
import { LanguageCode } from '../models/model-types';
import * as SettingsActions from './settings-actions';
import { SetSettings, SetUserLanguage } from './settings-actions';
import { settingsState } from './settings-selectors';
import { AppState } from './state';
import { HYDRATION_INIT } from './state-hydrator/state-hydrator.reducer';


@Injectable()
export class SettingsEffects {

    /**
     * Initialize available languages and set fallback
     */
    @Effect({
        dispatch: false
    })
    initLanguages$ = this.action$.pipe(
        ofType("@ngrx/effects/init"),
        switchMap(_ => {
            this.translate.setDefaultLang('en');
            const supportedUserLanguageCodes: LanguageCode[] = Object.keys(languageMap)
                .map(code => languageMap[code])
                .filter(language => language.supportedUserLanguage)
                .map(language => language.code);
            this.translate.addLangs(supportedUserLanguageCodes);
            return [];
        })
    );

    /**
     * Set user language from local storage or matching browser language
     */
    @Effect()
    initUserLanguage$ = this.action$.pipe(
        ofType(HYDRATION_INIT),
        withLatestFrom(this.store$),
        switchMap(([_, state]) => {
            const lang = settingsState(state).userLanguage || this.getMatchingUserLanguage();
            return [new SetUserLanguage(lang)];

        })
    );

    @Effect({
        dispatch: false
    })
    setSettings$ = this.action$.pipe(
        ofType(SettingsActions.SET_SETTINGS),
        withLatestFrom(this.store$),
        switchMap(([action, storeState]: [SetSettings, AppState]) => {
            this.translate.use(action.settings.userLanguage);
            moment.locale(storeState.settings.userLocale);
            return [];
        })
    );

    @Effect({
        dispatch: false
    })
    updateLanguage$ = this.action$.pipe(
        ofType(SettingsActions.SET_USER_LANGUAGE),
        withLatestFrom(this.store$),
        switchMap(([action, storeState]: [SetUserLanguage, AppState]) => {
            this.translate.use(action.code);
            moment.locale(storeState.settings.userLocale);
            return [];
        })
    );

    constructor(
        private action$: Actions,
        private store$: Store<AppState>,
        private translate: TranslateService
    ) { }

    /**
     * Go through preferred languages and find the first matching one
     */
    private getMatchingUserLanguage(): LanguageCode {
        const prefLangs = window.navigator.languages.map(code => code.toLowerCase());
        const supportedLangs = this.translate.getLangs();

        for (const prefLang of prefLangs) {
            // language sometimes contains a region identifier, check both
            const prefLangPrefix = prefLang.split('-')[0];
            if (supportedLangs.includes(prefLang)) {
                return prefLang as LanguageCode;
            } else if (supportedLangs.includes(prefLangPrefix)) {
                return prefLangPrefix as LanguageCode;
            }
        }
        return 'en';
    }

}
