import { Environment } from '@novo/platform-common/models';

export const environment: Environment = {
    mediaBaseUrl: 'https://novo-content.novo-learning.com/',
    production: true,
    remoteLoggingEnabled: true,
    parseAppId: 'novo',
    parseServerUrl: 'https://platform.novo-learning.com/parse',
    baseHref: 'studio',
    playerUrl: 'https://platform.novo-learning.com/player',
    studioUrl: 'https://platform.novo-learning.com/studio',
    privacyPolicyUrl: 'https://www.novo-learning.com/privacy/1.0.0',
    termsOfServiceUrl: 'https://www.novo-learning.com/terms/1.0.0',
    websiteUrl: 'https://www.novo-learning.com',
    env: 'prod',
    appName: 'studio',
    authApiUrl: 'https://auth-api.novo-learning.com/',
    pronunciationApiUrl: 'https://pronunciation-api.novo-learning.com/',
    speechApiWsUrl: 'wss://speech-api-<language-code>.novo-learning.com/',
    dataApiUrl: 'https://data-api.novo-learning.com/',
};
