import { UUID } from 'angular2-uuid';
import { Json } from '../utilities';

/**
 * A HotSpot defines a region on an image which can be selected by the user.
 * An image should not have overlapping HotSpots.
 */
export abstract class HotSpot {
    readonly id: string;
    readonly type: 'rectangle' | 'circle';

    static initialize(json: Json<HotSpot>): HotSpot {
        if (json.type === 'rectangle') {
            return RectangleHotSpot.initialize(json);
        } else {
            return CircleHotSpot.initialize(json);
        }
    }

    constructor(type: 'rectangle' | 'circle', id: string) {
        this.type = type;
        this.id = id;
    }
}

export class RectangleHotSpot extends HotSpot {
    startX: number;
    startY: number;
    endX: number;
    endY: number;

    public static initialize(json: Json<RectangleHotSpot>): RectangleHotSpot {
        return new RectangleHotSpot(json.id || UUID.UUID().slice(0, 8), json);
    }

    constructor(id: string, position: { startX?: number; startY?: number, endX?: number, endY?: number }) {
        super('rectangle', id);
        this.startX = position.startX || 0;
        this.startY = position.startY || 0;
        this.endX = position.endX || 10;
        this.endY = position.endY || 10;
    }
}

export class CircleHotSpot extends HotSpot {
    x: number;
    y: number;
    radius: number;

    public static initialize(json: Json<CircleHotSpot>): HotSpot {
        return new CircleHotSpot(json.id || UUID.UUID().slice(0, 8), json);
    }

    constructor(id: string, position: { x?: number; y?: number; radius?: number }) {
        super('circle', id);
        this.x = position.x || 0;
        this.y = position.y || 0;
        this.radius = position.radius || 2;
    }
}
