import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Action, ActionReducer, MetaReducer, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Course } from '@novo/platform-common/models';
import { Environment } from '@novo/platform-common/models/environment';
import { MediaFile } from '@novo/platform-common/models/media';
import { ParseAPIModule } from '@novo/platform-common/services/api/parse-api.module';
import { ParseConfig } from '@novo/platform-common/services/api/parse-request';
import { AuthErrorHandlerService } from '@novo/platform-common/services/auth-error-handler/auth-error-handler.service';
import { AuthErrorHandlerStudioService } from '@novo/platform-common/services/auth-error-handler/auth-error-handler.service.studio';
import { AuthenticationReduxStorage } from '@novo/platform-common/services/auth/auth-storage/auth.redux-storage.service';
import { AuthModule } from '@novo/platform-common/services/auth/auth.module';
import { ConsoleErrorLoggerService, ErrorLoggingModule, LoggingDataMiddleware, SentryErrorLoggerService, SENTRY_CONFIG } from '@novo/platform-common/services/error-logger';
import { NovoErrorHandler } from '@novo/platform-common/services/error-logger/error-handler';
import { ParseAuthentication } from '@novo/platform-common/services/parse-authentication';
import { WebStorageConfig } from '@novo/platform-common/services/web-storage-config';
import { authenticationReducer } from '@novo/platform-common/state/auth-reducer';
import { groupReducer } from '@novo/platform-common/state/group-reducer';
import { CommonStateLoggingMiddleware } from '@novo/platform-common/state/logging/state-logging-middleware';
import { SettingsEffects } from '@novo/platform-common/state/settings-effects';
import { settingsReducer } from '@novo/platform-common/state/settings-reducer';
import { AppState } from '@novo/platform-common/state/state';
import { stateHydrator, StateHydratorConfig, StateHydratorUserConfigToken, stateInitializerFactory } from '@novo/platform-common/state/state-hydrator/state-hydrator.reducer';
import { storeFreeze } from '@novo/platform-common/state/storeFreeze';
import { undoReducer } from '@novo/platform-common/state/util/undo.reducer';
import { LoadingBarComponent } from '@novo/platform-studio/loading-bar';
import { TelInputModule } from '@novo/platform-studio/tel-input/tel-input.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import packageJson from '../../package.json';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { WEBSTORAGE_CONFIG } from './app.fns';
import { AppPreloadingStrategy } from './app.preload-strategy';
import { appRoutes } from './app.router';
import { AuthenticationInterceptor } from './features/authentication/authentication.interceptor';
import { CustomRouterStateSerializer } from './state/router-state';


const PARSE_CONFIG: ParseConfig = {
    appId: environment.parseAppId,
    serverUrl: environment.parseServerUrl,
    pollDelay: 2000
};

MediaFile.baseUrl = environment.mediaBaseUrl;

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * Configure states which should be stored and restored between app instances
 */
export const stateHydratorConfig: Partial<StateHydratorConfig<AppState>> = {
    include: ['settings', 'courses'],
    modelSerializers: {
        Course
    },
    storageKey: 'studio-state',
};

export function stateHydratorReducer<T, V extends Action>(reducer: ActionReducer<T, V>): ActionReducer<T, V> {
    return stateHydrator<AppState>(stateHydratorConfig)(reducer);
}

/**
 * MetaReducers for development environment only
 */
export const developmentMetaReducers: MetaReducer<ActionReducer<AppState, Action>>[] = !environment.production ? [storeFreeze] : [];

/**
 * Reducers for all environments
 */
export const metaReducers: MetaReducer<ActionReducer<AppState, Action>>[] = [
    ...developmentMetaReducers,
    undoReducer,
    stateHydratorReducer,
];

@NgModule({
    providers: [
        ParseAuthentication,
        { provide: Environment, useValue: environment },
        { provide: SENTRY_CONFIG, useValue: { dsn: 'https://4ec2c73b0103482782d079979de8a8df@sentry.io/190597', release: `${packageJson.version}-${environment.env}` } },
        { provide: ErrorHandler, useClass: NovoErrorHandler },
        { provide: AuthErrorHandlerService, useClass: AuthErrorHandlerStudioService },
        { provide: WebStorageConfig, useValue: WEBSTORAGE_CONFIG },
        { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
        { provide: LoggingDataMiddleware, useClass: CommonStateLoggingMiddleware, multi: true, deps: [Store] },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
            deps: [Router, Store]
        },
        { provide: StateHydratorUserConfigToken, useValue: stateHydratorConfig },
        { provide: APP_INITIALIZER, useFactory: stateInitializerFactory, multi: true, deps: [Store, StateHydratorUserConfigToken] }
    ],
    declarations: [
        AppComponent,
        LoadingBarComponent
    ],
    imports: [
        ErrorLoggingModule.forRoot({ logger: environment.remoteLoggingEnabled ? SentryErrorLoggerService : ConsoleErrorLoggerService }),
        ParseAPIModule.forRoot(PARSE_CONFIG),
        AuthModule.forRoot({ storage: AuthenticationReduxStorage }),
        CommonModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, {
            enableTracing: false,
            preloadingStrategy: AppPreloadingStrategy,
            paramsInheritanceStrategy: 'always',
        }),
        NgxWebstorageModule.forRoot(WEBSTORAGE_CONFIG),
        StoreModule.forRoot({
            auth: authenticationReducer,
            settings: settingsReducer,
            group: groupReducer
        }, { metaReducers }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        EffectsModule.forRoot([
            SettingsEffects
        ]),
        TelInputModule.forRoot(),
        StoreDevtoolsModule.instrument({ maxAge: 25 }),
        IonicModule.forRoot()
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
