<mat-form-field>
    <mat-label>{{ getLabel('COUNTRY') }}</mat-label>
    <mat-select [ngModel]="selectedCountry" [placeholder]="getLabel('COUNTRY')" (ngModelChange)="onCountrySelect($event)">
        <mat-option *ngFor="let country of preferredCountriesInDropDown" [value]="country">
            <div class="iti-flag" style="display: inline-block; margin-right: 8px;" [ngClass]="country.flagClass"></div>
            <span>{{country.name}}</span>
            <small>+{{country.dialCode}}</small>
        </mat-option>
        <mat-optgroup [label]="getLabel('ALL_COUNTRIES')">
            <mat-option *ngFor="let country of allCountries" [value]="country">
                <div class="iti-flag" style="display: inline-block; margin-right: 8px;" [ngClass]="country.flagClass"></div>
                <span>{{country.name}}</span>
                <small>+{{country.dialCode}}</small>
            </mat-option>
        </mat-optgroup>
    </mat-select>
</mat-form-field>
<mat-form-field style="margin-left: 8px; max-width: 200px;">
    <mat-label>{{ getLabel('PHONE_NUMBER') }}</mat-label>
    <input matInput [placeholder]="getLabel('PHONE_NUMBER')" type="tel" autocomplete="off" (keypress)="onInputKeyPress($event)"
        [(ngModel)]="phoneNumber" (ngModelChange)="onPhoneNumberChange()" />
    <span style="margin-right: 5px;" matPrefix>
        <ng-container *ngIf="selectedCountry.dialCode">+{{selectedCountry.dialCode}}</ng-container>
    </span>
</mat-form-field>
<!--<div *ngIf="formatted" class="formatted-phone-number">{{formatted}}</div>-->
