export class Environment {
    mediaBaseUrl: string;
    production: boolean;
    remoteLoggingEnabled: boolean;
    parseAppId: string;
    parseServerUrl: string;
    versionEndpoint?: string; // version endpoint only necessary for player
    baseHref?: string;
    playerUrl: string;
    studioUrl: string;
    env: 'prod' | 'stage' | 'dev' | 'test' | 'local';
    privacyPolicyUrl: string;
    termsOfServiceUrl: string;
    websiteUrl: string;
    appName: 'player' | 'studio';
    authApiUrl: string;
    pronunciationApiUrl: string;
    speechApiWsUrl: string;
    dataApiUrl: string;
}
