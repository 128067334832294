import { Type } from 'class-transformer';
import { Course } from './course';
import { Section } from './section';
import { LocaleCompare, serializeType } from './utilities';


export class Folder {

    public name = '';
    @Type(serializeType(Folder))
    public subfolders: Folder[] = [];
    public parentId?: string = undefined;
    @Type(serializeType(Section))
    public activities: Section[];
    @Type(serializeType(Course))
    public courses: Course[];

    static initialize(json: any): Folder {
        const result = new Folder(json.identifier, json.groupId);
        result.name = json.name;
        result.parentId = json.parentId;
        if (json.subfolders) {
            result.subfolders = json.subfolders.map(x => Folder.initialize(x)).sort(Folder.sort);
        }
        if (json.courses) {
            result.courses = json.courses.map(x => Course.initialize(x)).sort(Course.sort);
        }
        if (json.activities) {
            result.activities = json.activities.map(x => Section.initialize(x)).sort(Section.sort);
        }
        return result;
    }

    static sort(folderA: Folder, folderB: Folder): number {
        const a = folderA.name;
        const b = folderB.name;
        return LocaleCompare.compare(a, b);
    }

    constructor(public readonly identifier: string, public readonly groupId: string) {
    }

    public createSubFolder(name = 'New Folder'): Folder {
        return Folder.initialize({
            name: name,
            parentId: this.identifier,
            groupId: this.groupId
        });
    }
}
