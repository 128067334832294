import { Action } from '@ngrx/store';
import { MediaFile, UserFlag } from '../models';
import { PermissionMap } from '../models/roles';
import { User } from '../models/user';

export type AuthAction =
    SetAuthentication |
    SetSessionToken |
    ClearAuthentication |
    UpdateUser |
    SetUserPermissions |
    ApprovePolicyDocuments |
    CheckPolicyDocumentApprovals |
    SetUserFlag |
    UpdateProfileImage |
    UpdateCoverImage;

export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export class SetAuthentication implements Action {
    readonly type = SET_AUTHENTICATED_USER;
    constructor(public user: User, public restored = false) { }
}
export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';
export class SetSessionToken implements Action {
    readonly type = SET_SESSION_TOKEN;
    constructor(public sessionToken: string, public restored = false) { }
}

export const CLEAR_AUTHENTICATION = 'CLEAR_AUTHENTICATION';
export class ClearAuthentication implements Action {
    readonly type = CLEAR_AUTHENTICATION;
    constructor() { }
}

export const APPROVE_POLICY_DOCUMENTS = 'APPROVE_POLICY_DOCUMENTS';
export class ApprovePolicyDocuments implements Action {
    readonly type = APPROVE_POLICY_DOCUMENTS;
    constructor(public urls: string[]) { }
}

export const CHECK_POLICY_DOCUMENT_APPROVALS = 'CHECK_POLICY_DOCUMENT_APPROVALS';
export class CheckPolicyDocumentApprovals implements Action {
    readonly type = CHECK_POLICY_DOCUMENT_APPROVALS;
    constructor(public termsUrl: string, public privacyUrl: string) { }
}

export const UPDATE_USER = 'UPDATE_USER';
export class UpdateUser implements Action {
    readonly type = UPDATE_USER;
    constructor(public user: User) { }
}

export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export class SetUserPermissions implements Action {
    readonly type = SET_USER_PERMISSIONS;
    constructor(public permissions: PermissionMap) { }
}

export const SET_USER_FLAG = 'SET_USER_FLAG';
export class SetUserFlag implements Action {
    readonly type = SET_USER_FLAG;
    constructor(readonly flag: UserFlag) { }
}

export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export class UpdateProfileImage implements Action {
    readonly type = UPDATE_PROFILE_IMAGE;
    constructor(readonly file: MediaFile) { }
}

export const UPDATE_COVER_IMAGE = 'UPDATE_COVER_IMAGE';
export class UpdateCoverImage implements Action {
    readonly type = UPDATE_COVER_IMAGE;
    constructor(readonly file: MediaFile) { }
}