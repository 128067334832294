import { InteractionResponse, InteractionResponseItem, InteractionResponseOption } from '../interaction-response';
import { Interaction, InteractionInputItem, InteractionItem } from '../interaction/interaction';
import { TextInteractionOption } from '../interaction/option';
import { InteractionScore, NoInteractionScore } from '../score';
import { BaseInteractionModel, ResponseFeedbackItem } from './base-interaction-model';


export class OpenTextEntryInteractionModel extends BaseInteractionModel {
    private inputItem: InteractionInputItem;
    private minWords?: number;
    private maxWords?: number;

    constructor(interaction: Interaction) {
        super();
        this.inputItem = interaction.items[0] as InteractionInputItem;
        this.minWords = this.inputItem.minResponses;
        this.maxWords = this.inputItem.maxResponses;
    }

    isCorrect(responseTemplate: InteractionResponse): boolean {
        let allCorrect = true;
        if (this.minWords || this.maxWords) {
            const responseItem = responseTemplate.items[0];
            if (responseItem != null && responseItem.options[0].text) {
                const matches = responseItem.options[0].text.match(/\S+/g);
                const words = (matches != null) ? matches.length : 0;
                if (this.minWords && words < this.minWords) {
                    allCorrect = false;
                }
                if (this.maxWords && words > this.maxWords) {
                    allCorrect = false;
                }
            } else {
                allCorrect = false;
            }
        }
        return allCorrect;
    }

    getScore(responseTemplate: InteractionResponse): NoInteractionScore {
        // TODO: assign meaningful score
        return new NoInteractionScore();
    }

    getResponseTemplate(interaction: Interaction): InteractionResponse {
        let userContent = '';
        const item = interaction.items[0];
        if (interaction.display.mode !== 'hidden' && InteractionItem.isInputInteraction(item) && item.options.length > 0) {
            const option = item.options[0];
            if (TextInteractionOption.is(option)) {
                userContent = option.text;
            }
        }

        const rOption = new InteractionResponseOption();
        rOption.text = userContent;
        const rItem = new InteractionResponseItem();
        rItem.options.push(rOption);
        const rResponse = new InteractionResponse();
        rResponse.items.push(rItem);
        return rResponse;
    }

    getMaxScore(): InteractionScore | NoInteractionScore {
        return new NoInteractionScore();
    }

    getFeedbackMessageKey(response: InteractionResponse): string {
        return 'sampleSolution';
    }

    public getResponseFeedback(response: InteractionResponse): ResponseFeedbackItem[] {
        const item = response.items[0];
        if (item && item.options[0].text) {
            return [{ item: item.options[0].text, statusCode: 'normal' }];
        }
        return [];
    }
}
