import * as moment from 'moment';
import { SessionInfo } from '../services/learning-record-store/learning-record-store.interfaces';
import { ActivityPlaySession } from '../state/play/play-interfaces';
import { InteractionResponse } from './interaction-response';
import { SlideHistory } from './slide-history';

export class SessionHistory {
    slideHistory: SlideHistory = new SlideHistory();
    duration: moment.Duration = moment.duration();
    completed = false;
    startTime = new Date();
    version = 0;

    static initialize(json: ActivityPlaySession | SessionInfo): SessionHistory {
        const session = new SessionHistory();
        session.completed = json.completed || false;
        session.duration = moment.duration(json.duration);
        session.version = json.version || 0;

        if (json.startTime != null) {
            session.startTime = new Date(json.startTime);
        }

        if (json.slideHistory != null) {
            // Recreate slide history from visited slides (with some assumptions)
            json.slideHistory.forEach(slideVisit => {
                if (session.slideHistory.currentSlideId !== slideVisit.slideId) {
                    if (session.slideHistory.hasPrevSlides() && session.slideHistory.previousSlideId === slideVisit.slideId) {
                        session.slideHistory = session.slideHistory.goBack();
                    } else if (session.slideHistory.hasNextSlides() && session.slideHistory.nextSlideId === slideVisit.slideId) {
                        session.slideHistory = session.slideHistory.goForward();
                    } else {
                        session.slideHistory = session.slideHistory.goToNewSlide(slideVisit.slideId);
                    }
                }

                slideVisit.actions.forEach(action => {
                    if (action.submit != null) {
                        session.slideHistory = session.slideHistory.setResponse(InteractionResponse.initialize(action.submit));
                    }
                });
            });

            // Start a completed session at the beginning
            if (json.completed) {
                // Include the final slide in the history, so user can forward to the final slide
                session.slideHistory = session.slideHistory.goToNewSlide('final');
                session.slideHistory = session.slideHistory.goBackToFirst();
            }
        }
        return session;
    }

    constructor() { }
}
