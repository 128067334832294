import { Type } from 'class-transformer';
import { Media } from './media';
import { Json, serializeType } from './utilities';

export class Icon {
    @Type(serializeType(Media)) media?: Media = undefined;

    static initialize(json?: Json<Icon>): Icon | undefined {
        if (json?.media) {
            const ic = new Icon();
            ic.media = Media.initialize(json.media);
            return ic;
        }
    }
}
