import { languageMap } from '@novo/platform-common/data';
import { DeviceType, Language, Settings } from '../models';
import { selectedSection } from './section-selectors';
import * as SettingsActions from './settings-actions';
import { SET_DEVICE_ORIENTATION, SET_DEVICE_SCALE, SET_DEVICE_TYPE, SET_SETTINGS, SET_USER_LANGUAGE, SET_USER_LOCALE, SET_VIEW_MODE, TOGGLE_DEVICE_ORIENTATION, TOGGLE_DEVICE_TYPE, TOGGLE_DOWNLOAD_WIFI_ONLY, TOGGLE_SMART_DOWNLOADS, TOGGLE_SOUNDFX } from './settings-actions';
import { AppState } from './state';

export const defaultInitialState: Settings = {
    userLanguage: 'en',
    userLocale: 'en-US',
    deviceScale: 'fit',
    viewMode: 'edit',
    deviceOrientation: 'portrait',
    deviceType: 'desktop',
    soundFxEnabled: true,
    downloadWifiOnly: true,
    smartDownloads: true,
};

export function settingsReducer(state: Settings, action: SettingsActions.SettingsAction): Settings {
    let lang: Language;
    switch (action.type) {
        case SET_SETTINGS:
            lang = languageMap[action.settings.userLanguage];
            return {
                ...action.settings,
                userLocale: (lang) ? lang.defaultLocale : state.userLocale
            };
        case SET_USER_LANGUAGE:
            lang = languageMap[action.code];
            return {
                ...state,
                userLanguage: action.code,
                userLocale: (lang) ? lang.defaultLocale : state.userLocale
            };
        case SET_USER_LOCALE:
            return {
                ...state,
                userLocale: action.code
            };
        case SET_DEVICE_SCALE:
            return {
                ...state,
                deviceScale: action.scale
            };
        case SET_VIEW_MODE:
            return {
                ...state,
                viewMode: action.mode
            };
        case SET_DEVICE_ORIENTATION:
            return {
                ...state,
                deviceOrientation: action.orientation
            };
        case SET_DEVICE_TYPE:
            return {
                ...state,
                deviceType: action.deviceType
            };
        case TOGGLE_SOUNDFX:
            return {
                ...state,
                soundFxEnabled: !state.soundFxEnabled
            };
        case TOGGLE_DEVICE_ORIENTATION:
            return {
                ...state,
                deviceOrientation: (state.deviceOrientation === 'landscape') ? 'portrait' : 'landscape'
            };
        case TOGGLE_DEVICE_TYPE:
            let newType: DeviceType = 'desktop';
            switch (state.deviceType) {
                case 'ipad':
                    newType = 'desktop';
                    break;
                case 'iphone6':
                    newType = 'ipad';
                    break;
                case 'desktop':
                    newType = 'iphone6';
                    break;
            }
            return {
                ...state,
                deviceType: newType
            };
        case TOGGLE_DOWNLOAD_WIFI_ONLY:
            return {
                ...state,
                downloadWifiOnly: !state.downloadWifiOnly
            };
        case TOGGLE_SMART_DOWNLOADS:
            return {
                ...state,
                smartDownloads: !state.smartDownloads
            };
        default:
            return state || defaultInitialState;
    }
}

/**
 * LRS is enabled when there is an activity which is not opened in content
 * editor (not published) or review mode (viewMode)
 *
 * @param state
 */
export const selectLrsEnabled =
    (state: AppState) => selectedSection(state) != null
        // tslint:disable-next-line: no-non-null-assertion
        && selectedSection(state)!.isPublishedVersion
        && state.settings.viewMode === 'play'
        && state.auth.user != null;
