import { UUID } from 'angular2-uuid';
import { Exclude, Type } from 'class-transformer';
import { InternationalText } from './international-text';
import { MediaInstance } from './media';
import { NavigationLink } from './navigation';
import { OverallScore, ScoringDimension } from './score';
import { initialize, serializeType } from './utilities';

export type SlideBlockType = 'interaction' | 'content';

export interface SlideBlockAction {
    type: 'play' | 'pause';
    blockId: string;
}

export interface Margin {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

export interface SlideBlockStyle {
    backgroundColor: string;
    margin: Margin;
    color: string;
    card: boolean;
    pointerEvents?: 'all';
}

export abstract class SlideBlock {
    readonly type: SlideBlockType;
    @Exclude() id: string = UUID.UUID().slice(0, 8);
    style?: SlideBlockStyle = undefined;

    static initialize(json: any): SlideBlock {
        if (json.type === 'interaction') {
            return InteractionBlock.initialize(json);
        } else {
            return ContentBlock.initialize(json);
        }
    }

    constructor(type: SlideBlockType) {
        this.type = type;
    }

    changeId() {
        this.id = UUID.UUID().slice(0, 8);
    }

    get backgroundColor(): string | undefined {
        if (this.style && this.style.card) {
            return this.style.backgroundColor;
        }
    }

    get margin(): Margin {
        if (this.style && this.style.margin) {
            return this.style.margin;
        } else {
            return { top: 0, right: 0, bottom: 0, left: 0 };
        }
    }

    get textColor(): string | undefined {
        if (this.style) {
            return this.style.color;
        }
    }

    get hasEditableStyle(): boolean {
        return true;
    }

    get isImageBlock(): boolean {
        if (this instanceof ContentBlock) {
            return this.media != null && this.media.type === 'image';
        }
        return false;
    }

    get isFullscreen(): boolean {
        if (this instanceof ContentBlock) {
            return this.media != null && this.media.type.indexOf('avatar') >= 0;
        }
        return false;
    }
}

export class ContentBlock extends SlideBlock {
    media?: MediaInstance = undefined;
    @Type(serializeType(InternationalText)) text: InternationalText = new InternationalText();
    showInUserLang = false;

    static initialize(json: any): ContentBlock {

        if (json.links) {
            return NavigationBlock.initialize(json);
        }
        if (json.dimensions) {
            return ScoreBlock.initialize(json);
        }
        // create content, interaction or avatar block based on json
        const result = initialize(ContentBlock, json);
        if (json.media) {
            result.media = MediaInstance.initialize(json.media);
        }

        return result;
    }

    /**
     * Check if the given block is of type content
     *
     * @param block
     */
    static is(block: SlideBlock): block is ContentBlock {
        return block.type === 'content';
    }

    constructor() {
        super('content');
    }
}

export class InteractionBlock extends SlideBlock {
    static initialize(json: any): InteractionBlock {
        return initialize(InteractionBlock, json);
    }

    static is(inst: SlideBlock): inst is InteractionBlock {
        return inst.type == 'interaction';
    }

    constructor() {
        super('interaction');
    }
}

export class NavigationBlock extends ContentBlock {
    links: NavigationLink[] = new Array<NavigationLink>();

    static initialize(json: any): NavigationBlock {
        const block: NavigationBlock = initialize(NavigationBlock, json);
        block.links = json.links.map(link => NavigationLink.initialize(link));
        return block;
    }

    constructor() {
        super();
    }
}

export class ScoreBlock extends ContentBlock {
    // Set from activity scoring settings or define for this block
    @Type(serializeType(ScoringDimension)) dimensions: ScoringDimension[] = [];

    // Set at runtime
    @Type(serializeType(OverallScore)) score?: OverallScore = undefined;

    static initialize(json: any): ScoreBlock {
        return initialize(ScoreBlock, json);
    }

    constructor() {
        super();
    }
}
