import { Routes } from '@angular/router';

export const appRoutes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./features/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: 'organization',
        loadChildren: () => import('./features/organization/organization.module').then(m => m.OrganizationModule),
        data: { parent: 'login' }
    },
    {
        path: 'account-setup',
        loadChildren: () => import('./features/authentication/account-setup/account-setup.module').then(m => m.AccountSetupModule)
    },
    {
        path: 'welcome',
        loadChildren: () => import('./features/authentication/welcome/welcome.module').then(m => m.WelcomeModule)
    },
    {
        path: '',
        redirectTo: 'organization',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'login'
    }
];
