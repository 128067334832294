import { Exclude, Expose, plainToClass, Type } from "class-transformer";
import { initConstructor, serializeType } from "../utilities";

export interface AsrConfidence {
    llr: number;
    prob: number;
}

export class AsrAudioFile {

    @Expose()
    get url(): string | undefined {
        return this._url;
    }
    
    @Exclude()
    private _id: string;

    @Exclude()
    private _url: string;

    static fromURL(url: string): AsrAudioFile {
        const file: AsrAudioFile = new AsrAudioFile();
        // Fix CORS issue
        file._url = url.replace('novolanguage.com', 'novo-learning.com');
        return file;
    }

    static fromId(id: string): AsrAudioFile {
        const file: AsrAudioFile = new AsrAudioFile();
        file._id = id;
        return file;
    }

    static initialize(value: string | { url: string }): AsrAudioFile | undefined {
        if (typeof (value) === 'string') {
            return AsrAudioFile.fromId(value);
        } else if (value.url != null) {
            return AsrAudioFile.fromURL(value.url);
        }
    }

    setUrl(url: string) {
        this._url = url;
    }
}

export interface AsrAudio {
    mp3?: AsrAudioFile; // Legacy LRS input
    ogg?: AsrAudioFile; // Legacy LRS input
    audioKey?: string; // New Speech API
}

export interface AsrRecognized {
    raw: string;
    ipa?: string;
}
export interface AsrRecognizedPhone {
    label: string;
    confidence: AsrConfidence;
}
export interface AsrPhone {
    begin: number;
    end: number;
    label: string;
    confidence: AsrConfidence;
    recognized: AsrRecognizedPhone;
    correct: boolean;
}
interface AsrWordModel {
    begin: number;
    end: number;
    label: AsrRecognized;
    confidence: AsrConfidence;
    phones: AsrPhone[];
    isSpeech: boolean;
    liaison?: AsrPhone;
    nextWordLiaison?: AsrPhone;
}
export class AsrWord implements AsrWordModel {
    begin: number;
    end: number;
    label: AsrRecognized;
    confidence: AsrConfidence;
    phones: AsrPhone[];
    isSpeech: boolean;
    liaison?: AsrPhone;
    nextWordLiaison?: AsrPhone;


    static initialize(json: AsrWordModel) {
        return new AsrWord({ ...json });
    }

    constructor(data: AsrWordModel) {
        initConstructor<AsrWordModel>(this, data);
    }
}

export class AsrResult {
    confidence: AsrConfidence;
    audio: AsrAudio;
    recognized: AsrRecognized;
    words: AsrWord[];
    isConfident?: boolean
    @Expose({ name: 'task_uuid' }) readonly taskUUID: string;
    @Type(serializeType(Date)) receivedAt: Date;

    static initialize(json: any): AsrResult {
        const result = new AsrResult(json.task_uuid);

        result.confidence = json.confidence;
        if (json.audio) {
            result.audio = {
                mp3: json.audio.mp3 && AsrAudioFile.initialize(json.audio.mp3),
                ogg: json.audio.ogg && AsrAudioFile.initialize(json.audio.ogg),
                audioKey: json.audio.audioKey
            };
        }
        result.recognized = json.recognized;
        result.words = json.words ? json.words.map(wordJson => AsrWord.initialize(wordJson)) : undefined;
        if (json.receivedAt == null) {
            result.receivedAt = new Date();
        } else {
            result.receivedAt = new Date(json.receivedAt);
        }
        result.process();
        return result;
    }

    constructor(taskUUID: string) {
        this.taskUUID = taskUUID;
    }

    private isSpeech(s: string): boolean {
        return !(s === '<sil>' || s === '<s>' || s === '</s>' || s === '!sil');
    }

    process() {
        if (this.words) {
            this.words.forEach((w: AsrWord) => {
                w.isSpeech = this.isSpeech(w.label.raw);
            });
        }
    }
}

export class AsrIntermediateResult {
    readonly currentBestPath: string;
    result: AsrResult;
    readonly finalSilenceDuration: string;
    readonly fsmState: string;
    samples: number;
    success: boolean;
    receivedAt: Date;

    public static initialize(json): AsrIntermediateResult {
        const res: AsrIntermediateResult = plainToClass(AsrIntermediateResult, json);
        res.receivedAt = new Date();
        res.result.process();
        return res;
    }
}

