import { createSelector } from '@ngrx/store';
import { languageMap } from '../data';
import { ScriptDirection } from '../models/model-types';
import { Section } from '../models/section';
import { SectionState } from './section-reducer';
import { AppState } from './state';

/**
 * Returns the section state
 */
export const sectionState = (state: AppState): SectionState => state.section;

/**
 * Selector returning current selected section
 */
export const selectedSection = createSelector(
    sectionState,
    (selection: SectionState) => selection && selection.selected
);

/**
 * Returns the error message when loading sections failed
 */
export const loadSectionError = createSelector(
    sectionState,
    (state: SectionState) => !state.loading ? state.errorMsg : undefined
);

export const selectScriptDirection = createSelector(
    selectedSection,
    (section: Section): ScriptDirection => {
        if (section && section.language && languageMap[section.language]) {
            return languageMap[section.language].scriptDirection;
        } else {
            return 'ltr';
        }
    }
);
