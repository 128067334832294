import { AsrResult, AsrWord } from '../asr/asr-result';
import { InteractionResponse, ResponseByUser } from '../interaction-response';
import { HotSpot } from '../interaction/hotspot';
import { Interaction } from '../interaction/interaction';
import { MediaInstance } from '../media';
import { InteractionScore, NoInteractionScore, ScoringDimension } from '../score';

export type StatusCode = 'correct' | 'incorrect' | 'deleted' | 'normal' | 'unknown';

export interface HotSpotResponse { media: MediaInstance; hotspot: HotSpot; feedback?: ResponseFeedbackItem[]; }

export interface ResponseStatistics {
    id: string;
    count: number;
    tooltip?: string | MediaInstance | HotSpotResponse;
    correct?: boolean;
}

export interface ResponseFeedbackItem {
    item: string | MediaInstance | HotSpotResponse | AsrResult | AsrWord;
    statusCode: StatusCode;
}

export abstract class BaseInteractionModel {

    abstract getResponseTemplate(interaction: Interaction): InteractionResponse;
    abstract isCorrect(response: InteractionResponse): boolean;
    abstract getScore(response: InteractionResponse, dimensions?: ScoringDimension[]): InteractionScore | NoInteractionScore;
    abstract getMaxScore(dimensions?: ScoringDimension[]): InteractionScore | NoInteractionScore;

    public getFeedbackMessageKey(response: InteractionResponse): string {
        return response.correct ? 'correct' : 'incorrect';
    }

    public getResponseFeedback(response: InteractionResponse): ResponseFeedbackItem[] {
        return [];
    }

    /**
     * Get statistics about the responses given by the users
     * @param responses Responses given by users
     */
    public collectStatistics(responses: ResponseByUser[]): ResponseStatistics[] | ResponseStatistics[][] {
        return [];
    }

    /**
     * Filter out responses of a previous submission When the interaction response includes those as well
     * @param responses Responses given by users
     */
    public filterPreviousResponses(responses: ResponseByUser[]): ResponseByUser[] {
        return responses;
    }
}
