import { clone, isArray, isObjectLike, uniq } from 'lodash-es';
import { InteractionResponse } from './interaction-response';
import { InteractionItem } from './interaction/interaction';
import { InteractionOption } from './interaction/option';
import { initialize } from './utilities';

export class SlideHistoryRecord<T extends InteractionOption = InteractionOption, I extends InteractionItem = InteractionItem> {
    slideId: string;
    lastResponse?: InteractionResponse;
    initialisationData?: T[] | { items: I[], options: T[] };

    static initialize(json: any): SlideHistoryRecord {
        const result = initialize<SlideHistoryRecord>(SlideHistoryRecord, json);

        result.lastResponse = (json.lastResponse) ? InteractionResponse.initialize(json.lastResponse) : undefined;
        const initData = json.initialisationData;
        if (initData) {
            if (isArray(initData)) {
                result.initialisationData = initData.map(InteractionOption.initialize).filter((i): i is InteractionOption => i != null);
            }
            else if (isObjectLike(initData)) {
                result.initialisationData = {
                    items: initData.items.map(InteractionItem.initialize),
                    options: initData.options.map(InteractionOption.initialize).filter((o): o is InteractionOption => o != null)
                };
            }
        }

        return result;
    }
}

/**
 * Immutable object holding the view and response history of the slides
 */
export class SlideHistory {

    private backwardStack: SlideHistoryRecord[] = [];
    private forwardStack: SlideHistoryRecord[] = [];
    private current?: SlideHistoryRecord = undefined;

    private _allResponses: { [key: string]: InteractionResponse[] } = {};

    static initialize(json: any): SlideHistory {
        let result = new SlideHistory;
        if (json._allResponses) {
            for (let key in json._allResponses) {
                result._allResponses[key] = json._allResponses[key].map(InteractionResponse.initialize);
            }
        }

        result.backwardStack = (json.backwardStack || []).map(SlideHistoryRecord.initialize);
        result.forwardStack = (json.forwardStack || []).map(SlideHistoryRecord.initialize);
        result.current = (json.current) ? SlideHistoryRecord.initialize(json.current) : undefined;

        return result;
    }

    /**
     * Functions which do not modify state
     */
    getSlides(): string[] {
        if (this.current) {
            return uniq([...this.backwardStack.map(s => s.slideId), this.current.slideId, ...this.forwardStack.map(s => s.slideId)]);
        } else {
            return uniq([...this.backwardStack.map(s => s.slideId), ...this.forwardStack.map(s => s.slideId)]);
        }
    }

    get currentSlideRecord(): SlideHistoryRecord | undefined {
        return this.current;
    }

    get currentSlideResponse(): InteractionResponse | undefined {
        return this.current && this.current.lastResponse;
    }

    get allResponses(): { [key: string]: InteractionResponse[] } {
        return this._allResponses;
    }

    hasNextSlides(): boolean {
        return this.forwardStack.length > 0;
    }

    hasPrevSlides(): boolean {
        return this.backwardStack.length > 0;
    }

    get currentSlideId(): string | undefined {
        return this.current && this.current.slideId;
    }

    get previousSlideId(): string | undefined {
        if (!this.hasPrevSlides()) { return undefined; }
        return this.backwardStack[this.backwardStack.length - 1].slideId;
    }

    get nextSlideId(): string | undefined {
        if (!this.hasNextSlides()) { return undefined; }
        return this.forwardStack[this.forwardStack.length - 1].slideId;
    }

    get historyLength(): number {
        return this.backwardStack.length;
    }

    /**
     * Functions returning a new SlideHistory which is modified w.r.t the current slide history
     */

    setResponse(response: InteractionResponse): SlideHistory {
        if (this.current == null) {
            throw new Error('setResponse called while no current slide');
        }
        const newSlideHistory = new SlideHistory();
        newSlideHistory.current = clone(this.current) as SlideHistoryRecord;
        newSlideHistory._allResponses = clone(this._allResponses);
        newSlideHistory.backwardStack = this.backwardStack;
        newSlideHistory.forwardStack = this.forwardStack;

        newSlideHistory.current.lastResponse = response;
        if (response.complete) {
            if (newSlideHistory._allResponses[this.current.slideId] == null) {
                newSlideHistory._allResponses[this.current.slideId] = [];
            } else {
                newSlideHistory._allResponses[this.current.slideId] = clone(this._allResponses[this.current.slideId]);
            }
            newSlideHistory._allResponses[this.current.slideId].push(response);
        }
        return newSlideHistory;
    }

    setInitialisationData(data: any): SlideHistory {
        if (this.current == null) {
            throw new Error('setInitialisationData called while no current slide');
        }
        const newSlideHistory = new SlideHistory();
        newSlideHistory.current = clone(this.current) as SlideHistoryRecord;
        newSlideHistory._allResponses = this._allResponses;
        newSlideHistory.backwardStack = this.backwardStack;
        newSlideHistory.forwardStack = this.forwardStack;

        newSlideHistory.current.initialisationData = data;
        return newSlideHistory;
    }


    clearForwardStack(nextSlideId?: string): SlideHistory {
        const newSlideHistory = new SlideHistory();
        newSlideHistory._allResponses = this._allResponses;
        newSlideHistory.forwardStack = [];

        if (nextSlideId == null) {
            newSlideHistory.current = this.current;
            newSlideHistory.backwardStack = this.backwardStack;
        } else {
            newSlideHistory.backwardStack = clone(this.backwardStack);
            if (this.current) {
                newSlideHistory.backwardStack.push(this.current);
            }
            newSlideHistory.current = { slideId: nextSlideId };
        }

        return newSlideHistory;
    }

    goToNewSlide(slideId: string): SlideHistory {
        const newSlideHistory = new SlideHistory();
        newSlideHistory._allResponses = this._allResponses;
        newSlideHistory.backwardStack = clone(this.backwardStack);
        newSlideHistory.forwardStack = this.forwardStack;
        newSlideHistory.current = { slideId: slideId };

        if (this.current) { newSlideHistory.backwardStack.push(this.current); }
        return newSlideHistory;
    }

    goForward(): SlideHistory {
        if (!this.hasNextSlides()) { return this; }

        const newSlideHistory = new SlideHistory();
        newSlideHistory._allResponses = this._allResponses;
        newSlideHistory.backwardStack = clone(this.backwardStack);
        newSlideHistory.forwardStack = clone(this.forwardStack);

        if (this.current) { newSlideHistory.backwardStack.push(this.current); }
        newSlideHistory.current = newSlideHistory.forwardStack.pop();
        return newSlideHistory;
    }

    goBack(): SlideHistory {
        if (!this.hasPrevSlides()) { return this; }

        const newSlideHistory = new SlideHistory();
        newSlideHistory._allResponses = this._allResponses;
        newSlideHistory.backwardStack = clone(this.backwardStack);
        newSlideHistory.forwardStack = clone(this.forwardStack);

        if (this.current) { newSlideHistory.forwardStack.push(this.current); }
        newSlideHistory.current = newSlideHistory.backwardStack.pop();
        return newSlideHistory;
    }

    /**
     * Go back to the first slide in the history.
     * Ensure the history can be replayed
     */
    goBackToFirst(): SlideHistory {
        if (!this.hasPrevSlides()) { return this; }

        const newSlideHistory = new SlideHistory;
        newSlideHistory._allResponses = this._allResponses;
        newSlideHistory.backwardStack = [];
        newSlideHistory.forwardStack = clone(this.forwardStack);

        if (this.current) { newSlideHistory.forwardStack.push(this.current); }

        newSlideHistory.forwardStack = newSlideHistory.forwardStack.concat(clone(this.backwardStack).reverse());
        newSlideHistory.current = newSlideHistory.forwardStack.pop();

        return newSlideHistory;
    }
}
