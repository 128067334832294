import { InteractionResponseItem } from '../interaction-response';
import { CorrectResponseOption, Interaction, InteractionInputItem } from '../interaction/interaction';
import { TextInteractionOption } from '../interaction/option';
import { InlineInteractionModel } from './inline-interaction-model';


export class InlineChoiceInteractionModel extends InlineInteractionModel {

    constructor(interaction: Interaction) {
        super(interaction);
    }

    isItemCorrect(item: InteractionInputItem, responseItem: InteractionResponseItem): boolean {
        const correctAnswers = item.correctResponses.map(cr => cr.values[0].source);
        return correctAnswers.indexOf(responseItem.options[0].source) >= 0;
    }

    getCorrectResponse(item: InteractionInputItem, responseItem: InteractionResponseItem): CorrectResponseOption | undefined {
        return item.correctResponses.find(cr => cr.values[0].source === responseItem.options[0].source);
    }

    getTextOptions(item: InteractionInputItem): { id: string, text: string, correct: boolean }[] {
        return item.options.map((option: TextInteractionOption) => {
            let correct = item.correctResponses.find(cr => cr.values.findIndex(val => val.source === option.id) >= 0) != null;
            return { id: option.id, text: option.text, correct };
        });
    }

    getResponseOption(rItem: InteractionResponseItem, idx: string): string {
        return rItem.options[0].source || 'missed';
    }

    getMissingIncorrectOption(id: string): string {
        return 'Unknown option:' + id;
    }

    getAllTextInteractionOptions(): { [key: string]: TextInteractionOption } {
        const allOptions: { [key: string]: TextInteractionOption } = {};
        this.items.forEach(item => {
            if (item instanceof InteractionInputItem) {
                item.options.forEach((option: TextInteractionOption) => allOptions[option.id] = option);
            }
        });
        return allOptions;
    }
}
