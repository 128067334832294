import { Type } from 'class-transformer';
import { InternationalText } from './international-text';
import { LanguageCode } from './model-types';
import { initialize, serializeType } from './utilities';

export type NavigationLinkAction = 'goto-slide' | 'retry-section' | 'goto-section' | 'quit-section' | 'open-external-url';

export class NavigationLink {
    @Type(serializeType(InternationalText)) description = new InternationalText();
    target = '';
    action: NavigationLinkAction = 'goto-slide';
    isPrimary = true;

    static initialize(json: any): NavigationLink {
        return initialize(NavigationLink, json);
    }

    toLocalizedLink(lang: LanguageCode): LocalizedNavigationLink {
        return {
            target: this.target,
            isPrimary: this.isPrimary,
            action: this.action,
            label: this.description.translation[lang]
        };
    }
}

export interface LocalizedNavigationLink {
    action?: NavigationLinkAction;
    label?: string;
    target?: string;
    isPrimary?: boolean;
}
