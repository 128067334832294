import { Component, OnDestroy } from '@angular/core';

@Component({
    selector: 'loading-bar',
    template: `
        <div class="progress"
             [style.width]="progressStyle"
             [ngClass]="{'started': progress < 100, 'completed': progress == 100}">
        </div>
    `,
    styles: [`
       .progress {
           height: 2px;
           background-color: red;
           transition: opacity 300ms ease;
           width: 0%;
           opacity: 0;
       }

       .progress.completed {
           opacity: 0;
       }

       .progress.started {
           opacity: 1;
       }
    `]
})
export class LoadingBarComponent implements OnDestroy {

    progress = 0;
    completed = false;
    started = false;
    progressStyle = '0%';
    raf;

    constructor() {
    }

    start() {
        this.completed = false;
        this.started = false;
        this.setProgress(0);
        setTimeout(() => {
            if (!this.completed) {
                this.started = true;
                this.raf = requestAnimationFrame(this.update);
            }
        }, 100); // Only start timer after a minimum time of 100ms

    }

    update = () => {
        if (this.progress < 100) {
            this.setProgress(this.progress + 5);
            this.raf = requestAnimationFrame(this.update);
        }
        else {
            this.cleanup();
        }
    }

    setProgress(p: number) {
        this.progress = p;
        this.progressStyle = `${this.progress}%`;
    }

    complete() {
        this.completed = true;
        if (this.started) {
            this.setProgress(100);
        }
    }

    cleanup() {
        if (this.raf) {
            cancelAnimationFrame(this.raf);
        }
    }

    ngOnDestroy() {
        this.cleanup();
    }


}
