import { Dictionary } from './dictionary';
import { ScoringDimensionBin } from './score';
/**
 * Get the matching bin based for a dimension score
 */
export const getMatchingBin = (score: number, bins: ScoringDimensionBin[]): ScoringDimensionBin => {
    let matchingBin: ScoringDimensionBin = bins[0];
    bins.forEach((bin: ScoringDimensionBin) => {
        if (score >= bin.minScore) { matchingBin = bin; }
    });
    return matchingBin;
};

/**
 * Get the lowest passing score for a dimension
 * @param bins
 * @param passingBin
 */
export const getLowestPassingScore = (bins: ScoringDimensionBin[], passingBin: number): number => {
    return bins[passingBin].minScore;
};

/**
 * Get the percent score for a dimension
 */
export const getDimensionPercScore = (score: Dictionary<{ count: number, total: number }>, dimId: string): number => {
    const dimScore = score[dimId];
    if (dimScore == null || dimScore.total === 0) { return 0; }
    return dimScore.count / dimScore.total;
};
