import { Interaction } from '../interaction/interaction';
import { BaseInteractionModel } from './base-interaction-model';
import { BranchingInteractionModel } from './branching-interaction-model';
import { ChoiceInteractionModel } from './choice-interaction-model';
import { ConversationInteractionModel } from './conversation-interaction-model';
import { HotSpotMatchTextInteractionModel } from './hot-spot-match-text-interaction-model';
import { HotSpotNavigationInteractionModel } from './hot-spot-navigation-interaction-model';
import { InlineChoiceInteractionModel } from './inline-choice-interaction-model';
import { InlineMatchTextInteractionModel } from './inline-match-text-interaction-model';
import { InlineTextEntryInteractionModel } from './inline-text-entry-interaction-model';
import { OpenTextEntryInteractionModel } from './open-text-entry-interaction-model';
import { OrderInteractionModel } from './order-interaction-model';
import { PronunciationInteractionModel } from './pronunciation-interaction-model';
import { TextEntrySetInteractionModel } from './text-entry-set-interaction-model';

export class InteractionModelFactory {
    static getInteractionModel(interaction: Interaction): BaseInteractionModel {
        if (interaction.type.startsWith('branching')) {
            return new BranchingInteractionModel(interaction);
        }
        if (interaction.type.startsWith('choice')) {
            return new ChoiceInteractionModel(interaction);
        }
        if (interaction.type.startsWith('order')) {
            return new OrderInteractionModel(interaction);
        }
        if (interaction.type === 'pronunciation') {
            return new PronunciationInteractionModel(interaction);
        }
        if (interaction.type === 'inline-match-text') {
            return new InlineMatchTextInteractionModel(interaction);
        }
        if (interaction.type === 'inline-choice-text') {
            return new InlineChoiceInteractionModel(interaction);
        }
        if (interaction.type === 'inline-text-entry') {
            return new InlineTextEntryInteractionModel(interaction);
        }
        if (interaction.type.startsWith('open-text-entry')) {
            return new OpenTextEntryInteractionModel(interaction);
        }
        if (interaction.type.startsWith('text-entry-set')) {
            return new TextEntrySetInteractionModel(interaction);
        }
        if (interaction.type.startsWith('hot-spot-match-text')) {
            return new HotSpotMatchTextInteractionModel(interaction);
        }
        if (interaction.type.startsWith('hot-spot-navigation')) {
            return new HotSpotNavigationInteractionModel(interaction);
        }
        if (interaction.type.startsWith('conversation')) {
            return new ConversationInteractionModel(interaction);
        }
        throw Error('Unknown interaction type encountered: ' + interaction.type);
    }
}
