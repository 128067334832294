import { cloneDeep } from 'lodash-es';
import { InteractionResponse, InteractionResponseItem, InteractionResponseOption, ResponseByUser } from '../interaction-response';
import { CorrectResponseOption, Interaction, InteractionInputItem, InteractionItem } from '../interaction/interaction';
import { TextInteractionOption } from '../interaction/option';
import { InteractionScore, NoInteractionScore } from '../score';
import { StringEqualityOptions } from '../utilities';
import { BaseInteractionModel, ResponseFeedbackItem, ResponseStatistics } from './base-interaction-model';


export abstract class InlineInteractionModel extends BaseInteractionModel {

    protected static readonly equalOptions: StringEqualityOptions = {
        ignoreCase: true,
        ignorePunctuation: false,
        ignoreExtraneousWhitespace: true,
        ignoreWhitespaceBeforePunctuation: true
    };

    protected items: InteractionItem[];

    constructor(interaction: Interaction) {
        super();
        this.items = cloneDeep(interaction.items);
    }

    protected abstract isItemCorrect(item: InteractionInputItem, responseItem: InteractionResponseItem): boolean;
    protected abstract getCorrectResponse(item: InteractionInputItem, responseItem: InteractionResponseItem): CorrectResponseOption | undefined;

    protected abstract getTextOptions(item: InteractionInputItem): { id: string, text: string, correct: boolean }[];

    isCorrect(responseTemplate: InteractionResponse): boolean {
        let allCorrect = true;
        this.items.forEach((item, index) => {
            if (InteractionInputItem.is(item)) {
                const responseItem = responseTemplate.items[index];
                if (responseItem == null) { return; }

                if (!this.isItemCorrect(item, responseItem)) {
                    allCorrect = false;
                    responseItem.correct = false;
                } else {
                    responseItem.correct = true;
                }
            }
        });
        return allCorrect;
    }

    getScore(responseTemplate: InteractionResponse): InteractionScore | NoInteractionScore {
        let score: InteractionScore | NoInteractionScore = new NoInteractionScore();
        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            if (item instanceof InteractionInputItem) {
                const responseItem = responseTemplate.items[i];
                if (responseItem == null) { continue; }

                const correctResponse = this.getCorrectResponse(item, responseItem);
                if (correctResponse && correctResponse.score) {
                    score = score.add(correctResponse.score);
                }
            }
        }
        return score;
    }

    getMaxScore(): InteractionScore | NoInteractionScore {
        let score: InteractionScore | NoInteractionScore = new NoInteractionScore();
        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            if (item instanceof InteractionInputItem) {
                const maxItemScore = item.correctResponses.reduce(
                    (prev: InteractionScore | NoInteractionScore, curr) => curr.score ? InteractionScore.max(prev, curr.score) : prev, new NoInteractionScore()
                );
                score = score.add(maxItemScore);
            }
        }
        return score;
    }

    getResponseTemplate(interaction: Interaction): InteractionResponse {
        const rResponse = new InteractionResponse();
        if (this.items) {
            this.items.forEach(item => {
                if (item instanceof InteractionInputItem) {
                    const iri = new InteractionResponseItem();
                    iri.options.push(new InteractionResponseOption());
                    rResponse.items.push(iri);
                } else {
                    rResponse.items.push(undefined);
                }
            });
        }

        return rResponse;
    }

    protected abstract getResponseOption(rItem: InteractionResponseItem, idx: string): string;

    protected abstract getMissingIncorrectOption(id: string): string;

    public collectStatistics(responses: ResponseByUser[]): ResponseStatistics[][] {
        const stats: { [key: string]: ResponseStatistics }[] = [];

        const items = this.items.filter((item): item is InteractionInputItem => item instanceof InteractionInputItem);

        // Initialize statistics for known (text) options
        items.forEach((inputItem, idx) => {
            stats[idx] = { ['missed']: { id: 'missed', count: 0, tooltip: undefined, correct: false } };
            this.getTextOptions(inputItem).forEach(({ id, text, correct }) => (stats[idx][id] = { id, count: 0, tooltip: text, correct }))
        });

        responses.forEach(userResponse => {
            const response = userResponse.response;
            response.items
                .filter(r => r instanceof InteractionResponseItem)
                .forEach((rItem, idx) => {
                    let selectedId: string;
                    if (rItem == null || rItem.options.length === 0) {
                        selectedId = 'missed';
                    } else {
                        selectedId = this.getResponseOption(rItem, items[idx].identifier);
                    }

                    if (stats[idx][selectedId] == null) {
                        const missing = this.getMissingIncorrectOption(selectedId);
                        stats[idx][selectedId] = { id: selectedId, count: 1, tooltip: missing, correct: false };
                    } else {
                        stats[idx][selectedId].count++;
                    }
                });
        });
        return stats.map(s => Object.keys(s).map(key => s[key]));
    }

    protected abstract getAllTextInteractionOptions(): { [key: string]: TextInteractionOption }

    public getResponseFeedback(response: InteractionResponse): ResponseFeedbackItem[] {
        const result: ResponseFeedbackItem[] = [];

        const allOptions = this.getAllTextInteractionOptions();

        this.items.forEach((item, index) => {
            if (item instanceof InteractionInputItem) {
                const responseItem = response.items[index];
                const correct = responseItem != null && this.isItemCorrect(item, responseItem);
                const selectedId = responseItem != null && this.getResponseOption(responseItem, item.identifier);
                const chosenOption = selectedId && allOptions[selectedId];

                if (!correct || !chosenOption) {
                    if (chosenOption) {
                        result.push({ item: chosenOption.text, statusCode: 'incorrect' });
                    } else {
                        result.push({ item: selectedId || '&lt;no answer given&gt;', statusCode: 'incorrect' });
                    }
                    if (item.correctResponses.length === 1 && item.correctResponses[0].values.length === 1) {
                        const correct = item.correctResponses[0].values[0] as TextInteractionOption;
                        result.push({ item: correct.text, statusCode: 'deleted' });
                    }
                } else {
                    result.push({ item: chosenOption.text, statusCode: 'correct' });
                }
            }
        });
        return result;
    }
}
