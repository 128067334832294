import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { classToPlain } from 'class-transformer';
import { mapValues } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Course, Dictionary, Group, GroupModel, User, UserFlag } from '../models';
import { Section } from '../models/section';
import { New, semVerUrlEquals } from '../models/utilities';
import { UpdateUser } from '../state/auth-actions';
import { AppState } from '../state/state';
import { ParseRequest } from './api/parse-request';

export interface ListGroupUserContent { details?: boolean; permissions?: boolean; sort?: { dir: 'asc' | 'desc', field: 'username' | 'groupName' | 'fullName' | 'lastLoginAt' }; count?: boolean; }
export interface ListGroupUserFilter { active?: boolean; pageSize?: number; offset?: number; subgroups: boolean; name?: string; roleId?: string; }


@Injectable()
export class ParseAuthentication {

    constructor(
        private state: Store<AppState>,
        private parseRequest: ParseRequest,
    ) {
    }

    async runCloudFunction(name: string, data: {}, progress$?: Subject<number>): Promise<any> {
        return this.parseRequest.runCloudFunction$(name, data, { progress$ }).toPromise();
    }

    runCloudFunction$<T>(name: string, data: {}, progress$?: Subject<number>): Observable<T> {
        return this.parseRequest.runCloudFunction$<{}, T>(name, data, { progress$ });
    }

    private get currentUser(): User {
        let user: User;
        // Synchronously get the last version
        this.state.select(s => s.auth.user).pipe(take(1)).subscribe(u => user = u);
        return user;
    }

    listGroupUsers$(group: Group, filter: ListGroupUserFilter, retrieve: ListGroupUserContent): Observable<{
        users: User[], permissions: Dictionary<{ [roleName: string]: string[] }>, count?: number, roles: { name: string, id: string }[]
    }> {
        return this.runCloudFunction$<{ users: {}[], permissions: Dictionary<{ [roleName: string]: string[] }>, count: number, roles: { name: string, id: string }[] }>('listGroupUsers', { groupId: group.identifier, filter, retrieve }).pipe(
            map(response => {
                const result: { users: User[], permissions: Dictionary<{ [roleName: string]: string[] }>, count?: number, roles: { name: string, id: string }[] } = { users: [], permissions: {}, roles: [] };
                result.users = response.users.map(u => User.initialize(u));
                result.permissions = response.permissions;
                if ('count' in response) { result.count = response.count; }
                result.roles = response.roles;
                return result;
            })
        );
    }

    async addUser(user: User): Promise<User> {
        const json = await this.runCloudFunction('createUser', classToPlain(user));
        return User.initialize(json);
    }

    async isUniqueUsernameOrEmail(username: string, email: string): Promise<boolean> {
        const isUnique: boolean = await this.runCloudFunction('checkUniqueUsernameOrEmail', { username, email });
        return isUnique;
    }

    async checkUniqueEmails(emails: string[]): Promise<{ [key: string]: boolean }> {
        return await this.runCloudFunction('checkUniqueEmails', { emails });
    }

    async getUser(userId: string, includeDetails = false): Promise<User> {
        const json = await this.runCloudFunction('getUser', { userId, includeDetails });
        return User.initialize(json);
    }

    async storeUser(user: User): Promise<User> {
        const userJSON = classToPlain(user);
        const json = await this.runCloudFunction('saveUser', userJSON);
        return User.initialize(json);
    }

    async addGroup(parentId: string, groupJSON: New<GroupModel>): Promise<Group> {
        const json = await this.runCloudFunction('addGroup', { parentId: parentId, group: groupJSON });
        return Group.initialize(json);
    }

    async storeGroup(group: Group): Promise<Group> {
        const groupJSON = classToPlain(group);
        delete groupJSON['subgroupIds'];

        const json = await this.runCloudFunction('saveGroup', groupJSON);
        return Group.initialize(json);
    }

    removeGroup$(groupId: string): Observable<void> {
        return this.runCloudFunction$('removeGroup', { groupId: groupId });
    }

    async updateCurrentUser(user: User): Promise<void> {
        await this.storeUser(user);
        this.state.dispatch(new UpdateUser(user));
    }

    async sendSupportEmail(
        message: string,
        deviceInfo: string,
        appInfo: string,
        extraContext: Dictionary<any>,
    ): Promise<void> {
        await this.runCloudFunction('sendSupportEmail',
            { ...extraContext, message, deviceInfo, appInfo }
        );
    }

    async getWistiaToken(group: Group, courseOrSection: Course | Section): Promise<string> {
        const data = { groupId: group.identifier };
        if (courseOrSection instanceof Course) {
            data['courseId'] = courseOrSection.identifier;
        } else if (courseOrSection instanceof Section) {
            data['sectionId'] = courseOrSection.identifier;
        }
        return await this.runCloudFunction('getWistiaToken', data);
    }

    async getDetailsBySetupToken(token: string): Promise<{ user?: User, group: Group, limitReachedFor?: string }> {
        const json = await this.runCloudFunction('getDetailsBySetupToken', { token: token });
        return {
            user: json.user && User.initialize(json.user),
            group: json.group && Group.initialize(json.group),
            limitReachedFor: json.seatLimitReached
        };
    }

    async checkDocumentApprovals(urls: string[]): Promise<boolean> {
        if (this.currentUser &&
            this.currentUser.approvedDocuments &&
            urls.every(url => this.currentUser.approvedDocuments.find((doc: string) => semVerUrlEquals(url, doc, 'major')) != null)) {
            return true;
        } else {
            return await this.runCloudFunction('checkDocumentApprovals', { urls });
        }
    }

    async approveDocuments(urls: string[]): Promise<User> {
        const json = await this.runCloudFunction('approveDocuments', { urls });
        return User.initialize(json);
    }

    async isUserFlagSet(flag: string): Promise<boolean> {
        if (this.currentUser &&
            this.currentUser.flags &&
            this.currentUser.flags[flag]) {
            return true;
        } else {
            return await this.runCloudFunction('isUserFlagSet', { flag });
        }
    }

    async setUserFlag(flag: UserFlag): Promise<User> {
        const json = await this.runCloudFunction('setUserFlag', { flag });
        return User.initialize(json);
    }

    /**
     * Sets up user account
     * @param user
     * @param token
     * @param approvedDocumentUrls
     */
    async setupUserAccount(userJSON: User, token: string, approvedDocumentUrls: string[] = []): Promise<void> {
        await this.runCloudFunction('setupUserAccount', {
            user: classToPlain(userJSON),
            token: token,
            approvedDocumentUrls
        });
    }

    async resendAccountSetupEmail(user: User): Promise<void> {
        const userJSON = classToPlain(user);
        await this.runCloudFunction('resendAccountSetupEmail', userJSON);
    }

    async generateUserNames(users: { givenName: string, familyName: string, email: string }[], exclude: string[]): Promise<string[]> {
        return await this.runCloudFunction('generateUserNames', { users, exclude });
    }

    async getUsers(userIds: string[]): Promise<{ [userId: string]: User }> {
        return mapValues(await this.runCloudFunction('getUserMap', { userIds: userIds }),
            user => User.initialize(user));
    }
    
    async deleteUserAccount(userId: string): Promise<void> {
        return this.runCloudFunction('deleteUser', { userId });
    }
}

