import { MediaInteractionOption, notEmpty, StatusCode, TextInteractionOption } from '..';
import { decisionCode, indmin, WordErrorRate } from '../../util/word-error-rate';
import { InteractionResponse, InteractionResponseItem } from '../interaction-response';
import { Interaction, InteractionInputItem, InteractionType } from '../interaction/interaction';
import { MediaInstance } from '../media';
import { InteractionScore, NoInteractionScore } from '../score';
import { BaseInteractionModel, ResponseFeedbackItem } from './base-interaction-model';

export class OrderInteractionModel extends BaseInteractionModel {
    private interaction: Interaction;

    constructor(interaction: Interaction) {
        super();
        this.interaction = interaction;
    }

    getResponseTemplate(interaction: Interaction): InteractionResponse {
        const ir = new InteractionResponse();
        ir.items.push(new InteractionResponseItem());
        return ir;
    }

    isCorrect(responseTemplate: InteractionResponse): boolean {
        const responseItem = responseTemplate.items[0];
        if (responseItem == null) { return false; }

        const response = responseItem.options.map(o => o.source).filter(notEmpty);
        const correctResponses: string[][] = [];
        const item = this.interaction.items[0];
        for (const resp of (item as InteractionInputItem).correctResponses) {
            const correctResponse = resp.values.map(value => value.source).filter(notEmpty);
            correctResponses.push(correctResponse);
        }
        const options: TextInteractionOption[] = (item as InteractionInputItem).options as TextInteractionOption[];
        return correctResponses.some(x => this.matches(response, x, options, this.interaction.type));
    }

    /** Matches a given response with a correct response
     * - For text order exercises the option TEXTS are used to match
     * - For other exercises the option IDs are used to match
     */
    matches(response: string[], correctResponse: string[], options: TextInteractionOption[], type: InteractionType): boolean {
        switch (type) {
            case 'order-text':
                const optionMap = options.reduce((dict, o) => {
                    dict[o.id] = o.text;
                    return dict;
                }, {} as { [optionId: string]: string });
                return JSON.stringify(response.map(id => optionMap[id])) === JSON.stringify(correctResponse.map(id => optionMap[id]));
            case 'order-graphic':
            default:
                return JSON.stringify(response) === JSON.stringify(correctResponse);
        }
    }

    getScore(responseTemplate: InteractionResponse): InteractionScore | NoInteractionScore {
        const responseItem = responseTemplate.items[0];
        if (responseItem == null) { return new NoInteractionScore(); }

        const response = responseItem.options.map(o => o.source).filter(notEmpty);
        const item = this.interaction.items[0] as InteractionInputItem;
        const options: TextInteractionOption[] = (item as InteractionInputItem).options as TextInteractionOption[];
        const correctResponse = item.correctResponses.find(cr => {
            const cresp: string[] = cr.values.map(value => value.source).filter(notEmpty);
            return this.matches(response, cresp, options, this.interaction.type);
        });
        return (correctResponse && correctResponse.score) || new NoInteractionScore();
    }

    getMaxScore(): InteractionScore | NoInteractionScore {
        const inputItem = (this.interaction.items[0]) as InteractionInputItem;
        return inputItem.correctResponses.reduce(
            (prev: InteractionScore | NoInteractionScore, curr) => curr.score ? InteractionScore.max(prev, curr.score) : prev, new NoInteractionScore()
        );
    }

    public getResponseFeedback(response: InteractionResponse): ResponseFeedbackItem[] {
        const responseItem = response.items[0];
        if (responseItem == null) { return []; }

        const interactionItem = this.interaction.items[0] as InteractionInputItem;

        const correctOrderOptions = interactionItem.correctResponses.map(r => r.values.map(v => v.source).filter(notEmpty));
        const optionMap: { [key: string]: string | MediaInstance } = {};
        (this.interaction.items[0] as InteractionInputItem).options.forEach(option => {
            if (option instanceof TextInteractionOption) {
                optionMap[option.id] = option.text;
            } else if (option instanceof MediaInteractionOption) {
                optionMap[option.id] = option.media!;
            }
        });

        const optionIds = responseItem.options.map(option => option.source).filter(notEmpty);
        const wers = correctOrderOptions.map(order => new WordErrorRate(order, optionIds));
        const wer = wers[indmin(wers.map(w => w.wer)).ind];

        return wer.align.evalali.map((e, index) => {
            if (e === decisionCode.INSERTION) {
                return {
                    item: optionMap[wer.hyp[wer.align.hypali[index]]],
                    statusCode: 'incorrect' as StatusCode
                };
            } else if (e === decisionCode.DELETION) {
                return {
                    item: optionMap[wer.ref[wer.align.refali[index]]],
                    statusCode: 'deleted' as StatusCode
                };
            } else {
                const correct = e === decisionCode.CORRECT;
                return {
                    item: optionMap[wer.hyp[wer.align.hypali[index]]],
                    statusCode: correct ? 'correct' : 'incorrect' as StatusCode
                };
            }
        });
    }
}
