import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppMaterialModule } from '@novo/platform-studio/app.material.module';
import { TelInputComponent } from './tel-input.component';
import { TelInputService } from './tel-input.service';

export * from './tel-input.component';
export * from './tel-input.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AppMaterialModule,
        TranslateModule.forChild()
    ],
    declarations: [
        TelInputComponent
    ],
    exports: [
        TelInputComponent
    ]
})
export class TelInputModule {
    static forRoot(): ModuleWithProviders<TelInputModule> {
        return {
            ngModule: TelInputModule,
            providers: [TelInputService]
        };
    }
}
