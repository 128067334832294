import { createSelector, Store } from '@ngrx/store';
import { OverallScore, Section, SlideHistory } from '@novo/platform-common/models';
import { UserAssignment } from '@novo/platform-common/models/user-assignment';
import { ActivityGraph } from '@novo/platform-common/services/activity/activity-graph';
import { selectAllUserAssignments } from '@novo/platform-common/state/user-assignment-status-reducer';
import { Observable } from 'rxjs';
import { filter, mapTo, pairwise, shareReplay, startWith, take } from 'rxjs/operators';
import { selectedSection } from '../section-selectors';
import { SessionsState } from '../sessions/sessions.interface';
import { selectSessionsState } from '../sessions/sessions.selectors';
import { ActivitySessionContext, AppState, RouterState, UserAssignmentsStatusState } from '../state';
import { UNKNOWN_SESSION_ID } from './play-constants';
import { ActivityPlaySession, ActivityPlayState, ActivityPlayStateInstance } from './play-interfaces';
import { getCurrentScoreFromState } from './play.fns';

/**
 * Returns the play state
 *
 * @param state
 */
export const selectPlayState = (state: AppState) => state.play;

/**
 * Selects the current session ID. Returns undefind when currentSession
 * equals 'UNKNOWN_SESSION_ID'
 *
 * @see {grabCurrentSessionIdWithFallback} for an implementation of this
 * method with a default fallback to 'UNKNOWN_SESSION_ID'
 */
export const selectActivitySessionId = createSelector(
    selectPlayState,
    (playState: ActivityPlayState): string | undefined => {
        return playState == null || playState.currentSession === UNKNOWN_SESSION_ID ? undefined : playState.currentSession;
    }
);

/**
 * Returns the current session from state. Shows a warning when
 * no active session ID is available, which should not happen.
 *
 * @param state
 */
export const grabCurrentSessionIdWithFallback = createSelector(
    selectPlayState,
    (playState: ActivityPlayState): string => {
        if (playState.currentSession === UNKNOWN_SESSION_ID) {
            console.warn(`'grabCurrentSessionIdWithFallback' called, but no active session available in state`);
        }
        return playState.currentSession;
    }
);

/**
 * Returns the current session information
 */
export const getCurrentSession = createSelector(
    selectPlayState,
    selectSessionsState,
    (playState: ActivityPlayState, sessionsState: SessionsState): ActivityPlaySession | undefined => {
        return sessionsState.sessions.find(s => s && s.sessionId === playState.currentSession);
    }
);

export const selectRouterState = (state: AppState) => (state['router'] || {}).state;
export const selectActivitySessionContext = createSelector(
    selectAllUserAssignments, selectRouterState, selectActivitySessionId,
    (asgn: UserAssignmentsStatusState, router: RouterState, sessionId: string): ActivitySessionContext | undefined => {
        return getActivitySessionContext(asgn, router, sessionId);
    }
);

/**
 * Returns the active play state
 */
export const getActivePlayState = createSelector(
    selectPlayState,
    (playState): ActivityPlayStateInstance | undefined => playState && playState.activity
);

export const selectSlideHistory = createSelector(
    getActivePlayState,
    (activityState) => {
        return activityState?.slideHistory;
    }
);

export const selectActivityGraph = createSelector(
    getActivePlayState,
    (activityState) => {
        return activityState?.activityGraph;
    }
);

export const selectTimeUp = createSelector(
    getActivePlayState,
    (activityState) => {
        return activityState?.timeUp;
    }
);

export const getCurrentScore = createSelector(
    selectedSection,
    selectSlideHistory,
    selectActivityGraph,
    selectTimeUp,
    (section: Section, history: SlideHistory, graph: ActivityGraph, timeUp: boolean): OverallScore | undefined => {
        return getCurrentScoreFromState(section, history, graph, timeUp);
    }
);


const getUserAssignment = (asgn: UserAssignmentsStatusState, assignmentId: string) => {
    let ua: UserAssignment | undefined;
    if (asgn.classAssignments) {
        asgn.classAssignments.forEach(ca => {
            ua = ua || ca.userAssignments.find(cua => cua.assignment.identifier === assignmentId);
        });
        if (ua != null) { return ua; }
    }

    if (asgn.personalTrackAssignments) {
        asgn.personalTrackAssignments.forEach(pta => {
            ua = ua || pta.userAssignments.find(ptua => ptua.assignment.identifier === assignmentId);
        });
        if (ua != null) { return ua; }
    }

    if (asgn.personalAssignments) {
        ua = asgn.personalAssignments.find(pa => pa.assignment.identifier === assignmentId);
        if (ua != null) { return ua; }
    }
};

export const getActivitySessionContext = (
    asgn: UserAssignmentsStatusState, router: RouterState, sessionId: string
): ActivitySessionContext | undefined => {
    if (
        router == null ||
        asgn == null
    ) { return; }

    const activityId = router.params['activityId'];
    if (activityId == null) { return; }

    const classId = router.params['classId'];
    const assignmentId: string | undefined = router.queryParams?.assignmentId;
    const userAssignment = assignmentId ? getUserAssignment(asgn, assignmentId) : undefined;
    let courseId: string | undefined;
    let trackId: string | undefined;
    if (userAssignment != null) {
        trackId = userAssignment.trackId;
        userAssignment.assignment.units.forEach(unit => {
            if (courseId == null && unit.type === 'course') {
                if (unit.sections.findIndex(s => s.identifier === activityId) >= 0) {
                    courseId = unit.identifier;
                }
            }
        });
    }
    return { activityId, classId, assignmentId, trackId, courseId, sessionId };
};

export const sectionEndedOnce$ = (state$: Store<AppState>): Observable<boolean> => {
    return state$.select(selectedSection).pipe(
        startWith(undefined),
        pairwise(),
        filter(([prev, curr]: [Section, Section]) => prev != null && curr == null),
        mapTo(true),
        take(1),
        shareReplay(1)
    );
};
