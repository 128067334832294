import { Store } from '@ngrx/store';
import { isOnline } from '@novo/platform-common/state/app-status-selectors';
import { AppState } from '@novo/platform-common/state/state';
import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { getCurrentUser } from '../state/auth-reducer';

/**
 * Filters the stream based on the current online state of the
 * application. Expects the relevant Store to be provided.
 *
 * @param state$    AppState which holds the online state
 * @param inverse   set to true to filter the inverse: whenOffline
 */
export const whenOnline = <T>(state$: Store<AppState>, inverse: boolean = false): MonoTypeOperatorFunction<T> => {
    return pipe(
        withLatestFrom(state$.select(isOnline)),
        filter(([_, online]) => online === !inverse),
        map(([a, _]) => a)
    );
};

/**
 * Filters the stream based on the current login state of the
 * user. Expects the relevant Store to be provided.
 *
 * @param state$    AppState which holds the online state
 */
export const whenLoggedIn = <T>(state$: Store<AppState>): MonoTypeOperatorFunction<T> => {
    return pipe(
        withLatestFrom(state$.select(getCurrentUser)),
        filter(([_, user]) => user != null),
        map(([a, _]) => a)
    );
};

/**
 * Filters values which are null or undefined and predicates the
 * type correctly.
 */
export const filterNull = <T>(): MonoTypeOperatorFunction<NonNullable<T>> => {
    return pipe(
        filter((a) => a != null),
    );
};