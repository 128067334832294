import { ActivityGraph } from '@novo/platform-common/services/activity/activity-graph';
import { InteractionResponse, InteractionScore, NoInteractionScore, OverallScore, ScoringDimension, ScoringSettings, Section, Slide, SlideHistory } from '../../models';
import { InteractionModelFactory } from '../../models/interaction-models/interaction-model-factory';

export const getCurrentScoreFromState = (section: Section | undefined, history: SlideHistory | undefined, graph: ActivityGraph | undefined, timeUp: boolean): OverallScore | undefined => {
    if (section == null || history == null || graph == null) {
        return;
    }

    const scoringSettings: ScoringSettings | undefined = section.scoringSettings;
    if (scoringSettings?.enabled) {
        if (timeUp) {
            // Add the shortest path to a proper final slide
            // and don't answer interactions on the shortest path (so they will be incorrect)
            const currentSlideId = history.currentSlideId === 'final' ? history.goBack().currentSlideId : history.currentSlideId;
            const missedSlides = graph?.getShortestPathToLeafFrom(currentSlideId || section.firstSlide) || [];
            missedSlides.forEach(slideId => history = history.goToNewSlide(slideId));
        }

        const dimensions: ScoringDimension[] = scoringSettings.dimensions;
        const slides: Slide[] = history.getSlides().map((slideId: string) => section.slides[slideId]);
        const responses = history.allResponses;
        const score: OverallScore = calculateOverallScore(dimensions, slides, responses);
        return score;
    }
};

export function calculateOverallScore(
    dimensions: ScoringDimension[],
    slides: Slide[],
    responses: { [slideId: string]: InteractionResponse[] }
): OverallScore {

    const dimIds: string[] = dimensions.map((dim: ScoringDimension) => dim.identifier);
    const overall = new OverallScore();
    dimIds.forEach((id: string) => overall.set(id, { count: 0, total: 0 }));

    // Loop through all slides with interactions
    slides.forEach((slide: Slide) => {

        if (slide == null || slide.interaction == null) { return; }

        const interactionModel = InteractionModelFactory.getInteractionModel(slide.interaction);
        const maxScore: InteractionScore | NoInteractionScore = interactionModel.getMaxScore();

        // Check whether there is a response to the interaction
        const response = getFirstCompleteResponse(slide, responses);
        if (response) {
            const score = response.score;

            if (score == null || score instanceof NoInteractionScore) {
                dimensions.forEach((dim: ScoringDimension) => {
                    overall.get(dim.identifier).total += maxScore.getDimensionScore(dim);
                });
            } else {
                dimensions.forEach((dim: ScoringDimension) => {
                    overall.get(dim.identifier).count += score.getDimensionScore(dim);
                    overall.get(dim.identifier).total += maxScore.getDimensionScore(dim);
                });
            }
        } else {
            dimensions.forEach((dim: ScoringDimension) => {
                overall.get(dim.identifier).total += maxScore.getDimensionScore(dim);
            });
        }
    });
    return overall;
}

function getFirstCompleteResponse(slide: Slide, responses: { [slideId: string]: InteractionResponse[] }): InteractionResponse | undefined {
    if (responses[slide.identifier] != null) {
        return responses[slide.identifier].find(response => response.complete);
    }
}
