import { Action, ActionReducer } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

export function deepFreeze(o: any) {
    const objType = typeof o;
    if (o == null || Object.isFrozen(o) || (objType !== 'object' && objType !== 'function')) {
        return o;
    }
    Object.freeze(o);
    Object.keys(o).forEach(key => deepFreeze(o[key]));
    return o;
}

/**
 * Meta-reducer that prevents state from being mutated anywhere in the app.
 */
export function storeFreeze<T, V extends Action = Action>(reducer: ActionReducer<T, V>): ActionReducer<T, V> {
    return (state: T, action: V): T => {

        // Make sure any object in an action cannot be changed by a reducer
        // and cause a side effect
        if (!Object.isFrozen(action)) {
            deepFreeze(cloneDeep(action));
        }

        // Execute the reducer
        const nextState = reducer(state, action);

        // Freeze the state
        return deepFreeze(nextState);
    };
}
