import { UUID } from 'angular2-uuid';
import { plainToClass, Type } from 'class-transformer';
import { cloneDeep } from 'lodash-es';
import { Dictionary } from './dictionary';
import { InternationalText } from './international-text';
import { LanguageCode } from './model-types';
import { NavigationLink } from './navigation';
import { serializeType } from './utilities';


export class NoInteractionScore {
    // Unique member to make a distinction for typescript between InteractionScore and NoInteractionScore when using instanceof
    readonly type = 'NoInteractionScore';

    static initialize(json: any): NoInteractionScore {
        return new NoInteractionScore();
    }

    public add(score: NoInteractionScore | InteractionScore | null | undefined): InteractionScore | NoInteractionScore {
        if (score == null) { return new NoInteractionScore(); }
        return cloneDeep(score);
    }

    public determineOverallScore(dimensions: ScoringDimension[]): number {
        return 0;
    }

    public getDimensionScore(dimension: ScoringDimension): number {
        return 0;
    }
}


export class InteractionScore {

    public dimensions: Dictionary<number> = {};

    static initialize(json: any): InteractionScore {
        const result = new InteractionScore();
        if (json && json.dimensions) {
            Object.keys(json.dimensions).forEach(key => result.dimensions[key] = json.dimensions[key]);
        }
        return result;
    }

    public static max(scoreA: InteractionScore | NoInteractionScore,
        scoreB: InteractionScore | NoInteractionScore): InteractionScore | NoInteractionScore {

        if (scoreA instanceof NoInteractionScore) {
            return cloneDeep(scoreB);
        }

        const maxScore = cloneDeep(scoreA) as InteractionScore;
        if (scoreB instanceof InteractionScore) {
            Object.entries(scoreB.dimensions).forEach(([key, value]) => {
                if (Object.keys(maxScore.dimensions).includes(key)) {
                    maxScore.dimensions[key] = Math.max(value!, maxScore.dimensions[key] || 0);
                } else {
                    maxScore.dimensions[key] = value;
                }
            });
        }
        return maxScore;
    }

    public add(score: InteractionScore | NoInteractionScore | undefined | null): InteractionScore {
        const result = new InteractionScore();
        Object.entries(this.dimensions).forEach(([key, value]) => result.dimensions[key] = value);

        if (score instanceof InteractionScore) {
            Object.entries(score.dimensions).forEach(([key, value]) => {
                if (Object.keys(result.dimensions).includes(key)) {
                    result.dimensions[key] = (result.dimensions[key] || 0) + value!;
                } else {
                    result.dimensions[key] = value;
                }
            });
        }
        return result;
    }

    public getDimensionScore(dimension: ScoringDimension): number {
        if (Object.keys(this.dimensions).includes(dimension.identifier)) {
            return this.dimensions[dimension.identifier] || 0;
        } else {
            return 0;
        }
    }

    /**
     * Update the dimensions; returns true iff the dimensions have changed.
     */
    public updateDimensions(dimensions: ScoringDimension[], defaultScore: number): boolean {
        let changed = false;
        // Do all dimensions exist
        dimensions.forEach(dim => {
            if (!Object.keys(this.dimensions).includes(dim.identifier)) {
                this.dimensions[dim.identifier] = defaultScore;
                changed = true;
            }
        });

        // No additional dimensions
        Object.entries(this.dimensions).forEach(([key, value]) => {
            if (dimensions.findIndex(dim => dim.identifier === key) < 0) {
                this.dimensions[key] = undefined;
                changed = true;
            }
        });
        return changed;
    }
}

/**
 * A map from dimension identifier to { count: number, total: number }
 * where count is the obtained score and the maximal obtainable score
 */
export class OverallScore {

    map: Dictionary<{ count: number, total: number }>;

    public static from(score?: InteractionScore | NoInteractionScore, maxScore?: InteractionScore | NoInteractionScore): OverallScore | undefined {
        // Both score and maximum score need to be defined
        if (!(score instanceof InteractionScore && maxScore instanceof InteractionScore)) {
            return undefined;
        }
        // Same dimensions
        if (score.dimensions.size !== maxScore.dimensions.size
            || Object.keys(score.dimensions).some(id => maxScore.dimensions[id] == null)) {
            return undefined;
        }

        const result = new OverallScore();
        Object.keys(score.dimensions).forEach(id => {
            result.map[id] = {
                count: score.dimensions[id] || 0,
                total: maxScore.dimensions[id] || 0
            };
        });
        return result;
    }

    constructor() {
        this.map = {};
    }

    public get(dimensionId: string): { count: number, total: number } {
        const dimensionIds: string[] = Object.keys(this.map);
        if (dimensionIds.includes(dimensionId)) {
            return this.map[dimensionId] || { count: 0, total: 0 };
        } else if (dimensionIds.length > 0) {
            // This shouldn't happen: dimension requested does not exist.
            // WORKAROUND!!! just pick the first one that exists
            return this.map[dimensionIds[0]] || { count: 0, total: 0 };
        }
        return { count: 0, total: 0 };
    }

    public set(dimensionId: string, value: { count: number, total: number }) {
        this.map[dimensionId] = value;
    }

    public determineOverallObtainedScore(dimensions: ScoringDimension[]): number {
        let overallScore = 0;
        let totalWeight = 0;
        dimensions.forEach(dim => {
            overallScore += this.get(dim.identifier).count * dim.weight;
            totalWeight += dim.weight;
        });
        return overallScore / totalWeight;
    }

    public determineOverallMaximumScore(dimensions: ScoringDimension[]): number {
        let overallScore = 0;
        let totalWeight = 0;
        dimensions.forEach(dim => {
            overallScore += this.get(dim.identifier).total * dim.weight;
            totalWeight += dim.weight;
        });
        return overallScore / totalWeight;
    }

    public isPassed(dimensions: ScoringDimension[]): boolean {
        return dimensions.every(dim => {
            if (this.get(dim.identifier).total === 0) { return true; }
            const perc: number = Math.round(100 * this.get(dim.identifier).count / this.get(dim.identifier).total);
            return perc >= dim.getThreshold();
        });
    }
}

export class ScoringDimensionBin {

    public minScore: number;
    public color?: string = undefined;
    @Type(serializeType(InternationalText)) public feedbackMessage: InternationalText;
    @Type(serializeType(NavigationLink)) navigationLink?: NavigationLink = undefined;

    constructor(minScore: number, message?: InternationalText) {
        this.minScore = minScore;
        this.feedbackMessage = message || new InternationalText();
    }
}

export class ScoringDimension {

    readonly identifier: string;

    @Type(serializeType(InternationalText)) public name: InternationalText = new InternationalText();

    // The score for each dimension and the overall score fall into a bin. The bins are sorted in increasing value.
    @Type(serializeType(ScoringDimensionBin)) public bins: ScoringDimensionBin[];

    // passingBin defines the index in bins which is considered to be a pass.
    public passingBin: number;

    // The overall score is a weighted sum of the scores of all dimensions
    public weight: number;

    constructor(defaultLanguage: LanguageCode, defaultName?: string) {
        this.identifier = UUID.UUID().slice(0, 8);
        this.name.default = defaultLanguage;
        if (defaultName) {
            this.name.translation[defaultLanguage] = defaultName;
        }

        this.bins = [0, 60, 80].map(score => {
            const message = new InternationalText();
            message.default = defaultLanguage;
            switch (score) {
                case 0:
                    message.translation['en'] = 'Oops!';
                    break;
                case 60:
                    message.translation['en'] = 'Nice!';
                    break;
                case 80:
                    message.translation['en'] = 'Great!';
                    break;
            }
            return new ScoringDimensionBin(score, message);
        });

        this.passingBin = 1;
        this.weight = 1;
    }

    public getThreshold(): number {
        return this.bins[this.passingBin].minScore;
    }
}

export class ScoringSettings {
    // Only on section. A section is considered passed when the score for every dimension is considered a pass.
    @Type(serializeType(ScoringDimension)) public dimensions: ScoringDimension[] = [];

    // The time limit setup for activity
    public timeLimit = 0;

    // Is scoring enabled for the section?
    public enabled = false;

    // Show an animation after every response to an interaction?
    public showInteractionScore = true;

    // In an assessment, no feedback is provided
    public assessment = false;

    // Default score for dimensions
    public defaultDimensionScore = 10;

    static initialize(json: any): ScoringSettings {
        return plainToClass(ScoringSettings, json);
    }

    constructor() {
        this.dimensions.push(new ScoringDimension('en', 'Correctness'));
    }

    getDefaultScore(): InteractionScore {
        const score: InteractionScore = new InteractionScore();
        this.dimensions.forEach(dim => {
            score.dimensions[dim.identifier] = this.defaultDimensionScore;
        });
        return score;
    }
}
