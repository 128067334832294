import { PhoneSet } from '@novo-learning/novo-sdk';
import { UUID } from 'angular2-uuid';
import { Type } from 'class-transformer';
import { InternationalText, Language } from '../international-text';
import { MediaInstance } from '../media';
import { LanguageCode, MediaType } from '../model-types';
import { InteractionScore } from '../score';
import { serializeType } from '../utilities';


/**
 * An InteractionOption describes a possible response a user can select
 */
export abstract class InteractionOption {
    readonly id: string;
    readonly type: 'text' | MediaType | 'pronunciation';
    fixed = false; // Indicates if this option must stay in the current position when shuffeling all options

    static initialize(json: any): InteractionOption | undefined {
        let ii: InteractionOption;
        switch (json.type) {
            case 'text':
                ii = TextInteractionOption.initialize(json);
                break;
            case 'audio':
                ii = AudioInteractionOption.initialize(json);
                break;
            case 'image':
                ii = ImageInteractionOption.initialize(json);
                break;
            case 'pronunciation':
                ii = PronunciationInteractionOption.initialize(json);
                break;
            default:
                console.error('Unknown Interaction type: ' + json.type);
                return undefined;
        }

        if (json.id) {
            (ii as any).id = json.id;
        }
        return ii;
    }

    constructor(type: 'text' | MediaType | 'pronunciation') {
        this.type = type;
        this.id = UUID.UUID().slice(0, 8);
    }

    isLanguageCompatible(lang: Language): boolean {
        return true;
    }
}

export class TextInteractionOption extends InteractionOption {
    text = '';

    static initialize(json: any): InteractionOption {
        const option = new TextInteractionOption();
        for (const key of Object.keys(option)) {
            option[key] = json[key];
        }
        return option;
    }

    static is(option: InteractionOption): option is TextInteractionOption {
        return option != null && option.type === 'text';
    }

    constructor() {
        super('text');
    }
}

export abstract class MediaInteractionOption extends InteractionOption {
    media?: MediaInstance = undefined;

    static is(option: InteractionOption): boolean {
        return ['audio', 'video', 'image', 'image-avatar', 'pixi-avatar'].includes(option.type);
    }

    constructor(type: MediaType) {
        super(type);
    }
}

export class AudioInteractionOption extends MediaInteractionOption {
    static initialize(json: any): InteractionOption {
        const option = new AudioInteractionOption();
        if (json.media) {
            option.media = MediaInstance.initialize(json.media);
        }
        return option;
    }

    constructor() {
        super('audio');
    }
}

export class ImageInteractionOption extends MediaInteractionOption {
    static initialize(json: any): InteractionOption {
        const option = new ImageInteractionOption();
        if (json.media) {
            option.media = MediaInstance.initialize(json.media);
        }
        return option;
    }

    constructor() {
        super('image');
    }
}

export class VideoInteractionOption extends MediaInteractionOption {
    static initialize(json: any): InteractionOption {
        const option = new VideoInteractionOption();
        if (json.media) {
            option.media = MediaInstance.initialize(json.media);
        }
        return option;
    }

    constructor() {
        super('video');
    }
}

export class Pronunciation {
    correct?= true;
    phones: string[] = [];
    meta?: { [key: string]: string } = undefined; // "100" -> { expanded: "one hundred" }

    static initialize(json: any): Pronunciation {
        const pron = new Pronunciation();
        for (const key of Object.keys(pron)) {
            pron[key] = json[key];
        }
        if (pron.correct == null) { pron.correct = true; }
        if (pron.phones == null) { pron.phones = []; }
        return pron;
    }

    static phonesEqual(p1: Pronunciation, p2: Pronunciation): boolean {
        if (p1.phones.length !== p2.phones.length) { return false; }
        return p1.phones.every((phone, idx) => p2.phones[idx] === phone);
    }

    clone(): Pronunciation {
        const pronunciation = new Pronunciation();
        pronunciation.correct = this.correct;
        pronunciation.phones = [...this.phones];
        pronunciation.meta = this.meta ? { ...this.meta } : undefined;
        return pronunciation;
    }
}

export class PronunciationEntry {
    targetLanguage: LanguageCode = 'en';
    nativeLanguage?: LanguageCode = undefined;
    word?: string = undefined;
    phones: string[] = [];
    correct?: boolean = undefined;
    count = 0;

    static initialize(json: any): PronunciationEntry {
        const pron = new PronunciationEntry();
        for (const key of Object.keys(pron)) {
            pron[key] = json[key];
        }
        return pron;
    }
}

export class PronunciationInteractionOptionWord {
    pronunciations: Pronunciation[] | undefined = undefined;
    liaison?: string;
    isEvaluated = false;

    static initialize(json: any): PronunciationInteractionOptionWord {
        const word = new PronunciationInteractionOptionWord(json.label);
        if (json.pronunciations && json.pronunciations.length > 0) {
            word.pronunciations = json.pronunciations.map(p => Pronunciation.initialize(p));
        }
        word.liaison = json.liaison;
        word.isEvaluated = (json.isEvaluated === true);
        return word;
    }

    constructor(public readonly label: string) {

    }

    setPronunciations(prons: Pronunciation[] | undefined) {
        if (prons && prons.length > 0) {
            this.pronunciations = prons as { 0: Pronunciation } & Pronunciation[];
        } else {
            this.pronunciations = undefined;
        }
    }

    hasDoublePronunciation(): boolean {
        if (this.pronunciations == null) { return false; }
        for (let idx1 = 0; idx1 < this.pronunciations.length; idx1++) {
            for (let idx2 = idx1 + 1; idx2 < this.pronunciations.length; idx2++) {
                if (Pronunciation.phonesEqual(this.pronunciations[idx1], this.pronunciations[idx2])) {
                    return true;
                }
            }
        }
        return false;
    }
}

export class PronunciationInteractionOption extends InteractionOption {
    words: PronunciationInteractionOptionWord[] = [];
    phoneSet?: PhoneSet | null = undefined;
    exampleAudio?: MediaInstance = undefined;
    translations: InternationalText = new InternationalText();
    @Type(serializeType(InteractionScore)) score?: InteractionScore = undefined; // The score the user will get when responding with this option.

    static initialize(json: any): PronunciationInteractionOption {
        const option = new PronunciationInteractionOption();
        for (const key of Object.keys(option)) {
            if (json[key] == null) {
                continue;
            }
            if (key === 'words') {
                option.words = json.words.map(w => PronunciationInteractionOptionWord.initialize(w));
            } else if (key === 'exampleAudio') {
                option.exampleAudio = MediaInstance.initialize(json.exampleAudio);
            } else if (key === 'translations') {
                option.translations = InternationalText.initialize(json.translations);
            } else if (key === 'score') {
                option.score = InteractionScore.initialize(json.score);
            } else {
                option[key] = json[key];
            }
        }
        return option;
    }

    static is(option: InteractionOption): option is PronunciationInteractionOption {
        return option.type === 'pronunciation';
    }

    get text(): string {
        return this.words.map(w => w.label).join(' ');
    }

    constructor() {
        super('pronunciation');
    }

    isLanguageCompatible(lang: Language): boolean {
        return this.phoneSet == null || this.phoneSet === lang.phoneSet;
    }
}

