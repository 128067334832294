import { InjectionToken } from '@angular/core';
import { Action, INIT, Store } from '@ngrx/store';
import { DBSchema, openDB } from 'idb';
import { isArray, isObject, mergeWith, throttle, transform } from 'lodash-es';

// Define a key-value type which represents a state
interface State { [key: string]: any; }

/**
 * Identifier used to identify stringified objects which can be
 * deserialized into a model.
 */
const SERIALIZATION_IDENTIFIER = 'Θ';

/**
 * Identifier used to identify stringified Date objects which can be
 * deserialized into a Date instance
 */
const DATE_SERIALIZATION_IDENTIFIER = 'Ξ';

/**
 * Keys which are used to write metadata about the storage hydrator
 */
const META_KEYS = {
    VERSION: 'VERSION'
};

/**
 * Configuration interface for StateHydrator reducer.
 *
 * Include and exclude are defined as undefined by default, causing
 * every key in the provided State to be stored.
 *
 * Providing include OR exclude allows to define which keys should
 * be serialized, or which shouldn't.
 *
 * An array of model constructors can be provided which will be used to
 * automatically serialize and deserialize instances of models.
 * The model constructor should at least implement the initialize method, which
 * accepts the 'json' object.
 *
 * By default every action will trigger a write operation. Use excludeActions to
 * provide a list of action types which should not trigger a write.
 *
 * Use 'plainStoreKeys' to define which state features should not be serialized, but
 * instead should be saved 'as they are'.
 *
 * Bump 'version' whenever state shapes change between application versions.
 * Whenever the StateHydrator initializes it will compare the provided version
 * with the last known versions. On version changes initialization is delayed and `versionChangeCallback`
 * will be executed with the 'old state' allowing to perform state shape changes. The callback is expected
 * to return a Promise which resolves with the new state.
 */
export interface StateHydratorConfig<T extends Object = {}> {
    include?: Array<keyof T>;
    exclude?: Array<keyof T>;
    storageKey: string;
    throttleMs: number;
    modelSerializers?: { [key: string]: { new(...args: any[]): any; initialize(val: any): any } };
    excludeActions?: string[];
    plainStoreKeys?: Array<keyof T>;
    version: number;
    versionChangeCallback?: (oldState: State, oldVersion: number) => Promise<State>;

    /**
     * Used to split a large state object into multiple objects. This can be
     * usefull to tweak performance, since it prevents large objects from being
     * written to the IndexedDB on every change (which is undesired since IndexedDB
     * has to clone the entire object - on the main thread - before storing it).
     *
     * @example
     * Take the following state object: { a: { b: { c: [1,2,3,4], d: true } }, e: {} }
     * With an depth set to 'two' the object would be stored in our IndexedDB
     * as followed:
     * - a.b = {c: [1,2,3,4], d: true}
     * - a.e = {}
     *
     * Increasing the setting to 'three' would create the following entries:
     * - a.b.c = [1,2,3,4]
     * - a.b.d = true
     * - a.e = {}
     */
    maxFlatteningDepth: number;
}

/**
 * Define the default config
 */
export const defaultConfig: StateHydratorConfig = {
    storageKey: 'app-state',
    throttleMs: 3000,
    maxFlatteningDepth: 3,
    version: 0
};

/**
 * Interface representing state in IndexDB
 */
interface StateDB extends DBSchema {
    features: {
        value: {
            name: string,
            state: object,
        },
        key: string,
    };
    meta: {
        key: string;
        value: any;
    };
}

/**
 * Action which should be emitted as soon as hydration
 * should take place.
 */
export const HYDRATION_INIT = 'HYDRATION_INIT';
export class HydrationInit implements Action {
    type = HYDRATION_INIT;
    constructor(readonly state?: State) { }
}

/**
 * Normalizes user config with default config
 * @param userConfig
 */
export const normalizeUserConfig = <T>(userConfig: Partial<StateHydratorConfig<T>>): StateHydratorConfig<T> => {
    return {
        ...defaultConfig,
        ...userConfig
    };
};

/**
 * Reducers can be registered multiple times (per forFeature call).
 * ActionHistory and hydrationHappened are 'app-wide' variables, so
 * make sure to define them on module-level.
 */
const actionHistory: any[] = [];
let hydrationHappened = false;

/**
 * State diff is used by the reducer to curate a state of 'changed' state features.
 * The object is cleared after each DB write.
 */
let stateDiff: State = {};
let flattenedStateDiff: any = {};


/**
 * Keep track of the last reduced state in a flattened form,
 * so we can create a diff of values which need to be written
 */
let lastFlattenedState: any;
let lastState: any;

/**
 * Reducer which stores and restores the state from Storage.
 *
 * Since we're using an async storage driver (IndexedDB) we can't
 * override the state during "INIT" (redux doesn't support async reducers).
 *
 * It's therefore that we make use of our own custom INIT action which
 * indicates a state hydration. As soon as this happens the restored state is
 * merged into the 'existing' state and all already executed actions
 * are replayed.
 *
 * @param config
 */
export const stateHydrator = <T>(userConfig: Partial<StateHydratorConfig<T>>) => (reducer: any) => {
    // Merge user config with default config
    const config = normalizeUserConfig(userConfig);

    // Setup throttler
    const throttledSaveStateDiff = throttle(saveStateDiff, config.throttleMs, { leading: false, trailing: true });

    // Verify if there are no duplicate keys in modelSerializers
    if (config.modelSerializers != null) {
        const uniqKeys = Object.keys(config.modelSerializers).reduce<string[]>((acc, cur) => [
            ...acc,
            ...(acc.indexOf(cur) === -1 ? [cur] : [])
        ], []);
        if (uniqKeys.length !== Object.keys(config.modelSerializers).length) {
            throw new Error('[StateHydrator] Not all "modelSerializers" keys are unique. Make sure each model has it\'s own unique key');
        }
    }

    return function (state: any, action: any) {
        let nextState: any;

        // If state arrives undefined, we need to let it through the supplied reducer
        // in order to get a complete state as defined by user
        if ((action.type === INIT) && !state) {
            nextState = reducer(state, action);
        } else {
            nextState = { ...state };
        }

        if (action.type === HYDRATION_INIT && action.state) {
            // Merge nextState with our rehydratedState
            // By default 'merge' will extend array values, which we don't
            // want to happen. We therefore use mergeWith and specify our own
            // solution for array values.
            nextState = mergeWith({}, nextState, action.state, (objValue, srcValue) => {
                if (isArray(objValue)) {
                    return srcValue;
                }
            });

            // Playback 'missed' actions
            nextState = actionHistory.reduce(reducer, nextState);

            hydrationHappened = true;
        }

        // Even though our async hydration action isn't the first we want it
        // to represent the first state. We therefore 'cache' all actions,
        // allowing them to be applied as soon as hydration happened.
        if (!hydrationHappened) {
            actionHistory.push(action);
        }

        nextState = reducer(nextState, action);

        // Store state in storage with the configured throttleMs
        if (
            hydrationHappened &&
            action.type !== HYDRATION_INIT &&
            (!config.excludeActions || config.excludeActions.indexOf(action.type) === -1)
        ) {
            void throttledSaveStateDiff(nextState, config);
        }

        if (action.type === HYDRATION_INIT) {
            // Set as last known state to make sure it's not included
            // in the next 'diff-write'
            lastState = nextState;
            lastFlattenedState = flattenObject(lastState, config.maxFlatteningDepth);
        }

        return nextState;
    };
};

/**
 * Creates a diff of the state which needs to be saved to IndexedDB.
 *
 * To create the diff an initial state feature comparison is executed,
 * the result of this diff is then forwarded to flattenObject which
 * creates an flattened object.
 *
 * An additional diff of this flattenedobject is then executed which
 * results in a list of values which need to be stored.
 */
const saveStateDiff = (state: {}, config: StateHydratorConfig<any>): Promise<any> => {
    // Filter to relevant state
    const filteredState = filterStateByKeys(state, config);
    let hasChanges = false;

    // create a feature state diff which does a simple
    // initial diff to check which features were modified
    for (const key in filteredState) {
        if (!lastState || filteredState[key] !== lastState[key]) {
            hasChanges = true;
            stateDiff[key] = filteredState[key];
        }
    }

    if (!hasChanges) {
        return Promise.resolve();
    }


    // Create flattened state from initial diff
    const flattenedState = flattenObject(stateDiff, config.maxFlatteningDepth);

    // Create partial state holding changed values
    if (!lastFlattenedState) {
        flattenedStateDiff = { ...flattenedState };
    } else {
        // Grab array of keys included in stateDiff
        const stateDiffFeatureKeys = Object.keys(stateDiff);

        // Loop through keys of both last and new state
        // and take note of all differences.
        //
        // We want no longer existing values to be removed from our
        // database so we'll have to include the keys of our last-known
        // state too
        const combinedState = { ...lastFlattenedState, ...flattenedState };
        for (const key in combinedState) {
            if (combinedState.hasOwnProperty(key)) {
                // Check if the key's feature root is included in the stateDiff
                // if not it should be skipped in comparison to prevent it from being
                // deleted
                const dotIndex = key.indexOf('.');
                const bracketIndex = key.indexOf('[');
                const featureSplitIndex = dotIndex > bracketIndex && bracketIndex > -1 ? bracketIndex : dotIndex;

                if (stateDiffFeatureKeys.indexOf(key.substr(0, featureSplitIndex)) > -1) {
                    // If key is included in diff we'll check if values have changed
                    // in comparison with the previous state
                    if (lastFlattenedState[key] !== flattenedState[key]) {
                        flattenedStateDiff[key] = flattenedState[key];
                    }
                }
            }
        }
    }

    // Save last compared state
    // We're extending lastFlattenedState since 'flattenedState' will
    // only contain a partial (filtered) state.
    lastFlattenedState = { ...lastFlattenedState, ...flattenedState };
    lastState = filteredState;

    return saveStateToStorage(flattenedStateDiff, config).then(resp => {
        // Clear state diff
        flattenedStateDiff = {};
        stateDiff = {};

        return resp;
    });
};


/**
 * Gets the last known state from storage and deserialize it so all
 * objects are initialized again.
 */
const getStateFromStorage = async (config: StateHydratorConfig<any>): Promise<State | undefined> => {
    let flattenedStoredState = await fromDb(config.storageKey);
    if (!flattenedStoredState) {
        return;
    }

    // Filter excluded keys
    flattenedStoredState = filterStateByKeys(flattenedStoredState, config);

    // Create two lists containing states to deserialize and ones to
    // pass as-they-are
    const plainStoreKeys = config.plainStoreKeys;
    const [toDeserialize, notToDeserialize] = plainStoreKeys ? extractStateByKeys(flattenedStoredState, plainStoreKeys) : [flattenedStoredState, {}];
    let deserializedState: {};

    try {
        deserializedState = (function deserializeObject(obj: State): State {
            return transform(obj, (result, value, key) => {
                let deserializedResult: any | undefined;
                if (config.modelSerializers && typeof value === 'string' && value.slice(0, SERIALIZATION_IDENTIFIER.length) === SERIALIZATION_IDENTIFIER) {
                    // All models are serialized and added as a string, starting with the SERIALIZATION_IDENTIFIER char
                    // Parse the string into an object and try to initialize it
                    const modelJson = JSON.parse(value.slice(SERIALIZATION_IDENTIFIER.length));

                    // Verify if the modelJson is indeed an object, which is not null, and has the serialize key.
                    // If so, initialize the model using the constructor defined in modelSerializers
                    if (typeof modelJson === 'object' && modelJson != null && SERIALIZATION_IDENTIFIER in modelJson) {
                        const model = config.modelSerializers[modelJson[SERIALIZATION_IDENTIFIER]];
                        if (model && 'initialize' in model) {
                            deserializedResult = model.initialize(modelJson);
                        }
                    }
                }
                // Deserialize date strings. See somment in #saveStateToStorage for more information
                // about the need to serialize and deserialize dates
                if (typeof value === 'string' && value.slice(0, DATE_SERIALIZATION_IDENTIFIER.length) === DATE_SERIALIZATION_IDENTIFIER) {
                    deserializedResult = new Date(value.slice(DATE_SERIALIZATION_IDENTIFIER.length));
                }

                result[key] = deserializedResult ? deserializedResult : (isObject(value)) ? deserializeObject(value) : value;
            });
        })(toDeserialize);
    } catch (e) {
        console.warn('An error occurred trying to parse the stored state', e);
        return;
    }

    // Unflatten back to its original shape
    return unflattenObject({
        ...deserializedState,
        ...notToDeserialize
    });
};

/**
 * Saves the given (partial) state to storage.
 *
 * Since our state contains class instances we have to serialize
 * those into plain object before sending them to the database
 *
 * Note how the state provided to 'saveStateToStorage' has already
 * been flattened, meaning some 'deeper objects' have been moved
 * to the first index.
 *
 * @param state
 * @param config
 */
export const saveStateToStorage = async (state: State, config: StateHydratorConfig<any>): Promise<State> => {
    if (Object.keys(state).length === 0) {
        return state;
    }

    const plainStoreKeys = config.plainStoreKeys;
    const [toSerialize, notToSerialize] = plainStoreKeys ? extractStateByKeys(state, plainStoreKeys) : [state, {}];

    try {
        const missingModels: string[] = [];
        const serializedState = (function serializeObject(obj: State): State {
            return transform(obj, (result, value, key) => {
                if (config.modelSerializers != null && isInitializable(value)) {
                    const constructorFn = value.constructor;
                    const foundConstructorKey = Object.keys(config.modelSerializers).reduce((found, k) => {
                        // tslint:disable-next-line: no-non-null-assertion
                        return config.modelSerializers![k] === constructorFn ? k : found;
                    }, undefined);
                    if (!foundConstructorKey) {
                        if (missingModels.indexOf(constructorFn.name) === -1) {
                            missingModels.push(constructorFn.name);
                        }
                        return value;
                    }
                    result[key] = SERIALIZATION_IDENTIFIER + JSON.stringify({
                        ...value,
                        [SERIALIZATION_IDENTIFIER]: foundConstructorKey
                    });
                } else if (value instanceof Date) {
                    // For some reason IndexedDB isn't handling 'Date' values
                    // correctly. During DB retrieval or deserialization they're converted
                    // into empty objects, causing some date dependent functions
                    // in our app to fail.
                    //
                    // The serializer below converts dates to string allowing them
                    // to be converted back during deserialization
                    result[key] = DATE_SERIALIZATION_IDENTIFIER + value.toString();
                } else {
                    result[key] = (isObject(value)) ? serializeObject(value) : value;
                }
            });
        })(toSerialize); // Note how we're only serializing the 'toSerialize' state!

        // Write both serialized and not serialized state to storage
        await writeToDb(config.storageKey, {
            ...notToSerialize,
            ...serializedState
        });

        if (missingModels.length > 0) {
            console.warn(`[StateHydrator] Encountered auto-serializable models which have no declaration in config#modelSerializers\nMake sure to include the following models in your configuration:\n - ${missingModels.join('\n - ')}`);
        }
    } catch (e) {
        console.warn('Failed saving the new state to storage', e);
    }

    return state;
};

/**
 * Creates a list of two states based on the provided list
 * of 'feature keys'.
 *
 * The first list contains all state elements which were not
 * included in the provided 'keys', The rest is placed in the
 * second list.
 */
const extractStateByKeys = (state: {}, keys: (string | number | symbol)[]): [{}, {}] => {
    return Object.keys(state).reduce<[State, State]>((acc, flattenedKey) => {
        // State object is flattened, so we'll have to get the first key from the object path
        const featureKey = flattenedKey.split(/\.|\[\d\]/g, 2).shift() || flattenedKey;
        // Check if it should be added to 'toSerialize' (0) or 'notToSerialize' (1)
        const collectionIndex = (keys.indexOf(featureKey) === -1) ? 0 : 1;
        acc[collectionIndex][flattenedKey] = state[flattenedKey];
        return acc;
    }, [{}, {}]);
};

/**
 * Filters config.include and config.exclude keys in the given state. This
 * returns a new object containing references to the original state.
 *
 * The provided state has already been flattened, which means some deeper
 * nested state values have been moved to the first index (in the form of 'state.value1.value2'
 * or state[0].value1).
 */
export const filterStateByKeys = (state: State, config: StateHydratorConfig<any>): State => {
    // Create new state object which will reference to relevant
    // keys in the original state
    const relevantState = {};

    for (const flattenedKey in state) {
        if (state.hasOwnProperty(flattenedKey)) {
            // Get first key of potentially deeply nested key
            const featureKey = flattenedKey.split(/\.|\[\d\]/g, 2).shift();

            // Don't include keys which are not mentioned by include (if provided)
            // and don't include keys which are mentioned in exclude (if provided)
            if (
                featureKey === undefined ||
                (config.include && config.include.indexOf(featureKey) === -1) ||
                (config.exclude && config.exclude.indexOf(featureKey) > -1)
            ) {
                continue;
            }

            relevantState[flattenedKey] = state[flattenedKey]; // include key's value in relevantState
        }
    }

    return relevantState;
};

/**
 * Initializer method which retrieves the state from storage
 * and emits a HydrationInit action.
 *
 * This function is intended to run as APP_INITIALIZER, allowing
 * our state to be read async.
 *
 * @param store
 * @param config
 */
export const stateInitializerFactory = (store: Store<any>, userConfig: Partial<StateHydratorConfig>) => async (): Promise<any> => {
    const config = normalizeUserConfig(userConfig);
    let state = await getStateFromStorage(config);

    // Handle state version change when versionChangeCallback is provided
    if (state != null && config.version && typeof config.versionChangeCallback === 'function') {
        // Retrieve previously saved version, with a fallback to zero (default version)
        const oldVersion = (await getMeta(config.storageKey, META_KEYS.VERSION)) || 0;
        if (oldVersion !== userConfig.version) {
            // Execute versionChangeCallback and use new state
            state = await config.versionChangeCallback(state, oldVersion);

            // Write new version
            void writeMeta(config.storageKey, META_KEYS.VERSION, userConfig.version);
        }
    }

    return store.dispatch(new HydrationInit(state));
};

/**
 * Token used to provide StateHydration User Config to the
 * initializer method.
 */
export const StateHydratorUserConfigToken: InjectionToken<StateHydratorConfig> = new InjectionToken<StateHydratorConfig>('StateHydratorUserConfig');

/**
 * Get/create instance of IndexedDB
 *
 * @param dbName
 */
const getDb = async (dbName: string) => {
    return openDB<StateDB>(dbName, 3, {
        upgrade(db, oldVersion) {
            if (oldVersion < 1) {
                db.createObjectStore('features', { keyPath: 'name' });
            }
            if (oldVersion < 3) {
                db.createObjectStore('meta');
            }
        }
    });
};

/**
 * Write given state to database
 *
 * @param state
 */
const writeToDb = async (dbName: string, state: State): Promise<any> => {
    const db = await getDb(dbName);
    const tx = db.transaction('features', 'readwrite');
    const store = tx.objectStore('features');
    for (const key in state) {
        if (state[key] === undefined) {
            void store.delete(key);
        } else {
            void store.put({
                name: key,
                state: state[key]
            });
        }
    }
    await tx.done;
};


/**
 * Get all state entries from DB
 *
 * @param dbName
 */
const fromDb = async (dbName: string): Promise<State> => {
    const db = await getDb(dbName);
    const state = await db.getAll('features');
    const result = {};
    for (const key in state) {
        if (state.hasOwnProperty(key)) {
            result[state[key].name] = state[key].state;
        }
    }
    return result;
};

/**
 * Write meta data to database
 *
 * @param dbName
 * @param key
 * @param value
 */
const writeMeta = async (dbName: string, key: string, value: any): Promise<any> => {
    const db = await getDb(dbName);
    const tx = db.transaction('meta', 'readwrite');
    const store = tx.objectStore('meta');
    void store.put(value, key);
    await tx.done;
};

/**
 * Returns the requested meta value
 *
 * @param dbName
 * @param key
 */
const getMeta = async (dbName: string, key: string): Promise<any | undefined> => {
    const db = await getDb(dbName);
    return db.get('meta', key);
};

/**
 * Flattens a given object by 'moving' deeper nested values to
 * the top level.
 *
 * @example
 * The following object: { a: {b: [1,2], c: true}, b: true }
 * Becomes: { 'a.b': [1,2], 'a.c': true, b: true } when called with a
 * depth of '2'
 *
 * @param object
 * @param depth
 */
const flattenObject = (obj: {}, maxDepth: number): { [key: string]: any } => {
    const result = {};
    (function recurse(cur, prop = '', curDepth = 0) {
        curDepth += 1;
        if (Object(cur) !== cur || cur instanceof Date || curDepth > maxDepth || isInitializable(cur)) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
            const l = cur.length;
            if (l === 0) {
                result[prop] = cur;
            } else {
                for (let i = 0; i < l; i++) {
                    recurse(cur[i], `${prop}[${i}]`, curDepth);
                }
            }
        } else {
            let isEmpty = true;
            for (const p in cur) {
                if (cur.hasOwnProperty(p)) { // only save direct own properties
                    isEmpty = false;
                    recurse(cur[p], prop ? `${prop}.${p}` : p, curDepth);
                }
            }
            if (isEmpty && prop) {
                result[prop] = cur;
            }
        }
    })(obj);

    return result;
};

/**
 * Unflattens an object 'flattened' by 'flattenObject'.
 * See 'flattenObject' for more information about a flattened
 * object.
 */
const unflattenObject = (obj: {}): {} => {
    if (Object(obj) !== obj || Array.isArray(obj)) {
        return obj;
    }

    // Search keys for 'object' patterns (dot notation: '.')
    // or arrays (braces index notation: [0-9])
    const splitRegex = /\.?([^.\[\]]+)|\[(\d+)\]/g;
    const result = {};
    for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
            let cur = result;
            let prop = '';
            let m: RegExpExecArray | null;
            while (m = splitRegex.exec(p)) {
                // Check if the set property exists on the current object
                // If not prepare a new array or object and set the
                // property.
                cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
                prop = m[2] || m[1];
            }
            cur[prop] = obj[p];
        }
    }
    return result[''] || result;
};

/**
 * Returns true when the given object can be 'initialized'
 */
const isInitializable = (obj: any): boolean => {
    return (typeof obj === 'object' && obj != null && 'initialize' in obj.constructor);
};
