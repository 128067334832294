import { UUID } from 'angular2-uuid';
import { clone, isEqual } from 'lodash-es';
import { AsrFeedback } from '..';
import { InteractionResponse, InteractionResponseItem, InteractionResponseOption, ResponseByUser } from '../interaction-response';
import { Interaction, InteractionConversationItem, InteractionItem } from '../interaction/interaction';
import { PronunciationInteractionOption } from '../interaction/option';
import { InteractionScore, NoInteractionScore } from '../score';
import { BaseInteractionModel, ResponseFeedbackItem, ResponseStatistics } from './base-interaction-model';

export class ConversationInteractionModel extends BaseInteractionModel {

    constructor(private interaction: Interaction) {
        super();
    }

    getResponseTemplate(interaction: Interaction): InteractionResponse {
        const rResponse = new InteractionResponse();
        interaction.items.forEach(item => {
            if (item.type === 'conversation-input-turn') {
                const iri = new InteractionResponseItem();
                iri.options.push(new InteractionResponseOption());
                rResponse.items.push(iri);
            } else {
                rResponse.items.push(undefined);
            }
        });
        return rResponse;
    }

    isCorrect(response: InteractionResponse): boolean {
        return response.items.every((item: InteractionResponseItem) => {
            if (item) {
                return item.correct;
            }
            return true;
        });
    }

    getScore(response: InteractionResponse): InteractionScore | NoInteractionScore {
        let score: InteractionScore = new InteractionScore();
        response.items
            .forEach((item: InteractionResponseItem, i: number) => {
                if (item && item.correct) {
                    const conversationItem: InteractionConversationItem = this.interaction.items[i] as InteractionConversationItem;
                    const itemScore = conversationItem.options[0].score;
                    score = score.add(itemScore);
                }
            });
        return score;
    }

    getMaxScore(): InteractionScore {
        let score: InteractionScore = new InteractionScore();
        this.interaction.items
            .filter(item => item.type === 'conversation-input-turn')
            .forEach((item: InteractionConversationItem) => {
                if (InteractionItem.isInputInteraction(item)) {
                    const itemScore = (item.options[0] as PronunciationInteractionOption).score;
                    score = score.add(itemScore);
                }
            });
        return score;
    }

    public collectStatistics(responses: ResponseByUser[]): ResponseStatistics[][] {
        const stats: ResponseStatistics[][] = [];
        this.interaction.items
            .filter(item => item.type === 'conversation-input-turn')
            .forEach((item: InteractionConversationItem) => {
                const itemStats: ResponseStatistics[] = [];
                item.options[0].words
                    .filter(w => w.isEvaluated)
                    .forEach(word => {
                        itemStats.push({ id: UUID.UUID().slice(0, 8), count: 0, tooltip: word.label, correct: true });
                    });
                stats.push(itemStats);
            });

        const lastSessionResponse: { [sessionId: string]: InteractionResponse } = {};
        responses.forEach(userResponse => {
            // Only responses for input-turn slots
            const response = clone(userResponse.response);
            response.items = userResponse.response.items.filter(i => i != null);

            const prevResponse = lastSessionResponse[userResponse.sessionId];
            const { option, index: idx } = this.getLastAsrOption(prevResponse, response);
            if (option && option.asrFeedback && option.asrFeedback.words) {
                option.asrFeedback.words
                    .filter(w => w.isEvaluated)
                    .forEach((w, index) => {
                        if (!w.hasErrors) {
                            stats[idx][index].count++;
                        }
                    });
            }

            lastSessionResponse[userResponse.sessionId] = response;
        });
        return stats;
    }

    public getResponseFeedback(response: InteractionResponse): ResponseFeedbackItem[] {
        const index = response.items.findIndex(i => i != null && i.asrFeedback != null);
        const item = response.items[index];
        if (item && item.asrFeedback) {
            const interactionItem = this.interaction.items[index] as InteractionConversationItem;
            const pronunciationOption = interactionItem.options[0] as PronunciationInteractionOption;
            return [{ item: AsrFeedback.initFromAsrResult(item.asrFeedback, pronunciationOption), statusCode: 'normal' }];
        }
        return [];
    }

    public filterPreviousResponses(responses: ResponseByUser[]): ResponseByUser[] {
        const filtered: ResponseByUser[] = [];
        const lastSessionResponse: { [sessionId: string]: InteractionResponse } = {};
        responses.forEach(userResponse => {
            const prevResponse = lastSessionResponse[userResponse.sessionId];
            const { index } = this.getLastAsrOption(prevResponse, userResponse.response);
            const responseItem = userResponse.response.items[index];
            if (index >= 0) {
                const ur = clone(userResponse);
                ur.response = clone(ur.response);
                ur.response.items = ur.response.items.map((item, idx) => {
                    if (item == null || index === idx) {
                        return item;
                    }
                    return new InteractionResponseItem();
                });
                ur.response.correct = responseItem != null && responseItem.correct;
                ur.response.complete = true;
                filtered.push(ur);
            }
            lastSessionResponse[userResponse.sessionId] = userResponse.response;
        });
        return filtered;
    }

    private getLastAsrOption(prevResponse: InteractionResponse, currentResponse: InteractionResponse)
        : { option: InteractionResponseItem | undefined, index: number } {
        if (prevResponse == null) {
            const index = currentResponse.items.findIndex(i => i != null && i.asrFeedback != null);
            return { option: currentResponse.items[index], index };
        }

        for (let index = 0; index < prevResponse.items.length; index++) {
            if (prevResponse.items[index] != null) { // if null then it is a listening item
                const prevItem = prevResponse.items[index];
                const prevFB = prevItem && prevItem.asrFeedback;
                const curItem = currentResponse.items[index];
                const curFB = curItem && curItem.asrFeedback;
                if (prevFB == null && curFB != null) {
                    return { option: currentResponse.items[index], index };
                }
                if (prevFB != null && curFB != null && !isEqual(prevFB.audio, curFB.audio)) {
                    return { option: currentResponse.items[index], index };
                }
            }
        }
        return { option: undefined, index: -1 };
    }
}
