import { Type } from 'class-transformer';
import * as moment from 'moment';
import { AsrFeedback } from './interaction/pronunciation-feedback';
import { MediaFile } from './media';
import { InteractionScore, NoInteractionScore } from './score';
import { initialize, Json, serializeType } from './utilities';

export class InteractionResponseOption {
    source?: string = undefined; // option id
    target?: string = undefined; // match target id
    text?: string = undefined; // filled in text
    correct = false;
    @Type(serializeType(MediaFile)) file?: MediaFile; // audio, video or text file
    @Type(serializeType(AsrFeedback)) asrFeedback?: AsrFeedback;

    static initialize(json: any): InteractionResponseOption {
        const result = initialize(InteractionResponseOption, json);
        if (json.file != null) {
            result.file = MediaFile.initialize(json.file);
        }
        if ((json.asrFeedback || json.pronunciationFeedback) != null) {
            result.asrFeedback = AsrFeedback.initialize({ ...(json.asrFeedback || json.pronunciationFeedback) });
        }
        return result;
    }

}

export class InteractionResponseItem {
    @Type(serializeType(InteractionResponseOption)) options: InteractionResponseOption[];
    correct = false;
    @Type(serializeType(AsrFeedback)) asrFeedback?: AsrFeedback;

    static initialize(json: any): InteractionResponseItem {
        const result = initialize(InteractionResponseItem, json);
        if (json.options != null && Array.isArray(json.options)) {
            result.options = json.options.map(o => InteractionResponseOption.initialize(o));
        }
        if (json.asrFeedback != null) {
            result.asrFeedback = AsrFeedback.initialize({ ...json.asrFeedback });
        }
        return result;
    }

    constructor() {
        this.options = [];
    }
}

export class InteractionResponse {
    @Type(serializeType(InteractionResponseItem)) items: (InteractionResponseItem | undefined)[] = [];
    feedback?: string = undefined;
    correct = false;
    complete = true; // For interaction with multiple responses: have all responses been given? Used for assessments
    score?: InteractionScore | NoInteractionScore = undefined;
    maxScore?: InteractionScore | NoInteractionScore = undefined;
    lastUpdatedItemIndex?: number = undefined;

    static initialize(json: Json<InteractionResponse>): InteractionResponse {
        const result = initialize(InteractionResponse, json);

        if (json.items != null && Array.isArray(json.items)) {
            result.items = json.items.map(i => i != null ? InteractionResponseItem.initialize(i) : undefined);
            result.lastUpdatedItemIndex = result.determineLastUpdatedItem();
        }
        if (json.score != null) {
            result.score = ('dimensions' in json.score) ? InteractionScore.initialize(json.score) : new NoInteractionScore();
        }
        if (json.maxScore != null) {
            result.maxScore = ('dimensions' in json.maxScore) ? InteractionScore.initialize(json.maxScore) : new NoInteractionScore();
        }
        return result;
    }

    static createConversationResponse(item: InteractionResponseItem): InteractionResponse {
        const response = new InteractionResponse();
        response.items = [item];
        response.correct = item.correct;
        response.complete = true;
        return response;
    }

    get hasDetailedPronFeedback(): boolean {
        const item = this.lastUpdatedItem;
        if (item == null || item.asrFeedback == null) {
            return false;
        } else {
            return item.asrFeedback.words.some(w => w.isEvaluated);
        }
    }

    get lastUpdatedItem(): InteractionResponseItem | undefined {
        if (this.items == null) { return; }
        const idx = this.lastUpdatedItemIndex != null ? this.lastUpdatedItemIndex : this.determineLastUpdatedItem();
        if (idx >= 0) { return this.items[idx]; }
    }

    private determineLastUpdatedItem(): number {
        // heuristic for backward compatibility, asrFeedback sets a date
        let last: Date = new Date(0);
        let lastIndex = -1;
        this.items.forEach((item, index) => {
            if (item && item.asrFeedback && item.asrFeedback.receivedAt) {
                const date = new Date(item.asrFeedback.receivedAt);
                if (date > last) {
                    last = date;
                    lastIndex = index;
                }
            }
        });
        if (lastIndex >= 0) {
            return lastIndex;
        }
        // as a last resort, go for the last element in items
        return this.items.length - 1;
    }
}

export interface ResponseByUser {
    userId: string;
    response: InteractionResponse;
    timestamp: moment.Moment;
    sessionId: string;
    statementId: string;
}
